import NewsItem from '@/definitions/interfaces/locales/NewsItem.i';

export default {
  TITLE: 'News: ',
  ITEMS: [
    {
      TEXT: 'KV-Dienstvertretung durch arztpool ist nicht sozialversicherungspflichtig +++++ KV-Vertreter bei arztpool, die rechtssichere Alternative zum Poolarzt +++++',
    },
  ] as NewsItem[],
};
