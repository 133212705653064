class Format {
  /**
   * format numeric value to represent a price
   * @param {number|string} value
   * @param {boolean} round
   * @param {boolean} addCurrencySymbol
   * @param {boolean} addPriceSymbol
   * @param {string} currencySymbol
   * @return {string}
   */
  public price(value: number|string, round: boolean = false, addCurrencySymbol: boolean = true, addPriceSymbol: boolean = false, currencySymbol: string = '€'): string {
    if (typeof value === 'string') {
      value = parseFloat(value);
    }

    if (round) {
      value = Math.round(value);
    }

    let price: string =  '';

    try {
      price += value.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
    catch (error) {
      price += (value + 0.00001).toFixed(2).replace('.', ',');
    }
    price += addCurrencySymbol ? `${currencySymbol}` : '';

    price = price.replace(',00', '');
    return addPriceSymbol ? `${price},-` : price;
  }

  /**
   * format numeric value to price per hour
   * @param {number} value
   * @param {boolean} round
   * @param {boolean} addCurrencySymbol
   * @param {boolean} addPriceSymbol
   * @param {string} currencySymbol
   * @return {string}
   */
  public pricePerHour(value: number|string, round: boolean = false, addCurrencySymbol: boolean = true, addPriceSymbol: boolean = false, currencySymbol: string = '€', timeUnit: string='Std.'): string {
    return `${this.price(value, round, addCurrencySymbol, addPriceSymbol, currencySymbol)}/${timeUnit}`;
  }
}

export default new Format();
