export default {
  CLOSE: 'Schließen',
  APPLY: {
    SUB_HEADING: [
      'Danke für Ihre Bewerbung!',
      'Wir können Ihre Bewerbung nicht bearbeiten!'
    ],
    TEXT: [
      'Ihre Bewerbung wurde erfolgreich übermittelt. Sie erhalten eine Nachricht von uns, wenn Ihre Bewerbung angenommen wird oder es Änderungen am Status des Dienstes gibt. Über Ihr Dashboard können Sie den Status des Dienstes ebenfalls verfolgen.',
      'Wir haben Ihre Bewerbung erhalten. Bitte vervollständigen Sie Ihr Profil, um dem Dienst zugewiesen werden zu können. Sie benötigen Ihre Approbationsurkunde und eine aktuelle Bestätigung Ihrer Berufshaftpflichtversicherung.',
    ],
    BUTTON: {
      MARKETPLACE: 'Weitere Dienste finden',
      BASE_DATA: 'Jetzt mein Profil vervollständigen',
      DASHBOARD: 'Zu meinem Dashboard',
    }
  },
  BOOKMARK: {
    SUB_HEADING: 'Der Dienst wurde auf Ihre Merkliste gesetzt!',
    TEXT: [
      'Sie erhalten eine Nachricht von uns, wenn es Änderungen am Status des Dienstes gibt. Über Ihr Dashboard können Sie den Status des Dienstes ebenfalls verfolgen.',
      'Vervollständigen Sie Ihr Profil, um dem Dienst zugewiesen werden zu können. Sie benötigen Ihre Approbationsurkunde und eine aktuelle Bestätigung Ihrer Berufshaftpflichtversicherung.',
    ],
    BUTTON: {
      WATCHLIST: 'Zur Merkliste',
      MARKETPLACE: 'Weitere Dienste finden',
      BASE_DATA: 'Jetzt mein Profil vervollständigen',
      DASHBOARD: 'Zu meinem Dashboard',
    }
  },
  ASSIGN: {
    SUB_HEADING: [
      'Herzlichen Glückwunsch, Sie vertreten den Dienst!',
      'Wir können Ihre Bewerbung nicht bearbeiten!',
    ],
    TEXT: 'Wir senden Ihnen eine Erinnerung wenige Tage vor Dienstbeginn. Den Dienstvertretungsvertrag können Sie nun herunterladen. Folgen Sie dazu einfach dem Link zum Dienst. Hier können Sie auch Details wie die Übergabe des Kartenlesegerätes ganz einfach mit Ihrem Auftraggeber abstimmen.',
    BUTTON: {
      SERVICE_MANAGEMENT: 'Einzelheiten zu Dienst aufrufen',
      MARKETPLACE: 'Weitere Dienste finden',
      BASE_DATA: 'Jetzt mein Profil vervollständigen',
      DASHBOARD: 'Zu meinem Dashboard',
    }
  },
  ERROR: {
    NOT_INSURED: 'Sie haben uns noch keine Versicherungsinformationen zukommen lassen. Bitte holen Sie dies umgehend nach.',
    NOT_VERIFIED: 'Ihr Account wurde bisher von uns noch nicht freigeschaltet, bitte warten Sie bis wir Ihre eingereichten Informationen überprüfen konnten.',
    PROFILE_INCOMPLETE: 'Bitte vervollständigen Sie Ihr Profil. Ohne die dort notwendigen Angaben können wir Ihnen keine Dienste vermitteln.',
    NO_INSURANCE: 'Sie haben uns noch keine Versicherungsinformationen zukommen lassen. Bitte holen Sie dies umgehend nach.',
    NO_CONFIRMED_TRAINING: 'Sie haben noch nicht bestätigt, dass Sie sich mind. im dritten Jahr Ihrer Weiterbildung befinden oder diese abgeschlossen haben. Bitte holen Sie dies umgehend nach.',
    VALIDATION_REJECTION: 'Wir konnten Ihre Facharzturkunde noch nicht verifizieren und Sie haben sich auf einen Dienst in einem Bundesland beworben, in dem nur noch Fachärzte Bereitschaftsdienste vertreten dürfen.',
    NO_LICENSE: 'Sie haben Ihre Approbation noch nicht hochgeladen. Bitte holen Sie dies umgehend nach, damit wir Sie so schnell wie möglich freischalten können.'
  }
};
