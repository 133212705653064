import PriceExamplesEntry from '@/definitions/interfaces/locales/PriceExamples.i';

export default {
  HEADING: 'Abrechnungsfähige Leistungen der KV-Dienste',
  HEADER: {
    TYPE: 'Dienstart',
    MOBILE: 'Fahrdienst',
    OFFICE: 'Sitzdienst',
  },
  ENTRIES: [
    {
      CLASS: 'duration',
      TITLE: 'Dauer',
      COLUMNS: [
        ['12 Std.'],
        ['4 Std.'],
      ],
    },
    {
      CLASS: 'patients',
      TITLE: 'behandelte Patienten',
      COLUMNS: [
        ['5 (Insgesamt)'],
        ['5 (pro Std.)'],
      ],
    },
    {
      CLASS: 'salary',
      TITLE: 'EBM-Honorar der KV',
      COLUMNS: [
        ['5 x 100€'],
        ['20 x 15€'],
      ],
    },
    {
      CLASS: 'financing',
      TITLE: 'Ihre Gegenfinanzierung durch die KV',
      COLUMNS: [
        ['500€'],
        ['300€'],
      ],
    },
    {
      CLASS: 'minimum',
      TITLE: 'Mindest-Honorarkosten für den Vertreter',
      COLUMNS: [
        [
          '12 Std. x 45€ =',
          '540€',
        ],
        [
          '4 Std. x 65€ =',
          '260€',
        ],
      ],
    },
    {
      CLASS: 'win',
      TITLE: 'Ihre voraussichtlichen Einnahmen',
      COLUMNS: [
        ['-40€'],
        ['+40€'],
      ],
    },
  ] as PriceExamplesEntry[],
  DISCLAIMER: [
    'zzgl. Vermittlungsgebühr an arztpool, in Abhängigkeit des Paketpreises',
    '1 Die Zahlen basieren auf den Erfahrungen und Auswertungen der vergangenen 10 Jahre.',
    '2 Durchschnittliches EBM-Honorar pro behandelten Patienten:',
    'Fahrdienst 100€ (EBM 01212 + 01418)',
    'Sitzdienst 15€ (EBM 01212)',
  ],
  INFO: {
    HEADLINE: [
      'arztpool',
      'vermittelt Ihnen persönliche Vertreter, welche zwei große Vorteile haben',
    ],
    POINTS: [
      'Der Vertreter ist in Ihrem Auftrag und auf Ihre Rechnung tätig. \n Das bedeutet, dass Sie das EBM-Honorar des gesamten Dienstes bei Ihrer KV als Gegenfinanzierung abrechnen können.',
      'Bei einer persönlichen Vertretung besteht keinerlei Gefahr, dass Ihr Vertreter sozialversicherungspflichtig wird.',
    ],
  },
};
