import { StoreGeneric, storeToRefs } from 'pinia';

import useMessageStore from '@/stores/Messages';
import useProfileDataStore from '@/stores/ProfileData';
import useQuickStartStore from '@/stores/QuickStart';
import useServiceStore from '@/stores/Service';
import useUserDataStore from '@/stores/UserData';
import usePartnerDataStore from '@/stores/PartnerData';
import useSubstituteDataStore from '@/stores/SubstituteData';
import useOperatorDataStore from '@/stores/OperatorData';
import useWizardStore from '@/stores/Wizard';

class Stores {
  /**
   * message store
   * @var {StoreGeneric}
   */
  // @ts-ignore
  public message;

  /**
   * profile data store
   * @var {StoreGeneric}
   */
  // @ts-ignore
  public profileData;

  /**
   * quick-start store
   * @var {StoreGeneric}
   */
  // @ts-ignore
  public quickStart;

  /**
   * service store
   * @var {StoreGeneric}
   */
  // @ts-ignore
  public service;

  /**
   * user data store
   * @var {StoreGeneric}
   */
  // @ts-ignore
  public userData;

  /**
   * partner data store
   * @var {StoreGeneric}
   */
  // @ts-ignore
  public partnerData;

  /**
   * substitute data store
   * @var {StoreGeneric}
   */
  // @ts-ignore
  public substituteData;

  /**
   * operator data store
   * @var {StoreGeneric}
   */
  // @ts-ignore
  public operatorData;

  /**
   * wizard data store
   * @var {StoreGeneric}
   */
  // @ts-ignore
  public wizard;

  /**
   * initialize stores
   * @return {void}
   */
  public init(): void {
    this.message = useMessageStore();
    this.profileData = useProfileDataStore();
    this.quickStart = useQuickStartStore();
    this.service = useServiceStore();
    this.userData = useUserDataStore();
    this.partnerData = usePartnerDataStore();
    this.substituteData = useSubstituteDataStore();
    this.operatorData = useOperatorDataStore();
    this.wizard = useWizardStore();
  }

  /**
   * convert states and getters to refs
   * @param {StoreGeneric} store
   * @return {*}
   */
  public storeToRefs(store: StoreGeneric): any {
    return storeToRefs(store);
  }

  /**
   * convert states and getters from message store to refs
   * @return {*}
   */
  public messageStoreToRefs(): any {
    if (this.message) {
      return this.storeToRefs(this.message);
    }

    return null;
  }

  /**
   * convert states and getters from profile data store to refs
   * @return {*}
   */
  public profileDataStoreToRefs(): any {
    if (this.profileData) {
      return this.storeToRefs(this.profileData);
    }

    return null;
  }

  /**
   * convert states and getters from quick start store to refs
   * @return {*}
   */
  public quickStartStoreToRefs(): any {
    if (this.quickStart) {
      return this.storeToRefs(this.quickStart);
    }

    return null;
  }

  /**
   * convert states and getters from service store to refs
   * @return {*}
   */
  public serviceStoreToRefs(): any {
    if (this.service) {
      return this.storeToRefs(this.service);
    }

    return null;
  }

  /**
   * convert states and getters from user data store to refs
   * @return {*}
   */
  public userDataStoreToRefs(): any {
    if (this.userData) {
      return this.storeToRefs(this.userData);
    }

    return null;
  }

  /**
   * convert states and getters from partner data store to refs
   * @return {*}
   */
  public partnerDataStoreToRefs(): any {
    if (this.partnerData) {
      return this.storeToRefs(this.partnerData);
    }

    return null;
  }

  /**
   * convert states and getters from substitute data store to refs
   * @return {*}
   */
  public substituteDataStoreToRefs(): any {
    if (this.substituteData) {
      return this.storeToRefs(this.substituteData);
    }

    return null;
  }

  /**
   * convert states and getters from operator data store to refs
   * @return {*}
   */
  public operatorDataStoreToRefs(): any {
    if (this.operatorData) {
      return this.storeToRefs(this.operatorData);
    }

    return null;
  }

  /**
   * convert states and getters from wizard data store to refs
   * @return {*}
   */
  public wizardStoreToRefs(): any {
    if (this.wizard) {
      return this.storeToRefs(this.wizard);
    }

    return null;
  }
}

export default new Stores();
