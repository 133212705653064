export default {
  STATES: {
    BREMEN: 'Bremen',
    HAMBURG: 'Hamburg',
    BERLIN: 'Berlin',
    SAARLAND: 'Saarland',
    SCHLESWIG_HOLSTEIN: 'Schleswig Holstein',
    THURINGIA: 'Thuringia',
    SAXONY: 'Saxony',
    RHINELAND_PALATINATE: 'Rhineland Palatinate',
    SAXONY_ANHALT: 'Saxony Anhalt',
    HESSE: 'Hesse',
    MECKLENBURG_POMERANIA: 'Mecklenburg Pomerania',
    BRANDENBURG: 'Brandenburg',
    NORTHRHINE_WESTPHALIA: 'Northrhine Westphalia',
    BADEN_WUERTTEMBERG: 'Baden-Wuerttemberg',
    LOWER_SAXONY: 'Lower Saxony',
    BAVARIA: 'Bavaria',
  },
  WEEKDAYS: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ],
  INSTITUTIONS: [
    'Einzelpraxis',
    'Berufsausübungsgemeinschaft',
    'Praxisgemeinschaft',
    'Klinik',
    'MVZ',
  ],
  SERVICES: [
    'Allgemeinärztlicher Bereitschaftsdienst',
    'Augenärztlicher Bereitschaftsdienst',
    'Kinderärztlicher Bereitschaftsdienst',
    'HNO-ärztlicher Bereitschaftsdienst',
    'Chirurgischer Bereitschaftsdienst',
    'Zahnärztlicher Bereitschaftsdienst',
  ],
  COUNTING: [
    'first',
    'second',
    'third',
    'fourth',
    'fifth',
    'sixth',
    'seventh',
    'eighth',
    'ninth',
    'tenth',
  ],
};
