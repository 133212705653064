export default {
  CLOSE: 'Schließen',
  HEADER: 'Dienstgebiet bearbeiten',
  DESCRIPTION: 'Wählen Sie das Bundesland, in dem diese Betriebsstätte liegt und anschließend das passende Dienstgebiet aus. Nachdem Sie diese Auswahl gespeichert haben, werden wir Ihnen für die Eintragung von Diensten für diese Betriebsstätte nur noch die passenden Dienste anbieten.',
  DESCRIPTION_NOT_AVAILABLE: 'Wenn Sie Ihr Dienstgebiet hier (noch) nicht wiederfinden, erreichen Sie unseren Support telefonisch unter der Nummer 0800 47 37 535 44. Gerne legen wir Ihr Dienstgebiet mit Ihnen zusammen in unserer Datenbank an.',
  FORM: {
    STATE: 'Bundesland',
    SERVICE_AREA: 'Dienstgebiet',
    SUBMIT: 'Speichern'
  },
};
