import { MESSAGES_QUERY } from '@/data/gql/queries';
import { SUBMIT_SERVICE_MESSAGE, MARK_MESSAGE_READ } from '@/data/gql/mutations';

import Stores from '@/stores';
import apolloClient from '@/plugins/apollo';
import Message from '@/definitions/interfaces/Message.i';

class Messages {
  /**
   * fetch a user's messages
   * @return {Promise}
   */
  public async fetchMessages(): Promise<void> {
    Stores.message.loadingMessages = true;

    const _messages = await apolloClient.defaultClient
      .query({
        query: MESSAGES_QUERY,
        fetchPolicy: 'no-cache',
    });

    Stores.message.setMessages(_messages.data.profile.messages);
    Stores.message.loadingMessages = false;
  }

  /**
   * send a message to user
   * @param {string} userId - the UUID of the user to send the message to
   * @param {string} subject - the subject of the message
   * @param {string} content - the content of the message
   * @param {string|null} serviceId - the UUID of a service to tie the message to; leave blank if not applicable
   * @return {Promise} boolean
   */
  public async sendMessage(userId: string, subject: string, content: string, serviceId: string|null): Promise<boolean> {
    const _response = await apolloClient.defaultClient
      .mutate({
        mutation: SUBMIT_SERVICE_MESSAGE,
        variables: {
          userID: userId,
          subject: subject,
          content: content,
          serviceID: serviceId,
        }
    });
    return true;
  }

  /**
   * send a message to service chat, uses current profile user id
   * @param {string} serviceId
   * @param {string} subject
   * @param {string} content
   * @return {void}
   */
  public async sendServiceMessage(serviceId: string, subject: string, content: string): Promise<any> {
    return apolloClient.defaultClient
      .mutate({
        mutation: SUBMIT_SERVICE_MESSAGE,
        variables: {
          serviceID: serviceId,
          userID: Stores.profileData.profile.id,
          subject,
          content,
        }
      });
  }

  /**
   * mark a message read (both in the store and in the DB)
   * @param {string} messageId - the UUID of the message to mark read
   * @return {Promise} boolean
   */
  public async markMessageRead(messageId: string): Promise<boolean> {
    const _messageIndex: number = Stores.message.messages.findIndex((message: Message): boolean => message.id === messageId);

    if (_messageIndex !== -1) {
      Stores.message.messages[_messageIndex].confirmedOn = new Date();

      const _response = await apolloClient.defaultClient
        .mutate({
          mutation: MARK_MESSAGE_READ,
          variables: {
            messageID: messageId,
          }
      });

      return _response.data.confirmMessageRead;
    }
    else {
      return false;
    }
  }
}

export default new Messages();
