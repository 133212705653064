import Quote from '@/definitions/interfaces/locales/Quote.i';
import Question from '@/definitions/interfaces/locales/Question.i';
import AdvantageColumn from '@/definitions/interfaces/locales/AdvantageColumn.i';

export default {
  META: {
    TITLE: 'KV-Dienste übernehmen',
    DESCRIPTION:
      'Die Übernahme von KV-Diensten ist für approbierte Ärzte eine lukrative Verdinestmöglichkeit. Mit arztpool profitieren Sie dabei von vielen weiteren Vorteilen.',
  },
  HEADING: 'Verdienstmöglichkeiten für Ärzte',
  HEADING_SUB: 'Wie es funktioniert: ',
  HEADING_BANNER: 'KV-Dienste übernehmen',
  DESCRIPTION: [
    'Die Übernahme von kassenärztlichen Bereitschaftsdiensten über arztpool.de eröffnet approbierten Ärzten attraktive Verdienstmöglichkeiten.',
    'Dank garantierten Mindesthonoraren und leistungsabhängigen Zusatzvergütungen werden Dienstvertretungsärzte bei arztpool.de stets gerecht entlohnt.',
    'Nutzen Sie die vielfältigen Vorteile, wenn Sie KV-Dienste über arztpool.de übernehmen.',
  ],
  PRICING_INFO: {
    HEADING: 'Preise für Dienstübernahme',
    SUB_HEADING: 'Gebühr pro vermittelten Dienst',
    INFO: 'abhängig von der Dienstdauer',
    ASTERISK: '*Preise sind Nettopreise (zzgl. 19% USt.)',
  },
  BUTTON_REPRESENT: 'Jetzt KV-Dienste übernehmen',
  SEPARATOR: 'Jetzt bewerben & KV-Dienste übernehmen',
  QUOTE: {
    NORMAL: '»Wir navigieren per App, shoppen online und kommunizieren mobil.',
    HIGHLIGHT: 'Warum nicht auch unsere KV-Dienste digital organisieren?«',
    NAME: 'Dr. med. Sven Kunath',
    POSITION: 'Ärztlicher Leiter arztpool.de',
  } as Quote,
  QUESTIONS: [
    {
      QUESTION: 'Wie werde ich Vertretungsarzt, um kassenärztliche Bereitschaftsdienste vertreten zu können?',
      ANSWER:
        'Zuerst müssen Sie sich bei arztpool ein Vertreterkonto anlegen und dort Ihre Approbationsurkunde, wenn vorhanden die Facharzturkunde, einen aktuellen Nachweis Ihrer Berufshaftpflichtversicherung hochladen, sowie Bankverbindung und Steuernummer angeben. Wenn alle Unterlagen und Angaben vollständig sind, wird Ihr Vertreterkonto von arztpool verifiziert und Sie können sich auf Dienste bewerben.',
    },
    {
      QUESTION: 'Wo finde ich meine Steuernummer?',
      ANSWER:
        'Sie finden Ihre Steuernummer auf jedem Schreiben Ihres Finanzamtes oben links. Bitte nicht mit der Steueridentifikationsnummer auf der Gehaltsabrechnung verwechseln.',
    },
    {
      QUESTION: 'Muss ich meine Berufshaftpflichtversicherung jedes Jahr nachweisen?',
      ANSWER:
        'Meistens verlängern sich die Berufshaftpflichtversicherungen nur um ein Kalenderjahr (1. Januar bis 31.12.). Da wir nicht wissen können, ob Sie den Vertrag gekündigt oder die Prämie bezahlt haben, müssen Sie uns einmal im Jahr das Weiterbestehen schriftlich bestätigen. Eine gültige Bestätigung der Versicherung gibt es erst NACH der Verlängerung, in den meisten Fällen also frühestens Mitte Januar.',
    },
    {
      QUESTION: 'Was mache ich, wenn ich keinen aktuellen Nachweis meiner Berufshaftpflichtversicherung habe?',
      ANSWER:
        'Bitte laden Sie keine Beitragsrechnung hoch. Diese wird nicht als Bestätigung akzeptiert. Kopieren Sie den folgenden Text in eine neue E-Mail und schicken Sie sie an Ihre Versicherung oder an Ihren Makler: "Sehr geehrte Damen und Herren, ich bitte um Zusendung einer Versicherungsbestätigung zu meinem Versicherungsvertrag. Die Bestätigung MUSS die folgenden Angaben enthalten: Ausstellungsdatum, Deckungssumme für Personen- und Sachschäden, Wann ist die nächste Hauptfälligkeit?, Bis zu welchem Datum ist die Prämie bezahlt?, Wie viele Jahre Nachhaftung enthält dieser Vertrag?, Ist die Patientenbehandlung als Arzt/Ärztin im organisierten ärztlichen Bereitschaftsdienst nach §75 Abs. 1 Satz 2 SGB V abgesichert?, Wie viele Dienste im ärztlichen Bereitschaftsdienst pro Jahr sind abgesichert?". Sobald Sie die Bestätigung erhalten haben können Sie das Dokument als Versicherungsnachweis hochladen.',
    },
    {
      QUESTION: 'Wie bewerbe ich mich auf einen Dienst?',
      ANSWER:
        'Sie können unseren Marktplatz nach für Sie interessanten Diensten durchsuchen und auf die Dienste verbindliche Angebote abgeben oder Sie nutzen die Sofortzusage-Option dort wo sie angeboten wird. Dann erhalten Sie den Dienst sofort zum angebotenen Mindesthonorar.',
    },
    {
      QUESTION: 'Wie geht es nach der Dienstbewerbung weiter?',
      ANSWER: 'Über unsere Plattform teilt Ihnen der Arzt, den Sie vertreten mit, wann und wie Sie die Unterlagen für den Dienst bekommen.',
    },
    {
      QUESTION: 'Brauche ich einen eigene Arzttasche?',
      ANSWER:
        'Ähnlich wie ein Handwerker sein eigenes Werkzeug mitbringt, sollte auch ein Vertreter im KV-Dienst seinen eigenen Arzttasche dabei haben. Sie können in Ihrem Vertreterprofil auch angeben, dass Sie eine besitzen.',
    },
    {
      QUESTION: 'Wer rechnet Privatpatienten und Todesbescheinigungen ab?',
      ANSWER: 'Gemäß unseren AGB rechnen immer Sie als Vertreter die Privatpatienten und die Todesbescheinigungen ab.',
    },
  ] as Question[],
  ADVANTAGES: {
    HEADING: 'Ihre Vorteile bei KV-Dienst-Vertretungen',
    COLUMNS: [
      {
        HEADING: 'Verdienst',
        ENTRIES: [
          'Honorarbasierte Verdienstmöglichkeit',
          'Flexible Arbeitszeiten',
          'Einsatzorte in ganz Deutschland',
          'Leistungsabhängige Zusatzvergütungen',
        ],
      },
      {
        HEADING: 'Support',
        ENTRIES: [
          'Einweisung und Anleitung',
          'Optional verfügbare Schulungen',
          'Telefonischer Support',
          'Hilfreiche Arbeitshilfen',
        ],
      },
      {
        HEADING: 'Komfort',
        ENTRIES: [
          'Bequeme Dienstkoordination und Abwicklung',
          'Rechnungsstellung durch arztpool.de',
          'Effiziente Prozesssteuerung',
          'Arbeitsmittelübergabe am Wunschort und zur gewünschten Zeit',
        ],
      },
      {
        HEADING: 'Sicherheit',
        ENTRIES: [
          'Verlässliche Auftraggeber für sichere Arbeit',
          'Datenverarbeitung über eigene Software und Server in Deutschland',
          'Spezialisiertes Vertragsmanagement',
        ],
      },
    ] as AdvantageColumn[],
  },
};
