<template>
  <div id="sudomodebanner" :class="{ small: smAndDown }">
    <v-container class="banner-container">
      <div class="banner-content">
        <span class="banner-text">
          {{ $t('OPERATORS.SUDO_MODE.WARNING') }}
        </span>
      </div>
    </v-container>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { useDisplay } from 'vuetify';

  export default defineComponent({
    name: 'SudoModeBanner',
    components: {},
    setup() {
      const { smAndDown } = useDisplay();
      return {
        smAndDown,
      };
    },
  });
</script>

<style lang="scss" scoped>
  @import '@/scss/configs/colors';
  @import '@/scss/configs/fonts';

  #sudomodebanner {
    width: 100%;
    background: linear-gradient(45deg, #ff9800, #f44336);
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;

    .banner-container {
      padding: 8px 16px;

      &.small {
        padding: 4px 8px;
      }
    }

    .banner-content {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;

      .warning-icon {
        font-size: 24px;
      }

      .banner-text {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(45deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
      animation: shine 3s infinite;
    }
  }

  @keyframes shine {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 0.5;
    }
  }
</style>
