export default {
  HEADING: 'Passwort zurücksetzen',
  FORM: {
    NEW_PASSWORD: 'Neues Passwort',
    PASSWORD_CONFIRMATION: 'Passwort bestätigen',
    SUBMIT: 'Passwort setzen',
  },
  ERROR: {
    EXPIREDCODEEXCEPTION: 'Dieser Code ist abgelaufen - bitte fordern Sie das Zurücksetzen Ihres Passworts erneut an.',
    LIMITEXCEEDEDEXCEPTION: 'Die maximale Anzahl an Versuchen, das Passwort zurückzusetzen wurde erreicht. Bitte versuchen Sie es später noch einmal.'
  },
  OPEN_LOGIN: 'Zum Login',
};
