export default {
  BUTTON_SEARCH: 'Neue Suche starten',
  LABELS: {
    DATE_FROM: 'von (Datum)',
    DATE_TO: 'bis (Datum)',
    STATE: 'Bundesland',
    STATES: 'Bundesländer',
    SERVICE_MOBILE: 'Fahrdienste',
    SERVICE_OFFICE: 'Sitzdienste',
  },
};
