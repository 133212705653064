export default {
  META: {
    TITLE: 'Ihr KV-Dienst-Manager',
  },
  HEADING: 'Ihr KV-Dienst-Manager',
  OVERVIEW: {
    TITLE: 'Übersicht',
    LOADING: 'Wir laden Ihre Dienste...',
    PARTNER: {
      HEADING: 'Übersicht Ihrer KV-Dienste',
      PUBLISHED: {
        HEADING: 'Ihre veröffentlichten Dienste',
      },
      OCCUPIED: {
        HEADING: 'Ihre vergebenen Dienste',
      },
      CONCLUDED: {
        HEADING: 'Ihre beendeten Dienste',
      },
      DRAFTS: {
        HEADING: 'Ihre gespeicherten Entwürfe',
      },
    },
    SUBSTITUTE: {
      HEADING: 'Übersicht meiner KV-Dienste',
      WATCHLIST: {
        HEADING: 'Merkliste',
      },
      APPLICATIONS: {
        HEADING: 'Meine Bewerbungen',
        APPLY_TO_MORE: 'Auf weitere KV-Dienste bewerben',
      },
      ASSIGNED: {
        HEADING: 'Mir zugewiesene Dienste',
      },
      CONCLUDED: {
        HEADING: 'Beendete Dienste',
      },
    },
    SHOW_ALL: 'Alle anzeigen',
  },
  REGISTER: {
    TITLE: 'Neuen Dienst eintragen',
    HEADING: 'Tragen Sie neue KV-Dienste ein',
    TEXT: 'Nutzen Sie unsere neue Ausschreibungssoftware: Wir vermitteln Ihnen schnell, zuverlässig und papierlos passende Vertretungsärzte. Und Sie behalten die Kontrolle und den Überblick. Probieren Sie es aus und überzeugen Sie sich von unserer 99%-igen Erfolgsquote.',
    ADD_ONE_SERVICE: 'Einzelnen KV-Dienst eintragen',
    ADD_ONE_SERVICE_TEXT: 'Schnelle, papierlose Vermittlung von geprüften Vertretungsärzten. Ideal, um arztpool.de zu testen.',
    ADD_MULTIPLE_SERVICES: 'Mehrere KV-Dienste eintragen',
    ADD_MULTIPLE_SERVICES_TEXT:
      'Tragen Sie mehrere KV-Dienste gleichzeitig ein und profitieren Sie von einem vergünstigten Tarif, wenn Sie drei oder mehr Dienste bei arztpool inserieren.',
  },
  PUBLISHED: {
    TITLE: 'Veröffentlichte Dienste',
    HEADING: 'Ihre veröffentlichten Dienste',
  },
  MARKETPLACE: {
    TITLE: 'KV-Dienste finden',
    HEADING: 'KV-Dienste finden',
  },
  WATCHLIST: {
    TITLE: 'Merkliste',
    HEADING: 'Merkliste',
  },
  APPLICATIONS: {
    TITLE: 'Meine Bewerbungen',
    HEADING: 'Meine Bewerbungen',
  },
  ASSIGNED: {
    TITLE: 'Zugewiesene Dienste',
    HEADING: 'Mir zugewiesene Dienste',
  },
  OCCUPIED: {
    TITLE: 'Vergebene Dienste',
    HEADING: 'Ihre vergebenen Dienste',
  },
  CONCLUDED: {
    TITLE: 'Beendete Dienste',
    HEADING: 'Beendete Dienste',
  },
  DRAFTS: {
    TITLE: 'Gespeicherte Entwürfe',
    HEADING: 'Ihre gespeicherten Entwürfe',
  },
  NO_RESULTS: 'Keine Einträge',
  FILTER: {
    ALL: 'Alle',
    MOBILE_SERVICE: 'Fahrdienst',
    OFFICE_SERVICE: 'Sitzdienst',
  },
  SORTING: 'Sortierung',
  SORT: {
    ASC: 'Datum aufsteigend',
    DESC: 'Datum absteigend',
  },
};
