import meta from '@/locales/de/meta';
import data from '@/locales/de/data';
import validation from '@/locales/de/validation';
import common from '@/locales/de/common';
import mails from '@/locales/de/mails';

import navigation from '@/locales/de/components/navigation';
import footer from '@/locales/de/components/footer';
import questions from '@/locales/de/components/questions';
import testimonials from '@/locales/de/components/testimonials';
import serviceCard from '@/locales/de/components/serviceCard';
import serviceColumns from '@/locales/de/components/serviceColumns';
import picker from '@/locales/de/components/picker';
import searchFilter from '@/locales/de/components/searchFilter';
import addServiceQuickStart from '@/locales/de/components/addServiceQuickStart';
import serviceForm from '@/locales/de/components/serviceForm';
import serviceFeeTable from '@/locales/de/components/serviceFeeCalculationTable';
import serviceSummary from '@/locales/de/components/serviceSummary';
import pricingInfo from '@/locales/de/components/pricingInfo';
import newsTicker from '@/locales/de/components/newsTicker';
import partnerFeesTable from '@/locales/de/components/partnerFeesTable';
import priceExamples from '@/locales/de/components/priceExamples';

import loginModal from '@/locales/de/components/modals/login';
import setupModal from '@/locales/de/components/modals/setup';
import contactModal from '@/locales/de/components/modals/contact';
import registerModal from '@/locales/de/components/modals/register';
import preregisterModal from '@/locales/de/components/modals/preregister';
import passwordResetModal from '@/locales/de/components/modals/resetPassword';
import priorityConfirmModal from '@/locales/de/components/modals/priorityConfirm';
import instantApplyConfirmModal from '@/locales/de/components/modals/instantApplyConfirm';
import serviceDetailConfirmModal from '@/locales/de/components/modals/serviceDetailConfirmation';
import dateTimeModal from '@/locales/de/components/modals/dateTime';
import singleServiceFeeConfirmModal from '@/locales/de/components/modals/singleServiceFeeConfirmation';
import singleServiceFeeModal from '@/locales/de/components/modals/singleServiceFeeModal';
import establishmentDoctorModal from '@/locales/de/components/modals/establishmentDoctor';
import establishmentServiceAreaModal from '@/locales/de/components/modals/establishmentServiceArea';
import serviceFormModal from '@/locales/de/components/modals/serviceForm';
import applicantSelectionModal from '@/locales/de/components/modals/applicantSelection';
import contractModal from '@/locales/de/components/modals/contract';
import invoiceModal from '@/locales/de/components/modals/invoice';
import substitutionNoticeModal from '@/locales/de/components/modals/substitutionNotice';

import home from '@/locales/de/views/home';
import mvz from '@/locales/de/views/mvz';
import imprint from '@/locales/de/views/imprint';
import privacy from '@/locales/de/views/privacy';
import aboutUs from '@/locales/de/views/aboutUs';
import conditions from '@/locales/de/views/conditions';
import pageNotFound from '@/locales/de/views/pageNotFound';
import serviceListing from '@/locales/de/views/serviceListing';
import serviceMarketplace from '@/locales/de/views/serviceMarketplace';
import provideService from '@/locales/de/views/provideService';
import representService from '@/locales/de/views/representService';
import resetPassword from '@/locales/de/views/resetPassword';
import wizard from '@/locales/de/views/wizard';
import confirm from '@/locales/de/views/confirm';
import serviceDetail from './de/views/serviceDetail';
import dashboard from '@/locales/de/views/dashboard';
import services from '@/locales/de/views/services';
import singleServiceEntry from '@/locales/de/views/partners/services/singleEntry';
import bulkServiceEntry from '@/locales/de/views/partners/services/bulkEntry';
import messages from '@/locales/de/views/messages';
import profile from '@/locales/de/views/profile';
import documents from '@/locales/de/views/documents';
import serviceManagement from '@/locales/de/views/serviceManagement';

import contract from '@/locales/de/documents/contract';
import invoiceTemplate from '@/locales/de/documents/invoiceTemplate';
import substitutionNotice from '@/locales/de/documents/substitutionNotice';

import operators from '@/locales/de/views/operators/operators';
import operatorUsers from '@/locales/de/views/operators/users';
import operatorServices from '@/locales/de/views/operators/services';
import operatorServiceAreas from '@/locales/de/views/operators/serviceAreas';
import operatorAccounting from '@/locales/de/views/operators/accounting';
import operatorReporting from '@/locales/de/views/operators/reporting';

export default {
  // general
  META: meta,
  DATA: data,
  VALIDATION: validation,
  COMMON: common,
  MAILS: mails,

  // components
  NAVIGATION: navigation,
  FOOTER: footer,
  QUESTIONS: questions,
  TESTIMONIALS: testimonials,
  SERVICE_CARD: serviceCard,
  SERVICE_COLUMNS: serviceColumns,
  PICKER: picker,
  ADD_SERVICE_QUICKSTART: addServiceQuickStart,
  SEARCH_FILTER: searchFilter,
  SERVICE_FORM: serviceForm,
  SERVICE_FEE_TABLE: serviceFeeTable,
  SERVICE_SUMMARY: serviceSummary,
  PRICING_INFO: pricingInfo,
  NEWS_TICKER: newsTicker,
  PARTNER_FEES_TABLE: partnerFeesTable,
  PRICE_EXAMPLES: priceExamples,

  // modals
  LOGIN_MODAL: loginModal,
  SETUP_MODAL: setupModal,
  CONTACT_MODAL: contactModal,
  REGISTER_MODAL: registerModal,
  PREREGISTER_MODAL: preregisterModal,
  PASSWORD_RESET_MODAL: passwordResetModal,
  INSTANT_APPLY_MODAL: instantApplyConfirmModal,
  PRIORITY_CONFIRM_MODAL: priorityConfirmModal,
  SERVICE_DETAIL_CONFIRMATION_MODAL: serviceDetailConfirmModal,
  DATE_TIME_MODAL: dateTimeModal,
  SINGLE_SERVICE_FEE_CONFIRM_MODAL: singleServiceFeeConfirmModal,
  ESTABLISHMENT_DOCTOR_MODAL: establishmentDoctorModal,
  ESTABLISHMENT_SERVICEAREA_MODAL: establishmentServiceAreaModal,
  SINGLE_SERVICE_FEE_MODAL: singleServiceFeeModal,
  SERVICE_FORM_MODAL: serviceFormModal,
  APPLICANT_SELECTION_MODAL: applicantSelectionModal,
  CONTRACT_MODAL: contractModal,
  INVOICE_MODAL: invoiceModal,
  SUBSTITUTION_NOTICE_MODAL: substitutionNoticeModal,

  // views
  HOME: home,
  MVZ: mvz,
  IMPRINT: imprint,
  PRIVACY: privacy,
  ABOUT_US: aboutUs,
  CONDITIONS: conditions,
  PAGE_NOT_FOUND: pageNotFound,
  SERVICE_LISTING: serviceListing,
  SERVICE_MARKETPLACE: serviceMarketplace,
  PROVIDE_SERVICE: provideService,
  REPRESENT_SERVICE: representService,
  PASSWORD_RESET: resetPassword,
  CONFIRM: confirm,
  DASHBOARD: dashboard,
  SERVICE_DETAIL: serviceDetail,
  SERVICES: services,
  WIZARD: wizard,
  SINGLE_ENTRY_SERVICE: singleServiceEntry,
  BULK_ENTRY_SERVICE: bulkServiceEntry,
  MESSAGES: messages,
  PROFILE: profile,
  DOCUMENTS: documents,
  SERVICE_MANAGEMENT: serviceManagement,

  //documents
  CONTRACT: contract,
  INVOICE_TEMPLATE: invoiceTemplate,
  SUBSTITUTION_NOTICE: substitutionNotice,

  //operators
  OPERATORS: operators,
  OPERATOR_USERS: operatorUsers,
  OPERATOR_SERVICES: operatorServices,
  OPERATOR_SERVICEAREAS: operatorServiceAreas,
  OPERATOR_ACCOUNTING: operatorAccounting,
  OPERATOR_REPORTING: operatorReporting,
};
