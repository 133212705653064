export default {
  HEADING: 'ÄNDERUNGS- bzw. TAUSCHMELDUNG',
  SUB_HEADING: 'Kassenärztlicher Bereitschaftsdienst',
  TEXT: [
    'Bereitschaftsdienst: {kind}',
    'Dienstgebiet: {serviceAreaName}',
    'Datum des Dienstes',
    'Uhrzeit',
    'Name des im Dienstplan ausgewiesenen Arztes',
    'Übernahme durch',
    'Telefon: {phone}',
    '(alternativ: {alternativePhone})',
    'E-Mail: {email}',
    'geboren: {birthdate}',
    'Dienst noch nicht vergeben',
    'Berlin, den {currentDate}',
    'ACHTUNG: Diese Änderungs- bzw. Tauschmeldung ersetzt nicht das erforderliche Formblatt der KV.',
    'Sie stellt lediglich die Bestätigung der Dienstvertretung für den Vertragsarzt dar.',
    'Dieses Schreiben ist maschinell erstellt und ohne Unterschrift gültig.'
  ]
};
