export default {
  REQUIRED: 'Please fill in!',
  EMAIL: {
    LENGTH: 'Max. 64 chars allowed',
    INVALID: 'E-Mail invalid',
  },
  PASSWORD: {
    INVALID: 'At least 8 chars, at least one capital letter, at least one number, at least one special character',
    MISMATCH: 'The passwords must match',
  },
};
