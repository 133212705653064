export default {
  HEADING: 'Buchhaltung',
  DESCRIPTION: 'Bitte wählen Sie zunächst einen Zeitraum aus, für den Sie Dienste laden wollen. Sie können diese dann in der angezeigten Tabelle überprüfen oder einzeln aufrufen. Den Export der geladenen Dienste wird dann mit dem entsprechenden Button angestoßen.',
  DESCRIPTION_2: 'Bitte beachten Sie: Die Daten, die für die geladenen Dienste exportiert werden, sind umfangreicher als sie in der Tabelle angezeigt werden, diese dient nur der Kontrolle, welche Dienste in dem gewählten Zeitraum enthalten sind.',
  FORM_LABELS: {
    SUBMIT: 'Suchen',
    FROM: 'Erstellt von...',
    TO: 'Erstellt bis...',
    START_FROM: 'Dienststart von...',
    START_TO: 'Dienststart bis...',
  },
  SERVICES_CREATIONDATE: 'Dienste nach Erstellungsdatum',
  SERVICES_STARTDATE: 'Dienste nach Dienststart',
  SERVICES_PRIORITYONLY: 'Nur Prioritydienste',
  MOBILE_SERVICE: 'Fahrdienst',
  OFFICE_SERVICE: 'Sitzdienst',
  EXPORT_CSV: 'Als CSV exportieren',
  EXPORT_XML: 'Als XML (Datev) exportieren',
  TABLE_HEADERS: {
    TYPE: 'Dienstart',
    PARTNER: 'Kunde',
    DEBTOR_NUMBER: 'Kundennummer',
    CREATED_ON: 'Eingetragen am',
    START: 'Dienststart',
    END: 'Dienstende',
    SERVICE_AREA: 'Dienstgebiet',
    STATE: 'Bundesland',
    SUBSTITUTE: 'Vertreter',
    STATUS: 'Status',
    ACTIONS: 'Aktionen',
  },
};
