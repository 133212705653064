import Question from '@/definitions/interfaces/locales/Question.i';
import TeamIndividual from '@/definitions/interfaces/locales/TeamIndividual.i';

export default {
  META: {
    TITLE: 'Über arztpool',
    DESCRIPTION: 'Wir sind die Menschen hinter arztpool. Wir sind Ärzte, Kaufleute, Juristen, Programmierer, Logistiker, Buchhalter und noch vieles mehr.',
  },
  HEADING: 'Über arztpool',
  HEADING_BANNER: 'Über arztpool.de',
  HEADING_SUB: 'Wir sind die Menschen hinter arztpool.de',
  DESCRIPTION: [
    'Wir sind die Menschen hinter arztpool. Wir sind Ärzte, Kaufleute, Juristen, Programmierer, Logistiker, Buchhalter und noch vieles mehr.',
    'Unsere große Leidenschaft ist der Kassenärztliche Bereitschaftsdienst.',
    'Unser Ziel ist es, arztpool.de als neuen Standard bei der Organisation von kassenärztlichen Bereitschaftsdiensten unter allen niedergelassenen Ärzten und potenziellen Vertretungsärzten zu etablieren und damit die Vermittlung von Vertretungen digital zu revolutionieren.',
  ],
  QUESTIONS: [
    {
      QUESTION: 'Welche Unterlagen/ Angaben brauche ich für eine erfolgreiche Registrierung?',
      ANSWER:
        'Für eine erfolgreiche Registrierung als Honorararzt bei arztpool.de benötigen Sie Ihre Approbationsurkunde, eine aktuelle Bestätigung Ihrer Berufshaftpflichtversicherung sowie falls vorhanden eine Facharzturkunde. Alles im Format PDF, nicht größer als 10 MB. Ebenfalls brauchen Sie Ihre Steuernummer und Ihre Bankverbindung für die Anmeldung. Ohne diese Angaben und Dokumente können wir Ihre Registrierung nicht verifizieren.',
    },
    {
      QUESTION: 'Was passiert nach meiner Dienstbewerbung?',
      ANSWER:
        'Nach der Dienstbewerbung bekommen wir angezeigt, dass Sie sich auf einen oder mehrere Dienste beworben haben. Wenn Sie dann für einen oder mehrere Dienste als Vertreter ausgewählt werden, werden Sie per Mail über die Dienstübernahme benachrichtigt. Im Portal muss zuerst der Kunden und dann Sie dem Dienstvertretungsvertrag zustimmen. Erst dann werden Ihre Daten an den Kunden übermittelt, sodass er die KV über seine Dienstvertretung informieren kann.',
    },
    {
      QUESTION: 'Wie bekomme ich meine Unterlagen für den Dienst?',
      ANSWER:
        'Sobald wir den Kunden darüber informieren, dass Sie seinen Dienst übernehmen, wird er mit Ihnen Kontakt aufnehmen und die Übergabe und Rückgabe der Unterlagen wie Kartenlesegerät, Notfallscheine, Kassenrezepte oder Krankenhauseinweisungen absprechen.',
    },
    {
      QUESTION: 'Muss ich meine Berufshaftpflichtversicherung jedes Jahr nachweisen?',
      ANSWER:
        'Meistens verlängern sich die Berufshaftpflichtversicherungen nur um ein Kalenderjahr (1. Januar bis 31.12.). Da wir nicht wissen können, ob Sie den Vertrag gekündigt oder die Prämie bezahlt haben, müssen Sie uns einmal im Jahr das Weiterbestehen schriftlich bestätigen. Eine gültige Bestätigung der Versicherung gibt es erst NACH der Verlängerung, in den meisten Fällen also frühestens Mitte Januar.',
    },
    {
      QUESTION: 'Wo finde ich meine Steuernummer?',
      ANSWER:
        'Sie finden Ihre Steuernummer auf jedem Schreiben Ihres Finanzamtes oben links. Bitte nicht mit der Steueridentifikationsnummer auf der Gehaltsabrechnung verwechseln.',
    },
  ] as Question[],
  TEAM: [
    {
      IMAGE: 'anne_cathrin_mengiste.jpg',
      NAME: 'Anne-Cathrin Mengiste',
      ROLE: 'Geschäftsleitung arztpool',
      EMAIL: 'a.mengiste@arztpool.de',
    },
    {
      IMAGE: 'sven_kunath.png',
      NAME: 'Dr. med. Sven Kunath',
      ROLE: 'Ärztlicher Leiter',
      EMAIL: 's.kunath@arztpool.de',
      PHONE: '030 47 37 535 30',
    },
    {
      IMAGE: 'anton_papperger.png',
      NAME: 'Anton Papperger',
      ROLE: 'Manager Kundenbetreuung',
      EMAIL: 'a.papperger@arztpool.de',
      PHONE: '030 47 37 535 28',
    },
    {
      IMAGE: 'christian_kleiner.png',
      NAME: 'Christian Kleiner',
      ROLE: 'Manager Dienstvergabe',
      EMAIL: 'c.kleiner@arztpool.de',
      PHONE: '030 47 37 535 68',
    },
    {
      IMAGE: 'placeholder.png',
      NAME: 'Support Dienstvergabe',
      ROLE: '',
      EMAIL: 'dienste@arztpool.de',
      PHONE: '030 47 37 535 13',
      FAX: 'Fax: 030 47 37 535 20',
    },
    {
      IMAGE: 'placeholder.png',
      NAME: 'Logistik',
      ROLE: '',
      EMAIL: 'logistik@arztpool.de',
      PHONE: '030 47 37 535 69',
    },
    {
      IMAGE: 'placeholder.png',
      NAME: 'Vertrieb/Marketing',
      ROLE: '',
      EMAIL: 'marketing@arztpool.de',
      PHONE: '030 47 37 535 0',
    },
    {
      IMAGE: 'placeholder.png',
      NAME: 'Finanzen',
      ROLE: '',
      EMAIL: 'buchhaltung@arztpool.de',
      PHONE: '030 47 37 535 81',
    },
    {
      IMAGE: 'placeholder.png',
      NAME: 'IT',
      ROLE: '',
      EMAIL: 'info@arztpool.de',
      PHONE: '030 47 37 535 0',
    },
  ] as TeamIndividual[],
};
