export default {
  CLOSE: 'Schließen',
  HEADING: 'Information zu Ihrer Vermittlungsgebühr',
  SUB_HEADING: 'Priority',
  TEXT: [
    'Einfach Aufmerksamkeit erzielen - Ihr KV-Dienst wird unseren Vertretungsärzten empfohlen und in der Liste hervorgehoben. Dadurch erlangt Ihr Dienst höhere Aufmerksamkeit und erhält schneller passende Angebote.',
    'Ich bin einverstanden mit der Vermittlungsgebühr. Mir ist bewusst, dass diese erst nach Zuteilung des Dienstes fällig ist.',
  ],
  BUTTON: {
    CLOSE: 'Schließen',
    BOOK: 'Zubuchen'
  }
};
