class Upload {
  /**
   * convert blob to base64 string
   * @param {File|Blob} file
   * @return {Promise}
   */
  public async fileToBase64(file: File | Blob): Promise<string> {
    return new Promise((resolve, reject): void => {
      const reader: FileReader = new FileReader();

      reader.onload = (): void => {
        resolve(reader.result as string);
      };

      reader.onerror = (error: ProgressEvent<FileReader>): void => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  }

  /**
   * get upload information for a file to use in gpl mutations
   * @param {Blob} file
   * @return {Promise}
   */
  public async getUploadData(file: File | Blob): Promise<{ filename: string; url: string }> {
    return {
      filename: 'name',
      url: await this.fileToBase64(file),
    };
  }
}

export default new Upload();
