<template>

  <v-dialog v-model="showModalPreRegister" max-width="984">
    <v-card id="modalPreRegister">
      <a class="modal-close" @click="showPreRegister(false)">
        <span>{{ $t('PREREGISTER_MODAL.CLOSE') }}</span>
        <v-icon icon="fal fa-times" />
      </a>
      <v-card-title class="mb-16 d-inline">
        <h1 class="mb-8 text-center" style="word-break: break-word">
          {{ $t('PREREGISTER_MODAL.HEADING') }}
        </h1>
        <h2 class="mb-6 text-center" style="word-break: break-word">
          {{ $t('PREREGISTER_MODAL.SUBHEADING') }}
        </h2>
      </v-card-title>
      <div class="d-flex justify-center d-inline">
        <v-btn class="btn-cta me-8" @click.prevent="setUserType('partner')">
          {{ $t('PREREGISTER_MODAL.PARTNERBUTTON') }}
        </v-btn>
        <v-btn class="btn-cta" @click.prevent="setUserType('substitute')">
          {{ $t('PREREGISTER_MODAL.SUBSTITUTEBUTTON') }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue';
  import { useDisplay } from 'vuetify';
  import Stores from '@/stores';
  import EventBus from '@/services/EventBus';

  export default defineComponent({
    name: 'ModalRegister',
    components: {},
    setup() {
      const { smAndDown } = useDisplay();

      return {
        smAndDown,
        showModalPreRegister: ref(false),
        userType: ref(''),
      };
    },
    async beforeMount(): Promise<void> {
      // register for event bus to open modal
      EventBus.on(EventBus.keys.PREREGISTER_OPEN, (): void => {
        this.showPreRegister(true);
      });
    },
    methods: {
      /**
       * toggle preregister modal state (open/close)
       * @param {boolean} state
       * @return {void}
       */
      showPreRegister(state: boolean): void {
        this.showModalPreRegister = state;
      },

      /**
       * sets the user type the user wants to register as
       * @param {string} newUserType
       * @return {void}
       */
      setUserType(newUserType: string): void {
        this.userType = newUserType;
        this.showPreRegister(false);
        Stores.wizard.isQuickRegistration = true;
        this.$router.push({ name: 'Wizard', query: { role: this.userType } });
      },
      /**
       * open registration modal
       * @return {void}
       */
      showModalLogin(): void {
        EventBus.emit(EventBus.keys.LOGIN_OPEN, true);
      },
    }
  });
</script>

<style lang="scss" scoped>
  @import '@/scss/configs/colors';

  #modalPreRegister {
    overflow: hidden;

    .v-card-title {
      padding: 0;
    }
  }

  #modalRegister {
    overflow: hidden;

    .v-card-title {
      padding: 0;
    }

    img.passwordToggle:hover {
      cursor: pointer;
    }

  }
</style>
