export default {
  SUB_HEADING: [
    'Zu vertretender Dienst',
    'Rahmenbedingungen',
    'Manuelle Dienstgebietseingabe',
  ],
  LABELS: {
    ESTABLISHMENT: 'Betriebsstätte',
    DOCTOR: 'Arzt',
    KIND: 'Dienstart',
    FILTER: {
      OFFICE: 'Sitzdienste',
      MOBILE: 'Fahrdienste',
      HELP_TEXT: 'Wählen Sie aus, ob Ihr gewünschtes Dienstgebiet ein Fahr- oder Sitzdienstgebiet ist',
    },
    SERVICE_AREA: 'Bitte wählen Sie das korrekte Fahrdienstgebiet oder die Bereitschaftspraxis durch anklicken aus.',
    HOURS: 'Dienstzeiten',
    START: 'von (Datum)',
    END: 'bis (Datum)',
    FEE_HEADING: 'Honorar',
    EMERGENCY_FEE: 'Notfallscheinbeteiligung',
    FEE: 'Honorar für Ihren Vertreter',
    TIME_END: 'Endzeit',
    COMMENT: 'Kommentar',
    SUBMISSION: {
      MOBILE: 'Fahrdienst',
      OFFICE: 'Sitzdienst',
      NAME: 'Name des Dienstgebiets',
      INFORMATION: 'Bitte geben Sie uns ein paar Informationen zu ihrer Dienststätte',
      HELP_TEXT: 'Die hier angegebenen Informationen, werden von unserem Kundendienst geprüft, bevor der angelegte Dienst freigegeben wird.',
    },
    BREAK: {
      HEADING: 'Pausenzeiten',
      DESCRIPTION: [
        'Falls Sie zwei aufeinanderfolgende Dienste am selben Tag vertreten lassen wollen, können Sie dies über die Pausenzeiten realisieren. Wir werden dann einen Vertreter für beide Dienste suchen und Sie zahlen nur eine Vermittlungsgebühr.',
        'Tragen Sie dazu hier die Startzeit des ersten Dienstes als Startzeit ein, die Endzeit des zweiten Dienstes als Endzeit und legen Sie die Zeit zwischen den beiden Diensten als Pausentzeit an. Unser System errechnet dann die korrekte Arbeitszeit Ihres Vertreters und teilt diesem mit, welche Stunden er Ihnen als Arbeitszeit in Rechnung stellen kann.',
        'Wenn Sie nur einen Dienst eintragen wollen, lassen Sie die Pausenzeiten einfach leer und tragen Sie die Dienstzeiten wie gewohnt ein.',
      ],
      START: 'von (Datum)',
      END: 'bis (Datum)',
    }
  },
  COMMENT: {
    DESCRIPTION: 'Hier können Sie Bewerbern für diesen Dienst wichtige (entscheidungsrelevante) Informationen mitteilen, die er schon VOR einer Bewerbung kennen sollte, wie bestimmte fachliche Voraussetzungen, die jeder Bewerber erfüllen muss. Bitte beachten Sie, dass die Informationen, die Sie hier ggf. eintragen öffentlich sind - bitte geben Sie hier keine Telefonnummern oder ähnliche Informationen ein. Hinweise zur Durchführung des Dienstes können Sie nach Wahl Ihres Vertreters direkt austauschen, diese brauchen Sie hier nicht eingeben.',
  },
  NO_LOCATIONS: [
    'Das richtige Dienstgebiet ist noch nicht dabei? Dann geben Sie diese entweder hier manuell ein oder fügen Sie eine Dienststätte in Ihrem Profil hinzu',
    'Zum Profil',
    'Diese Betriebsstätte hat noch kein Dienstgebiet. Geben Sie diese entweder hier manuell ein oder fügen Sie eine Dienststätte in Ihrem Profil hinzu',
    'Diese Betriebsstätte hat noch kein Dienstgebiet. Fügen Sie eine Dienststätte in Ihrem Profil hinzu.'
  ],
  BTN: {
    ADD_SERVICE_LOCATION: 'Dienstgebiet manuell eingeben',
    SUBMIT: 'weiter',
    SUBMISSION: 'Manuelle Eingabe abbrechen'
  },
  TIME_FORMAT: '{date}, {time} Uhr',
  FEE_TOOLTIP: 'Bitte beachten Sie, dass sich mit der Erhöhung des Honorars für Vertreter, möglicherweise auch die Anzahl der Bewerber erhöhen könnte.',
  TICK_LABEL: 'von {value}',
  SLIDER_LABEL: 'bis {value}'
};
