export default {
  MENU: {
    TITLE: 'Menu',
    LINKS: {
      DASHBOARD: 'Dashboard',
      SERVICES: 'Services',
      DOCUMENTS: 'Documents',
    },
  },
  MY_DOCTOR_POOL: {
    TITLE: 'My arztpool',
    TEASER_LOGIN: 'Click here to login or register',
    TEASER_LOGOUT: 'Click here to logout.',
    LOGIN: 'Login / Register',
    LOGOUT: 'Logout',
    LINKS: {
      DASHBOARD: '@:NAVIGATION.MENU.LINKS.DASHBOARD',
      SERVICES: '@:NAVIGATION.MENU.LINKS.SERVICES',
      DOCUMENTS: '@:NAVIGATION.MENU.LINKS.DOCUMENTS',
      MESSAGES: 'Messages',
      OPERATORS: 'Operators',
      PROVIDE_SERVICE: 'KV-Dienst vertreten lassen',
      REPRESENT_SERVICE: 'KV-Dienst übernehmen',
      MVZ: 'Dienstüberlassung für MVZ',
      HELP: 'Technische Hilfe',
      SUBSTITUTES:{
        YOUR_ACCOUNT : 'Your Account',
        BASIC_DATA: 'Basic Data',
        BILLING_DATA : 'Billing Data',
        INSURANCE:'Insurance',
        HEALTH_INSURANCE:'Health Insurance',
      },
      PARTNER:{
        INSTITUTION : 'Institution',
        BUSINESS_ESTABLISHMENT : 'Business Establishments',
      }
    },
  },
};
