import Quote from '@/definitions/interfaces/locales/Quote.i';
import Question from '@/definitions/interfaces/locales/Question.i';
import InfoCard from '@/definitions/interfaces/locales/InfoCard.i';
import Statistic from '@/definitions/interfaces/locales/Statistic.i';

export default {
  META: {
    TITLE: 'Das Vermittlungsportal für Ärzte im KV-Bereitschaftsdienst',
  },
  BUTTON_PROVIDE: 'Ich möchte KV-Dienste abgeben',
  BUTTON_REPRESENT: 'Jetzt Dienst vertreten',
  BUTTON_MORE_INFO: 'Mehr Informationen',
  BUTTON_MORE_INFO_TEAM: 'Zum Team',
  TEASER_BTN: 'KV-Dienst vetreten lassen ab 69€',
  HEADER: {
    HEADING: 'Ihr Vermittlungsportal für KV-Dienste',
    HEADING_SUB: 'Unabhängig. Zuverlässig. Digital.',
    BUTTON_TEST_AP: 'arztpool unverbindlich testen',
  },
  INTRODUCTION: {
    HEADING: 'KV-Bereitschaftsdienste koordinieren mit arztpool',
    HEADING_SUB: 'Was macht arztpool',
    DESCRIPTION:
      'KV-Dienste sind Bereitschaftsdienste, welche von zugelassenen Ärzten in einem Medizinischen Versorgungszentrum oder von niedergelassenen Ärzten mit Mitgliedschaft in der Kassenärztlichen Vereinigung (KV) übernommen werden. Das Ziel der Bereitschaftsdienste der KV ist es, Kassen- sowie Privatpatienten außerhalb regulärer Praxis-Öffnungszeiten ambulant ärztlich zu versorgen.',
  },
  QUICKSTART: {
    TAB: {
      PARTNER: 'Dienst vertreten lassen',
      SUBSTITUTE: 'Dienst übernehmen',
    },
    LABELS: {
      SERVICE_MOBILE: 'Fahrdienst',
      SERVICE_OFFICE: 'Sitzdienst',
      NO_SERVICE: 'Ohne Dienst',
      STATE: 'Bundesland',
      STATES: 'Bundesländer',
      START_DATE: 'Dienstbeginn',
      END_DATE: 'Dienstende',
      END_TIME: 'Endzeit',
      FEE: 'Honorar für Ihren Vertreter €/Std.',
      FROM_DATE: 'von (Datum)',
      TO_DATE: 'bis (Datum)',
    },
    NO_SERVICE: {
      TITLE: 'Ohne Dienst testen',
      TEXT: 'Testen Sie arztpool ohne einen Dienst einzutragen.',
    },
    TIME_FORMAT: '{date}, {time} Uhr',
    SUBSTITUTE_TEXT: 'In welchem Zeitraum möchten Sie KV-Dienste vertreten?',
    POTENTIAL_SUBSTITUTES:
      'Wählen Sie den finanziellen Rahmen für Ihren KV-Dienst, indem Sie das maximale Stundenhonorar angeben. Je höher der Stundensatz, desto attraktiver wird der Dienst für potentielle Vertreter.',
    BUTTON_PARTNER: 'arztpool unverbindlich testen',
    BUTTON_SUBSTITUTE: 'KV-Dienste anzeigen',
  },
  SERVICES: {
    HEADING: 'Wie können wir Ihnen helfen?',
    HEADING_SUB: 'Unsere Services für Sie',
    CARDS: [
      {
        TITLE: 'Ich möchte KV-Dienste abgeben',
        TEXT: 'Services für Einzel- und Gemeinschaftspraxen',
        LINK: '/kv-dienst-vertreten-lassen',
      },
      {
        TITLE: 'Ich möchte KV-Dienste abgeben',
        TEXT: 'Services für medizinische Versorgungszentren (MVZ) ',
        LINK: '/mvz',
      },
      {
        TITLE: 'Ich möchte KV-Dienste vertreten',
        TEXT: 'Services für Vertretungsärzte',
        LINK: '/kv-dienst-uebernehmen',
      },
    ] as InfoCard[],
  },
  STATISTICS: [
    {
      STAT: 'knapp 500.000',
      LABEL: 'Dienststunden\nvermittelt pro Jahr',
    },
    {
      STAT: 'über 2.500',
      LABEL: 'Vertretungsärzte\ndeutschlandweit',
    },
    {
      STAT: 'über 99%',
      LABEL: 'erfolgreiche\nVermittlungen',
    },
    {
      STAT: '12 Jahre',
      LABEL: 'erfolgreich\nam Markt',
    },
  ] as Statistic[],
  ABOUT: {
    HEADING: 'Wer steckt hinter arztpool?',
    DESCRIPTION:
      'Wir sind Ärzte, Kaufleute, Juristen, Logistiker, Programmierer und Buchhalter. Seit vielen Jahren beschäftigen wir uns mit dem Thema Vertretung im kassenärztlichen Bereitschaftsdienst und stehen Ärzten mit unserem interdisziplinären Team mit Rat und Tat zur Seite.',
  },
  QUOTE: {
    NORMAL: 'Die arztpool-Formel:',
    HIGHLIGHT: '» 30 | 50 | 100 «',
    NAME: 'In 30 Sekunden registrieren - über 50 Wochen profitieren - 100 Prozent Vorteile nutzen',
    POSITION: '',
  } as Quote,
  QUESTIONS: [
    {
      QUESTION: 'Wie kann die Vertretung meiner KV-Dienste von Berlin aus funktionieren?',
      ANSWER:
        'arztpool ist seit vielen Jahren in der Vertretung für Kassenärztliche Bereitschaftsdienste aktiv, alle Mitarbeiter verfügen über mehrjährige Expertise. Über die Jahre haben wir uns ein deutschlandweites Netzwerk an Dienstvertretungsärzten aufgebaut, die auch Ihren Dienst übernehmen können, egal aus welchem Bundesland Sie als Kunde zu uns kommen. arztpool ist deutschlandweit aktiv.',
    },
    {
      QUESTION: 'Welche Kosten kommen als Kunde/ Partner von arztpool für die Vermittlung meiner Bereitschaftsdienste auf mich zu?',
      ANSWER:
        'Wir berechnen lediglich eine Vermittlungsgebühr pro vermittelten Dienst, die sich nach der Länge des Dienstes berechnet. Sollte der Vertretungsarzt keinen Arztkoffer besitzen und Sie ihm auch keinen zur Verfügung stellen können, dann können wir mit Ihrem Einverständnis dem Vertreter eine Mappe mit einer Grundausstattung schicken. Ihnen berechnen wir nur den Versand, nicht den Koffer selbst.',
    },
    {
      QUESTION: 'Wie erfahre ich, wer meinen Dienst vertritt?',
      ANSWER:
        'Wenn Sie Ihren Dienst auf unserer Plattform eingetragen haben, schreiben wir ihn auf dem Marktplatz aus. Sobald wir einen Arzt gefunden haben, und Sie als Kunde und der Vertreter dem Dienstvertretungsvertrag zugestimmt haben, teilen wir Ihnen alle relevanten Daten mit, sodass Sie ihn als Ihren Vertreter bei der KV melden können.',
    },
    {
      QUESTION: 'Wie bekommt mein Vertreter die Unterlagen für seinen Dienst?',
      ANSWER:
        'Sobald Sie Kunde bei arztpool geworden sind schicken Sie uns die notwendigen Unterlagen wie Kartenlesegerät, Notfallscheine, Kassenrezepte, Krankenhauseinweisungen zu. Ein Mitarbeiter nimmt dazu Kontakt mit Ihnen auf. Wir kontaktieren dann zeitnah vor jedem Dienst den Vertreter und lassen ihm die Unterlagen zukommen. Ihr Kartenlesegerät erhalten Sie jeweils am Quartalsende für die Abrechnung zurück.',
    },
    {
      QUESTION: 'Muss ich meinen Vertreter der KV melden?',
      ANSWER:
        'Ja, sobald wir Ihnen den Vertreter mitgeteilt haben, müssen Sie ihn auch bei der KV melden, sodass es nicht zu Missverständnissen, etwa bei der Dienstanmeldung kommt und man den Vertreter nicht als solchen akzeptiert. Ihre KV hat dafür normalerweise eigene Formulare. Nähere Informationen bekommen Sie von der für Sie zuständigen KV.',
    },
    {
      QUESTION: 'Wie ist die Haftungsfrage während des Dienstes geregelt?',
      ANSWER:
        'Grundsätzlich haften Sie als der zum Dienst verpflichtete und eingeteilte Arzt. Kommen Sie aber Ihrer Sorgfaltspflicht nach und vergewissern sich, dass der eingeteilte Arzt über die notwendigen Qualifikationen (Approbation, Berufshaftpflichtversicherung) verfügt, dann geht die Haftung auf den Vertretungsarzt über. Wenn Sie unsere Dienstleistung in Anspruch nehmen, übernehmen wir für Sie die (Sicht-)Prüfung von Approbation und Berufshaftpflichtversicherung.',
    },
  ] as Question[],
  ADVANTAGES: [
    'Marktführer-Vorteile nutzen',
    'Deutschlandweit zuverlässige Garantie',
    'Qualitätssicherung: interne Prüfverfahren',
  ],
  STEPS: {
    HEADING: 'So geht\'s',
    HEADING_SUB: 'KV-Dienste einfach delegieren:',
    BUTTON: 'Jetzt KV-Dienste vertreten lassen',
    CARDS: [
      {
        TITLE: 'Dienst eintragen',
        TEXT: 'Mühelos können Sie Ihre KV-Dienste auf arztpool.de eintragen - kostenfrei und ohne Verpflichtungen',
      },
      {
        TITLE: 'Angebot erhalten',
        TEXT: 'Innerhalb von 48 Stunden bekommen Sie Bewerbungen von passenden Vertretungsärzten, aus denen Sie Ihre Favoriten auswählen können',
      },
      {
        TITLE: 'Zuverlässige Vertretung',
        TEXT: 'Sobald beide Parteien den Dienstvertretungsvertrag bestätigt haben, erhalten Sie sämtliche relevanten Daten Ihres Vertreters, um ihn bei der KV zu melden.',
      },
    ],
  },
};
