export default {
  HEADING: 'Willkommen!',
  CLOSE: 'Schließen',
  FORM: {
    MAIL: 'E-Mail',
    PASSWORD: 'Passwort',
    PASSWORD_SHOW: 'Passwort anzeigen',
    PASSWORD_HIDE: 'Passwort verbergen',
    REMAIN_SIGNUP: 'Angemeldet bleiben',
    SUBMIT: 'Anmelden',
  },
  RESET_PASSWORD: 'Passwort vergessen?',
  ERROR: {
    INCORRECT_USERNAME_OR_PASSWORD: 'E-Mail oder Passwort nicht korrekt.',
    USER_IS_NOT_CONFIRMED: 'Dieses Nutzerkonto wurde noch nicht bestätigt.',
    PASSWORD_RESET_REQUIRED_FOR_THE_USER: 'Sie müssen Ihr Passwort ändern, um sich einzuloggen.',
  },
  FOOTER: {
    HEADING: 'Ich bin neu hier.',
    REGISTERBUTTON: 'Registrieren',
    AGREEMENT: [
      'Mit Erstellung eines Accounts stimmen Sie unseren',
      'AGB',
      'und',
      'Datenschutzbestimmungen',
      'zu.',
    ],
  },
};
