export default {
  CLOSE: 'Schließen',
  HEADER: 'Information zu Sofort-Zusage',
  SUB_HEADER: 'Sie haben sich für die Sofort-Zusage entschieden, um sich diesen KV-Dienst direkt und ohne Wartezeit zu sichern.',
  CARD: {
    HEADER: 'Sofort-Zusage',
    TEXT: 'Die Sofort-Zusage ermöglicht Ihnen, sich Ihren Dienst direkt und ohne Wartezeit zu sichern. Sie akzeptieren damit das Mindesthonorar von {minFee}€/Std. für diesen KV-Dienst. Für Sie entstehen verbindliche Kosten für die arztpool-Vermittlungsgebühr von {price}.'
  },
  CONFIRM: 'Ich bin einverstanden mit der Vermittlungsgebühr. Mir ist bewusst, dass diese direkt nach Beendigung des Dienstes fällig ist. Mir ist ebenfalls bewusst, dass ich mit einer verbindlichen Bewerbung den Dienstvertretungsvertrag im Falle einer erfolgreichen Vermittlung akzeptiere.',
  BUTTON: {
    CANCEL: 'Abbrechen',
    SUBMIT: 'Bestätigen'
  }
};
