export default {
  ROW: [
    {
      HEADING: 'Maximales Honorar Vertreter',
      TEXT: 'Wird auf Basis Ihrer vorgegebenen Honorarspanne angegeben.',
    },
    {
      HEADING: 'Vermittlungsgebühr für Rundum-Sorglos-Paket',
      TEXT: 'Die Gebühr wird auf Basis Ihres Einstelldatums kalkuliert.',
    },
    {
      HEADING: 'Vermittlungsgebühr',
      TEXT: 'Die Gebühr wird auf Basis Ihres Einstelldatums kalkuliert.'
    },
    {
      HEADING: 'Zusatzoptionen: Priority',
      TEXT: 'Die Gebühr wird auf Basis Ihrer Einstellungen sowie Ihres Einstelldatums kalkuliert.',
    },
    {
      HEADING: 'KV-Zahlung an Sie',
      TEXT: 'Wird auf Basis des KV-Durchschnittshonorars angegeben.',
    },
    {
      HEADING: 'Gesamtkosten',
      TEXT: 'Zzgl. Beteiligungssumme pro Notfallschein'
    }
  ]
};
