import PartnerFeesTableEntry from '@/definitions/interfaces/locales/PartnerFeesTableEntry.i';

export default {
  HEADING: 'Das passende Paket für Sie',
  BUTTON: 'Upgraden',
  MODAL_CLOSE: 'schließen',
  ASTERISK: '*Rundum-Sorglos-Paket',
  HEADER: {
    ALLIN: 'RUSP*',
    DEFAULT: 'Standard',
    URGENT: 'Eil',
    EXPRESS: 'Express',
  },
  PRICES: {
    TITLE: 'Paketpreis',
  },
  TIMING: {
    TITLE: [
      'Gültigkeit nach Zeitraum',
      'vor dem Dienst',
    ],
    DAYS: 'Tage',
  },
  ENTRIES: [
    {
      TITLE: [
        'Nutzung Ausschreibungssoftware',
        '(Ausschreibung Dienstvergabe über Marktplatz)',
      ],
      CHECKED: {
        ALLIN: true,
        DEFAULT: true,
        URGENT: true,
        EXPRESS: true,
      },
    },
    {
      TITLE: [
        'Von ap24 geprüfte Vertreter',
        '(Approbation, Haftpflicht, Facharzt)',
      ],
      CHECKED: {
        ALLIN: true,
        DEFAULT: true,
        URGENT: true,
        EXPRESS: true,
      },
    },
    {
      TITLE: [
        'Digitale Vertretervermittlung',
        '(schnell und papierlos)',
      ],
      CHECKED: {
        ALLIN: true,
        DEFAULT: true,
        URGENT: true,
        EXPRESS: true,
      },
    },
    {
      TITLE: [
        'Systemisches Erinnerungswesen',
        '(zukünftige Funktion)',
      ],
      CHECKED: {
        ALLIN: true,
        DEFAULT: true,
        URGENT: true,
        EXPRESS: true,
      },
    },
    {
      TITLE: [
        'Nutzung Mustervorlage',
        'Dienstvertretungsvertrag',
      ],
      CHECKED: {
        ALLIN: true,
        DEFAULT: true,
        URGENT: true,
        EXPRESS: true,
      },
    },
    {
      TITLE: [
        'Verwaltung von Betriebsstätten,',
        'Ärzten und Diensten',
      ],
      CHECKED: {
        ALLIN: true,
        DEFAULT: true,
        URGENT: true,
        EXPRESS: true,
      },
    },
    {
      TITLE: [
        'Statusinformationen',
        'über Dienst',
      ],
      CHECKED: {
        ALLIN: true,
        DEFAULT: true,
        URGENT: true,
        EXPRESS: true,
      },
    },
    {
      TITLE: [
        'Notfallbereitschaft 24/7',
      ],
      CHECKED: {
        ALLIN: true,
        DEFAULT: true,
        URGENT: true,
        EXPRESS: true,
      },
    },
    {
      TITLE: [
        'Kontrolle über die Auswahl',
        'des Vertreters',
      ],
      CHECKED: {
        ALLIN: true,
        DEFAULT: true,
        URGENT: true,
        EXPRESS: true,
      },
    },
    {
      TITLE: [
        'Persönlicher Ansprechpartner',
        'für Ihren Dienst',
      ],
      CHECKED: {
        ALLIN: true,
        DEFAULT: false,
        URGENT: true,
        EXPRESS: true,
      },
    },
    {
      TITLE: [
        'Anleitung zum Gelingen/Checkliste',
        '(Material, ...)',
      ],
      CHECKED: {
        ALLIN: true,
        DEFAULT: false,
        URGENT: true,
        EXPRESS: true,
      },
    },
    {
      TITLE: [
        'Sie übergeben uns digital die Dienstdaten,',
        'wir übernehmen den Rest',
      ],
      CHECKED: {
        ALLIN: true,
        DEFAULT: false,
        URGENT: false,
        EXPRESS: true,
      },
    },
    {
      TITLE: [
        'Wir organisieren Bewerberanfragen',
        'im Rahmen Ihrer Honorarvorgaben',
      ],
      DESCRIPTION: null,
      CHECKED: {
        ALLIN: true,
        DEFAULT: false,
        URGENT: false,
        EXPRESS: true,
      },
    },
    {
      TITLE: [
        'Wir organisieren die rechtzeitige Versendung',
        'von KL und Unterlagen',
      ],
      CHECKED: {
        ALLIN: true,
        DEFAULT: false,
        URGENT: false,
        EXPRESS: false,
      },
    },
  ] as PartnerFeesTableEntry[],
};
