export default {
  HEADING: 'Dienstgebiete',
  HEADING_SINGLE_SERVICE_AREA: 'Dienst Submissions',
  TABLE_HEADERS: {
    NAME: 'Name',
    STATE: 'Bundesland',
    OFFICE_SERVICES: 'Sitzdienste',
    MOBILE_SERVICES: 'Fahrdienste',
    ACTIONS: 'Aktionen',
  },
  FORM_LABELS: {
    STATE: 'Bundesland',
    SUBMIT: 'Suchen',
    NEW_AREA: 'Neues Dienstgebiet anlegen'
  },
  MODAL: {
    HEADING: 'Neues Dienstgebiet anlegen',
    CLOSE: 'Schließen',
  },
  SINGLE_SERVICEAREA: {
    HEADING: 'Dienstgebiet',
    HEADING_MOBILESERVICES: 'Fahrdienste',
    HEADING_NEW_MOBILESERVICE: 'Neuen Fahrdienst hinzufügen',
    HEADING_OFFICESERVICES: 'Sitzdienste',
    HEADING_NEW_OFFICESERVICE: 'Neuen Sitzdienst hinzufügen',
    NO_MOBILESERVICES: 'Noch keine Fahrdienste angelegt...',
    NO_OFFICESERVICES: 'Noch keine Sitzdienste angelegt...',
    FORM_LABELS: {
      NAME: 'Name',
      STATE: 'Bundesland',
      VALID_FROM: 'Gültig von',
      VALID_TO: 'Gültig bis',
      SHORTHAND: 'Kürzel',
      COORDINATES: 'Koordinaten',
      COMMENT: 'Kommentar',
      COMMENT_PLACEHOLDER: 'Dieser Kommentar ist öffentlich.',
      CONFIDENTIAL_COMMENT: 'Vertraulicher Kommentar',
      CONFIDENTIAL_COMMENT_PLACEHOLDER: 'Vertrauliche Informationen zu diesem Dienst',
      LEGACY_ID: 'Legacy ID',
      CITY: 'Stadt',
      ZIP: 'PLZ',
      STREET: 'Straße',
      HOUSENUMBER: 'Hausnummer',
      SUBMIT: 'Speichern',
    }
  }
};
