export default {
  TITLE: {
    PREFIX: '',
    SUFFIX: '',
    DEFAULT: '',
  },
  DESCRIPTION: {
    DEFAULT: '',
  },
};
