export default {
  HEADING: 'Reporting',
  FILTERS: 'Suchparameter',
  DESCRIPTION: 'Bitte wählen Sie zunächst einen Zeitraum aus, für den Sie Dienste laden wollen. Sie können diese dann in der angezeigten Tabelle überprüfen oder einzeln aufrufen. Den Export der geladenen Dienste wird dann mit dem entsprechenden Button angestoßen.',
  DESCRIPTION_2: 'Bitte beachten Sie: Die Daten, die für die geladenen Dienste exportiert werden, sind umfangreicher als sie in der Tabelle angezeigt werden, diese dient nur der Kontrolle, welche Dienste in dem gewählten Zeitraum enthalten sind.',
  SERVICES: 'Eingetragene Dienste',
  USERS: 'Registrierte Nutzer',
  FORM_LABELS: {
    SUBMIT: 'Suchen',
    FROM: 'Erstellt Von',
    TO: 'Erstellt Bis',
    ONE_DAY: 'Letzte 24h',
    ONE_WEEK: 'Letzte 7 Tage',
    ONE_MONTH: 'Letzte 30 Tage',
  },
  MOBILE_SERVICE: 'Fahrdienst',
  OFFICE_SERVICE: 'Sitzdienst',
  TABLE_HEADERS_SERVICES: {
    TYPE: 'Dienstart',
    PARTNER: 'Kunde',
    DEBTOR_NUMBER: 'Kundennummer',
    CREATED_ON: 'Eingetragen am',
    START: 'Dienststart',
    END: 'Dienstende',
    SERVICE_AREA: 'Dienstgebiet',
    STATE: 'Bundesland',
    SUBSTITUTE: 'Vertreter',
    STATUS: 'Status',
    ACTIONS: 'Aktionen',
  },
  TABLE_HEADERS_USERS: {
    ROLES: 'Rollen',
    FIRSTNAME: 'Vorname',
    LASTNAME: 'Nachname',
    DEBTOR_NUMBER: 'Kundennummer',
    REGISTERED_ON: 'Registriert am',
    EMAIL: 'E-Mail',
    VERIFIED: 'Verifiziert',
    ACTIONS: 'Aktionen',
  },

};
