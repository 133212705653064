import { DocumentNode } from 'graphql/language';
import gql from 'graphql-tag';

export const REGISTER_PROFILE: DocumentNode = gql`
  mutation RegisterProfileMutation(
    $title: String!
    $firstName: String!
    $lastName: String!
    $birthDate: Date!
    $mobile1: String
    $mobile2: String
    $fax: String
    $phone: String
    $legacyID: Int
  ) {
    register(
      title: $title
      firstName: $firstName
      lastName: $lastName
      birthDate: $birthDate
      mobile1: $mobile1
      mobile2: $mobile2
      fax: $fax
      phone: $phone
      legacyID: $legacyID
    )
  }
`;

export const UPDATE_PROFILE: DocumentNode = gql`
  mutation UpdateProfileMutation(
    $title: String
    $firstName: String
    $lastName: String
    $birthDate: Date
    $mobile1: String
    $mobile2: String
    $fax: String
    $phone: String
  ) {
    updateProfile(
      title: $title
      firstName: $firstName
      lastName: $lastName
      birthDate: $birthDate
      mobile1: $mobile1
      mobile2: $mobile2
      fax: $fax
      phone: $phone
    )
  }
`;

export const UPDATE_SUBSTITUTE: DocumentNode = gql`
  mutation UpdateSubstitute(
    $substituteID: UUID!
    $trainingConfirmed: Boolean
    $taxNumber: String
    $license: UploadInput
    $liabilityInsurance: DocumentLiabilityInsuranceInput
    $has_doctors_bag: Boolean
    $use_default_contract: Boolean
  ) {
    updateSubstitute(
      substituteID: $substituteID
      trainingConfirmed: $trainingConfirmed
      license: $license
      taxNumber: $taxNumber
      liabilityInsurance: $liabilityInsurance
      has_doctors_bag: $has_doctors_bag
      use_default_contract: $use_default_contract
    )
  }
`;

export const ADD_SUBSTITUTE_SPECIALIZATION: DocumentNode = gql`
  mutation AddSubstituteSpecialization($specializations: [SubstituteSpecializationInput!]!) {
    addSubstituteSpecializations(specializations: $specializations)
  }
`;

export const UPDATE_SPECIALIZATION: DocumentNode = gql`
  mutation UpdateSpecializations($id: UUID!, $certificate: UploadInput!) {
    updateSubstituteSpecializations(id: $id, certificate: $certificate)
  }
`;

export const UPDATE_ADDRESSES: DocumentNode = gql`
  mutation UpdateAddresses($addresses: [UpdateAddressInput!]!) {
    updateAddresses(addresses: $addresses)
  }
`;

export const UPDATE_BANKACCOUNTS: DocumentNode = gql`
  mutation UpdateBankaccounts($accounts: [BankAccountUpdateInput!]!) {
    updateBankAccounts(accounts: $accounts)
  }
`;

export const REQUEST_SUBSTITUTE: DocumentNode = gql`
  mutation RequestSubstituteMutation(
    $trainingConfirmed: Boolean!
    $taxNumber: String!
    $address: AddressInput!
    $billingAddress: AddressInput!
    $bankAccount: BankAccountInput!
  ) {
    requestSubstituteRole(
      trainingConfirmed: $trainingConfirmed
      taxNumber: $taxNumber
      address: $address
      billingAddress: $billingAddress
      bankAccount: $bankAccount
    )
  }
`;

export const REQUEST_PARTNER: DocumentNode = gql`
  mutation RequestPartnerMutation($institution: InstitutionInput!) {
    requestPartnerRole(institution: $institution)
  }
`;

export const CREATE_ESTABLISHMENTS: DocumentNode = gql`
  mutation CreateEstablishments($establishments: [EstablishmentInput!]!) {
    createEstablishments(establishments: $establishments)
  }
`;

export const SUBMIT_OFFICESERVICE: DocumentNode = gql`
  mutation SubmitOfficeService(
    $services: [OfficeServiceInput!]!
    $totalCount: Float
    $publish: Boolean!
  ) {
    submitOfficeServices(
      services: $services
      totalCount: $totalCount
      publish: $publish
    )
  }
`;

export const SUBMIT_MOBILESERVICE: DocumentNode = gql`
  mutation SubmitMobileService(
    $services: [MobileServiceInput!]!
    $totalCount: Float
    $publish: Boolean!
  ) {
    submitMobileServices(
      services: $services
      totalCount: $totalCount
      publish: $publish
    )
  }
`;

export const UPDATE_INSTITUTIONS: DocumentNode = gql`
  mutation UpdateInstitutions($institutions: [InstitutionUpdateInput!]!) {
    updateInstitutions(institutions: $institutions)
  }
`;

export const UPDATE_ESTABLISHMENTS: DocumentNode = gql`
  mutation UpdateEstablishments($establishments: [EstablishmentUpdateInput!]!) {
    updateEstablishments(establishments: $establishments)
  }
`;
export const APPLY: DocumentNode = gql`
  mutation Apply($serviceID: UUID!, $chainCandidate: Boolean!, $offer: Float!, $instantAssign: Boolean!) {
    apply(serviceID: $serviceID, chainCandidate: $chainCandidate, offer: $offer, instantAssign: $instantAssign)
  }
`;

export const ADD_ESTABLISHMENT_DOCTOR: DocumentNode = gql`
  mutation AddEstablishmentDoctor($establishmentID: UUID!, $name: String!, $kvSeats: Float!, $lanr: String!) {
    addEstablishmentDoctor(establishmentID: $establishmentID, name: $name, kvSeats: $kvSeats, lanr: $lanr)
  }
`;

export const UPDATE_ESTABLISHMENT_DOCTOR: DocumentNode = gql`
  mutation UpdateEstablishmentDoctor($establishmentDoctorID: UUID!, $name: String, $kvSeats: Float, $lanr: String) {
    updateEstablishmentDoctor(establishmentDoctorID: $establishmentDoctorID, name: $name, kvSeats: $kvSeats, lanr: $lanr)
  }
`;

export const DELETE_ESTABLISHMENT_DOCTOR: DocumentNode = gql`
  mutation DeleteEstablishmentDoctor($establishmentDoctorID: UUID!) {
    deleteEstablishmentDoctor(establishmentDoctorID: $establishmentDoctorID)
  }
`;

export const MARK_MESSAGE_READ: DocumentNode = gql`
  mutation MarkMessageRead($messageID: UUID!) {
    confirmMessageRead(messageID: $messageID)
  }
`;

export const ADD_PAYMENT_METHOD_BILL: DocumentNode = gql`
  mutation AddPaymentMethodBill($debtorID: UUID!, $bankAccount: BankAccountInput) {
    addDebtorBillPaymentMethod(debtorID: $debtorID, bankAccount: $bankAccount)
  }
`;

export const ADD_PAYMENT_METHOD_SEPA: DocumentNode = gql`
  mutation AddPaymentMethodSepa($debtorID: UUID!, $bankAccount: BankAccountInput!) {
    addDebtorSepaPaymentMethod(debtorID: $debtorID, bankAccount: $bankAccount)
  }
`;

export const SET_PARTNER_TARIFF: DocumentNode = gql`
  mutation SetPartnerTariff($debtorID: UUID!, $period: Int, $paymentPeriod: Int, $paymentMethodID: UUID) {
    setPartnerDebtorTariff(debtorID: $debtorID, period: $period, paymentPeriod: $paymentPeriod, paymentMethodID: $paymentMethodID)
  }
`;

export const SUBMIT_SERVICEAREA_REQUEST: DocumentNode = gql`
  mutation SubmitServiceareaRequest($msg: String!) {
    submitServiceAreaEntryRequest(msg: $msg)
  }
`;

export const SUBMIT_TEMPORARY_SERVICE: DocumentNode = gql`
  mutation SubmitTemporaryService($establishmentID: UUID!, $content: String!) {
    submitServiceSubmission(establishmentID: $establishmentID, content: $content)
  }
`;

export const UPDATE_SERVICE_WITH_NO_APPLICANTS: DocumentNode = gql`
  mutation UpdateService(
    $serviceID: UUID!
    $establishmentDoctorID: UUID
    $regularFeeRange: ServiceFeeRangeUpdateInput
    $start: DateTime
    $end: DateTime
    $establishmentID: UUID
    $instantAssign: Boolean
    $priority: Boolean
    $kvShareRateMobile: Float
    $kvShareRateOffice: Float
  ) {
    updateService(
      serviceID: $serviceID
      establishmentDoctorID: $establishmentDoctorID
      regularFeeRange: $regularFeeRange
      start: $start
      end: $end
      establishmentID: $establishmentID
      instantAssign: $instantAssign
      priority: $priority
      kvShareRateMobile: $kvShareRateMobile
      kvShareRateOffice: $kvShareRateOffice
    )
  }
`;

export const UPDATE_SERVICE: DocumentNode = gql`
  mutation UpdateService(
    $serviceID: UUID!
    $comment: String
    $establishmentDoctorID: UUID
    $regularFeeRange: ServiceFeeRangeUpdateInput
    $holidayFeeRange: ServiceFeeRangeUpdateInput
    $start: DateTime
    $end: DateTime
    $partnerConfirmedContract: Boolean
    $substituteConfirmedContract: Boolean
    $stateInsuredPatientCount: Int
    $privatePatientCount: Int
    $necropsyCount: Int
    $consultationViaTelephoneCount: Int
    $confirmCounts: Boolean
    $establishmentID: UUID
    $substituteConfirmedServiceInfo: Boolean
  ) {
    updateService(
      serviceID: $serviceID
      comment: $comment
      establishmentDoctorID: $establishmentDoctorID
      regularFeeRange: $regularFeeRange
      holidayFeeRange: $holidayFeeRange
      start: $start
      end: $end
      partnerConfirmedContract: $partnerConfirmedContract
      substituteConfirmedContract: $substituteConfirmedContract
      stateInsuredPatientCount: $stateInsuredPatientCount
      privatePatientCount: $privatePatientCount
      necropsyCount: $necropsyCount
      consultationViaTelephoneCount: $consultationViaTelephoneCount
      confirmCounts: $confirmCounts
      establishmentID: $establishmentID
      substituteConfirmedServiceInfo: $substituteConfirmedServiceInfo
    )
  }
`;

export const SEND_MESSAGE_TECHNICAL_HELP: DocumentNode = gql`
  mutation SendMessageTechnicalHelp(
    $text1: String
    $text2: String
    $text3: String
    $text4: String
    $text5: String
    $text6: String
    $email: String
    $phone: String
    $readPrivacy: Boolean!
  ) {
    sendMessageTechnicalHelp(
      text1: $text1
      text2: $text2
      text3: $text3
      text4: $text4
      text5: $text5
      text6: $text6
      email: $email
      phone: $phone
      readPrivacy: $readPrivacy
    )
  }
`;

export const ADD_SUBSTITUTE_BOOKMARK: DocumentNode = gql`
  mutation addServiceBookmark($serviceID: UUID!, $substituteID: UUID!) {
    addServiceBookmark(serviceID: $serviceID, substituteID: $substituteID) {
      __typename
    }
  }
`;

export const DELETE_SUBSTITUTE_BOOKMARK: DocumentNode = gql`
  mutation deleteServiceBookmark($substituteID: UUID!, $serviceID: UUID!) {
    deleteServiceBookmark(substituteID: $substituteID, serviceID: $serviceID)
  }
`;

export const DELETE_SUBSTITUTE_BOOKMARK_BY_ID: DocumentNode = gql`
  mutation deleteServiceBookmarkById($bookmarkID: UUID!) {
    deleteServiceBookmarkById(bookmarkID: $bookmarkID)
  }
`;

export const ASSIGN_SUBSTITUTE: DocumentNode = gql`
  mutation assignService($serviceID: UUID!, $substituteID: UUID!) {
    assignService(serviceID: $serviceID, substituteID: $substituteID)
    __typename
  }
`;

/*
export const UNASSIGN_SUBSTITUTE: DocumentNode = gql `
  mutation DeleteServiceAssignment($serviceID: UUID!) {
    revokeAssignment(serviceID: $serviceID)
  }
`;
*/

export const SUBMIT_SUBSTITUTE_CHECKLIST: DocumentNode = gql`
  mutation submitServiceInformation(
    $serviceID: UUID!
    $substituteID: UUID!
    $defaultContract: Boolean!
    $contractInfo: String!
    $bagInfo: String!
    $cardreaderInfo: String!
    $medicalDocumentsInfo: String!
    $miscInfo: String!
  ) {
    submitServiceInformation(
      serviceID: $serviceID
      substituteID: $substituteID
      defaultContract: $defaultContract
      contractInfo: $contractInfo
      bagInfo: $bagInfo
      cardreaderInfo: $cardreaderInfo
      medicalDocumentsInfo: $medicalDocumentsInfo
      miscInfo: $miscInfo
    )
  }
`;

export const SUBMIT_SERVICE_MESSAGE: DocumentNode = gql`
  mutation sendMessage($subject: String!, $content: String!, $userID: UUID!, $serviceID: UUID) {
    sendMessage(serviceID: $serviceID, content: $content, subject: $subject, userID: $userID)
  }
`;

export const APPROVE_EMERGENCY_NOTE: DocumentNode = gql`
  mutation approveServiceEmergencyNote($serviceEmergencyNoteId: UUID!) {
    approveServiceEmergencyNote(serviceEmergencyNoteId: $serviceEmergencyNoteId) {
      id
      approved_date
    }
  }
`;

export const DECLINE_EMERGENCY_NOTE: DocumentNode = gql`
  mutation declineServiceEmergencyNote($serviceEmergencyNoteId: UUID!) {
    declineServiceEmergencyNote(serviceEmergencyNoteId: $serviceEmergencyNoteId) {
      id
      declined_date
    }
  }
`;

export const DELETE_SERVICE: DocumentNode = gql`
  mutation deleteService($serviceID: UUID!) {
    deleteService(serviceID: $serviceID)
  }
`;

export const ADD_SERVICE_EMERGENCY_NOTE: DocumentNode = gql`
  mutation addServiceEmergencyNote($serviceId: UUID!, $data: ServiceEmergencyNoteInput!) {
    addServiceEmergencyNote(serviceId: $serviceId, data: $data) {
      id
    }
  }
`;

export const CREATE_SERVICE_AREA: DocumentNode = gql`
  mutation CreateServiceArea($name: String!, $state: String!, $validFrom: Date!, $validTo: Date!) {
    createServiceArea(name: $name, state: $state, validFrom: $validFrom, validTo: $validTo)
  }
`;

export const CREATE_SERVICE_AREA_OFFICE: DocumentNode = gql`
  mutation CreateServiceAreaOffice(
    $id: UUID!
    $name: String!
    $shorthandName: String!
    $address: AddressInput!
    $comment: String
    $confidentialComment: String
    $legacyID: Int
  ) {
    createServiceAreaOffice(
      id: $id
      name: $name
      shorthandName: $shorthandName
      address: $address
      comment: $comment
      confidentialComment: $confidentialComment
      legacyID: $legacyID
    )
  }
`;

export const CREATE_SERVICE_AREA_MOBILE: DocumentNode = gql`
  mutation CreateServiceAreaMobile(
    $id: UUID!
    $name: String!
    $shorthandName: String!
    $coordinates: String!
    $comment: String!
    $confidentialComment: String!
    $legacyID: Int
  ) {
    createServiceAreaMobile(
      id: $id
      name: $name
      shorthandName: $shorthandName
      coordinates: $coordinates
      comment: $comment
      confidentialComment: $confidentialComment
      legacyID: $legacyID
    )
  }
`;

export const DELETE_SERVICE_EMERGENCY_NOTE: DocumentNode = gql`
  mutation deleteServiceEmergencyNote($serviceEmergencyNoteId: UUID!) {
    deleteServiceEmergencyNote(serviceEmergencyNoteId: $serviceEmergencyNoteId)
  }
`;

export const UPDATE_SUBSTITUTE_BLACKLIST: DocumentNode = gql `
  mutation updateSubstituteServiceNewsletterBlacklist(
    $substituteID: UUID!,
    $states: [String!]!,
    $byUser: Boolean,
  ){
    updateSubstituteServiceNewsletterBlacklist(
     substituteID: $substituteID,
     states: $states,
     byUser: $byUser,
  )}
`;

