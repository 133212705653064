export default {
  META: {
    TITLE: 'KV-Dienste finden'
  },
  HEADING: 'KV-Dienste finden',
  NO_RESULTS: 'Keine Ergebnisse für diese Suchparameter.',
  OPEN_FILTER: '@:SERVICE_LISTING.OPEN_FILTER',
  SORTING: '@:SERVICE_LISTING.SORTING',
  SORT: {
    ASC: '@:SERVICE_LISTING.SORT.ASC',
    DESC: '@:SERVICE_LISTING.SORT.DESC',
  }
};
