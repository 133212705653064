import { Ref, UnwrapRef, ref } from 'vue';
import appConfig from '@/configs/app';

class Debug {
  /**
   * current debug state as vue reference
   * @private
   */
  private state: Ref<boolean> = ref(appConfig.debug);

  /**
   * enables debug mode
   * @return {void}
   */
  public enable(): void {
    this.state.value = true;
  }

  /**
   * disables debug mode
   * @return {void}
   */
  public disable(): void {
    this.state.value = false;
  }

  /**
   * check if debug mode enabled
   * @return {boolean}
   */
  public isEnabled(): boolean {
    return this.state.value;
  }

  /**
   * get the debug state reference
   * @return {Ref}
   */
  public getStateRef(): Ref<UnwrapRef<boolean>> {
    return this.state;
  }

  /**
   * if debug is enabled log content(s) to console
   * @param {*} content
   * @return {void}
   */
  public toConsole(...content: any[]): void {
    if (this.isEnabled()) {
      // eslint-disable-next-line no-console
      console.log(...content);
    }
  }

  /**
   * log exception to console on dev and to appsignal everywhere else
   * @param {*} content
   * @return {void}
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public captureException(error: any, tags: any): void {
    this.toConsole(error);
  }
}

export default new Debug();
