<template>
  <div id="newsticker">
    <v-container class="">
      <a href="https://poolarzt.arztpool24.de" target="_blank">
        <div class="ticker-wrap">
          <div class="title">
              {{ $t('NEWS_TICKER.TITLE') }}
          </div>
          <div class="ticker">
            <div class="ticker__item" v-for="(item, i) of items" :key="i">
              {{ item.TEXT }}
            </div>
          </div>
        </div>
      </a>
    </v-container>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { useDisplay } from 'vuetify';

  import NewsItem from '@/definitions/interfaces/locales/NewsItem.i';

  export default defineComponent({
    name: 'NewsTicker',
    components: {},
    props: {
      items: {
        type: Array as PropType<NewsItem[]>,
        require: true,
      }
    },
    setup() {
      const { smAndDown } = useDisplay();

      return {
        smAndDown,
      };
    }
  });
</script>

<style lang="scss" scoped>
  @import '@/scss/configs/colors';
  @import '@/scss/configs/fonts';

  $duration: 30s;

  #newsticker {

    z-index: 92;
    width: 100%;
    height: 50px;
    padding: 0px 0px 10px 0px;
    background-color: $color-marine;

    @-webkit-keyframes ticker {
      0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
      }

      100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
      }
    }

    @keyframes ticker {
      0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
      }

      100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
      }
    }

    .ticker-wrap {

      width: 100%;
      overflow: hidden;
      background-color: transparent;
      padding-left: 100%;
      box-sizing: content-box;
      cursor: pointer;

      .title {

        display: inline-block;
        color: $color-orange;
        font-weight: bold;
        position: absolute;
        left: 0%;
        width: 15%;
        z-index: 150;
        background-color: $color-marine;
        text-align: right;
        padding-right: 10px;
        text-transform: uppercase;

      }

      .ticker {

        display: inline-block;
        white-space: nowrap;
        padding-right: 100%;
        box-sizing: content-box;

        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
                animation-timing-function: linear;
       -webkit-animation-name: ticker;
               animation-name: ticker;
        -webkit-animation-duration: $duration;
                animation-duration: $duration;

        &__item {

          display: inline-block;
          padding: 0 2rem;
          color: $color-white;
          font-weight: bold;

        }
      }
    }
  }
</style>
