export default {
  CLOSE: 'Schließen',
  HEADER_EDIT: 'Betriebsstättenarzt bearbeiten',
  HEADER_NEW: 'Betriebsstättenarzt anlegen',
  DESCRIPTION: 'Bitte geben Sie für den Arzt/die Ärztin den Namen, die LANR und die Anzahl der KV Sitze an.',
  FORM: {
    NAME: 'Name',
    KV_SEATS: 'KV Sitze',
    LANR: 'LANR',
    SUBMIT: 'Speichern'
  },
};
