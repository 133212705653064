export default {
  apollo: {
    /**
     * apollo request base uri
     * @var {string}
     */
    uri: import.meta.env.ARZTPOOL_APOLLO_URI,
  },

  amplify: {
    /**
     * amplify server region
     * @var {string}
     */
    region: import.meta.env.ARZTPOOL_AMPLIFY_REGION,

    /**
     * amplify user pool ID
     * @var {string}
     */
    userPoolId: import.meta.env.ARZTPOOL_AMPLIFY_USERPOOL_ID,

    /**
     * amplify user pool web-client ID
     * @var {string}
     */
    userPoolWebClientId: import.meta.env.ARZTPOOL_AMPLIFY_USERPOOL_WEBCLIENT_ID,

    /**
     * amplify identity pool ID
     * @var {string}
     */
    identityPoolId: import.meta.env.ARZTPOOL_AMPLIFY_IDENTITY_POOL_ID,
  },

  googleTagManager: {
    /**
     * google tag manager container id
     * @var {string}
     */
    id: import.meta.env.ARZTPOOL_GOOGLE_TAG_MANAGER_ID,
  },

  googleMaps: {
    /**
     * google maps api key
     * @var {string}
     */
    apiKey: import.meta.env.ARZTPOOL_GOOGLE_MAPS_API_KEY,

    /**
     * google maps initialization callback name
     * @var {string}
     */
    callbackName: 'gmapsCallback',
  },

  cloudWatch: {
    /**
     *
     * AWS RUM application id
     * @var {string}
    */
    awsrumApplicationId: import.meta.env.ARZTPOOL_AWS_RUM_APPLICATION_ID,

    /**
     *
     * AWS RUM application version
     * @var {string}
    */
    awsrumApplicationVersion: import.meta.env.ARZTPOOL_AWS_RUM_APPLICATION_VERSION,


    /**
     *
     * AWS RUM application region
     * @var {string}
    */
    awsrumApplicationRegion: import.meta.env.ARZTPOOL_AWS_RUM_APPLICATION_REGION,

    /**
     *
     * AWS RUM identity pool id
     * @var {string}
    */
    awsrumIdentityPoolId: import.meta.env.ARZTPOOL_AWS_RUM_IDENTITY_POOL_ID,


    /**
     *
     * AWS RUM endpoint url
     * @var {string}
    */
    awsrumEndpoint: import.meta.env.ARZTPOOL_AWS_RUM_ENDPOINT,

  }

};
