<template>
  <v-dialog v-model="showModalSetup" width="560" :persistent="true">
    <v-card id="modalSetup">
      <v-card-title class="mb-5">
        <h1 class="mb-0">
          {{ $t('SETUP_MODAL.HEADING') }}
        </h1>
      </v-card-title>
      <v-row>
        <v-col class="pa-0 d-flex" cols="12">
          {{ $t('SETUP_MODAL.DESCRIPTION[0]') }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-0 d-flex" cols="12">
          {{ $t('SETUP_MODAL.DESCRIPTION[1]') }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-0 d-flex" cols="12">
          {{ $t('SETUP_MODAL.DESCRIPTION[2]') }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-0 d-flex justify-center align-center pr-2 mt-14 mb-1" cols="12">
          <div class="loading">
            <div class="loader"></div>
          </div>
        </v-col>
      </v-row>
      <v-divider class="mb-10" />
      <v-row>
        <v-col class="pa-0 d-flex justify-center align-center" cols="12">
          {{ currentSetupStep }}
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue';
  import { useDisplay } from 'vuetify';
  import { RouterSamePageErrorHandler } from '@/plugins/router';
  import { $t } from '@/plugins/i18n';

  import Address from '@/definitions/interfaces/Address.i';
  import BankAccount from '@/definitions/interfaces/BankAccount.i';
  import UserProfile from '@/definitions/interfaces/Profile.i';
  import InstitutionInput from '@/definitions/interfaces/InstitutionInput.i';
  import AddressInput from  '@/definitions/interfaces/AddressInput.i';
  import EstablishmentInput from '@/definitions/interfaces/EstablishmentInput.i';
  import ServiceInput from '@/definitions/interfaces/ServiceInput.i';

  import Stores from '@/stores';
  import Profile from '@/services/Profile';
  import Partner from '@/services/Partner';
  import Substitute from '@/services/Substitute';
  import EventBus from '@/services/EventBus';
  import CaptureException from '@/services/CaptureException';
  import ServiceService from '@/services/Service';

  export default defineComponent({
    name: 'ModalSetup',
    components: {},
    setup() {
      const { smAndDown } = useDisplay();

      return {
        smAndDown,
        showModalSetup: ref(false),
        loading: ref(true),
        error: ref(''),
        currentSetupStep: ref(''),
      };
    },
    async beforeMount(): Promise<void> {
      // register event to open setup modal
      EventBus.on(EventBus.keys.SETUP_OPEN, (): void => {
        if (Stores.userData.signedIn) {
          this.showSetup(true);
        }
      });

      // register event to open setup modal
      EventBus.on(EventBus.keys.TRIGGER_SETUP, (): void => {
        if (Stores.userData.signedIn) {
          this.currentSetupStep = $t('SETUP_MODAL.SETUP_STEPS.SETUP_START');
          this.startSetup();
        }
      });

      // register event to receive setup error messages
      EventBus.on(EventBus.keys.SETUP_ERROR, (setupError): void => {
        this.error = (setupError as string);
        this.loading = false;
      });

       // register event to open setup modal
      EventBus.on(EventBus.keys.UPDATE_SETUP_STEP, (setupStep): void => {
        this.currentSetupStep = $t(`SETUP_MODAL.SETUP_STEPS.${setupStep as string}`);
      });
    },
    methods: {
      /**
       * toggle setup modal state (open/close)
       * @return {void}
       */
      showSetup(state: boolean): void {
        this.showModalSetup = state;
      },
      /**
       * prepare and start account setup
       *
       */
      startSetup(): void {
        if (Stores.userData.signedIn) {
          if (Stores.userData.userType === 'partner') {
            this.setupPartner();
          }
          if (Stores.userData.userType === 'substitute') {
            this.setupSubstitute();
          }
        }
      },
      /**
       * setup a substitute account
       *
       * Because we are not querying all necessary data in the onboarding wizards or for registrations
       * not through the onboarding wizards, we need to set placeholders in the DB to fullfil all constraints:
       *
       * needed string values: ####PLACEHOLDER####
       * needed number values: 0
       * needed dates: current date
       * needed zipcode: 12681 (the arztpool zipcode)
       * needed specializiation: Allgemeinmedizin
       *
       * where we need a state, we set Baden-Württemberg as the default as this is the first option in the drowndown
       * and will not raise any problems with users expecting a alphabetically sorted list in the dropdown
       *
       */
      async setupSubstitute(): Promise<void> {
        try {

          // build a profile
          let _profile: UserProfile = {
            id: '',
            email: null,
            registrationDate: null,
            termsAcceptedOn: null,
            legacy: false,
            title: (Stores.wizard.substitute.title !== null ? Stores.wizard.substitute.title : '####PLACEHOLDER####'),
            firstName: (Stores.wizard.substitute.firstname !== null ? Stores.wizard.substitute.firstname : '####PLACEHOLDER####'),
            lastName: (Stores.wizard.substitute.lastname !== null ? Stores.wizard.substitute.lastname : '####PLACEHOLDER####'),
            birthDate: (Stores.wizard.substitute.birth !== null ? Stores.wizard.substitute.birth : new Date()),
            mobile1: (Stores.wizard.substitute.mobile !== null ? Stores.wizard.substitute.mobile : '0'),
            mobile2: (Stores.wizard.substitute.mobile_additional !== null ? Stores.wizard.substitute.mobile_additional : null),
            fax: null,
            phone: (Stores.wizard.substitute.phone !== null ? Stores.wizard.substitute.phone : '0'),
          };

          // register the profile in the DB
          const _profileCreated = await Profile.registerProfile(_profile);

          // if profile was succesfully created, request Substitute role

          if (_profileCreated) {

            // build an address
            let _address: Address = {
                id: '',
                city: (Stores.wizard.substitute.place !== null ? Stores.wizard.substitute.place : '####PLACEHOLDER####'),
                street: (Stores.wizard.substitute.street !== null ? Stores.wizard.substitute.street : '####PLACEHOLDER####'),
                country: 'Deutschland',
                houseNr: (Stores.wizard.substitute.house !== null ? Stores.wizard.substitute.house : '####PLACEHOLDER####'),
                zipCode: (Stores.wizard.substitute.postcode !== null ? Stores.wizard.substitute.postcode : '12681'),
                state: (Stores.wizard.substitute.state !== null ? Stores.wizard.substitute.state : $t('DATA.STATES.BADEN_WUERTTEMBERG')),
            };

            // build a bank account
            let _bankAccount: BankAccount = {
              id: '',
              accountHolder: (Stores.wizard.substitute.accountOwner !== null ? Stores.wizard.substitute.accountOwner : '####PLACEHOLDER####'),
              bank: (Stores.wizard.substitute.bank !== null ? Stores.wizard.substitute.bank : '####PLACEHOLDER####'),
              iban: (Stores.wizard.substitute.iban !== null ? Stores.wizard.substitute.iban : '####PLACEHOLDER####'),
              bic: (Stores.wizard.substitute.bic !== null ? Stores.wizard.substitute.bic : '####PLACEHOLDER####'),
            };

            let _trainingConfirmed = (Stores.wizard.substitute.confirmation !== null ? Stores.wizard.substitute.confirmation : false);
            let _taxNumber = (Stores.wizard.substitute.taxNumber !== null ? Stores.wizard.substitute.taxNumber : '####PLACEHOLDER####');

            const _substituteResult = await Substitute.requestSubstituteRole(_trainingConfirmed, _taxNumber, _address, _bankAccount);

            // if substitute role was granted and we have a debtor object in the store, add payment method and add license and certification if provided

            if (_substituteResult && Stores.substituteData.substitute.debtor !== null) {

              // add bill payment method to debtor
              const _paymentResult = await Substitute.addBillPaymentMethod();

              // set the Specialization
              const _specializiationResult = await Substitute.addSpecialization((Stores.wizard.substitute.specialField !== null ? Stores.wizard.substitute.specialField : 'Allgemeinmedizin'));

              // set defaults
              let _licenseResult = false;
              let _specializiationCertResult = false;
              let _insuranceResult = false;

              // if the license has been set in the store, add that
              if (Stores.wizard.substitute.licenseToPractice !== null) {
                _licenseResult = await Substitute.addLicense(null, true);
              }
              else {
                // so we can go on, set the default to true
                _licenseResult = true;
              }

              // if a specialization has been set, add that
              if (Stores.wizard.substitute.specialistCertificate !== null) {
                _specializiationCertResult = await Substitute.updateSpecializationCertificate(null, true);
              }
              else {
                // so we can go on, set the default to true
                _specializiationCertResult = true;
              }

              // if an insurance has been set, add that
              if (Stores.wizard.substitute.addLiabilityInsurance !== null || Stores.wizard.substitute.addLiabilityInsurance.length > 0) {
                _insuranceResult = await Substitute.addLiabilityInsurance(null, null, null, null, null, true);
              }
              else {
                // so we can go on, set the default to true
                _insuranceResult = true;
              }

              if (_paymentResult && _licenseResult && _specializiationResult && _specializiationCertResult && _insuranceResult) {
                this.$router.push({path: '/dashboard'}).catch(RouterSamePageErrorHandler);
                this.showSetup(false);
              }
              else {
                this.showSetup(false);
              }
            }
          }
        }
        catch(e) {
          CaptureException.send(e);
          this.currentSetupStep = e as string;
        }
      },
      /**
       * setup a partner account
       *
       * Because we are not querying all necessary data in the onboarding wizards or for registrations
       * not through the onboarding wizards, we need to set placeholders in the DB to fullfil all constraints:
       *
       * needed string values: ####PLACEHOLDER####
       * needed number values: 0
       * needed dates (like the birthdate for the profile): current date
       * needed zipcode: 12681 (the arztpool zipcode)
       *
       * where we need a state, we set Baden-Württemberg as the default as this is the first option in the drowndown
       * and will not raise any problems with users expecting a alphabetically sorted list in the dropdown
       *
       */
      async setupPartner(): Promise<void> {
        try {

          // build a profile
          let _profile: UserProfile = {
            id: '',
            email: null,
            registrationDate: null,
            termsAcceptedOn: null,
            legacy: false,
            title: (Stores.wizard.partner.title !== null ? Stores.wizard.partner.title : '####PLACEHOLDER####'),
            firstName: (Stores.wizard.partner.firstname !== null ? Stores.wizard.partner.firstname : '####PLACEHOLDER####'),
            lastName: (Stores.wizard.partner.lastname !== null ? Stores.wizard.partner.lastname : '####PLACEHOLDER####'),
            birthDate: new Date(),
            mobile1: '0',
            mobile2: null,
            fax: null,
            phone: '0',
          };

          // register the profile in the DB
          const _profileCreated = await Profile.registerProfile(_profile);

          // if profile was succesfully created, request Partner role

          if (_profileCreated) {

            // switch institution type
            let _institutionType = 'Einzelpraxis';
            if (Stores.wizard.partner.institutionType !== null && Stores.wizard.partner.institutionType === 'mvz') {
              _institutionType = 'MVZ';
            }

            // build an AddressInput
            let _address: AddressInput = {
              city: '####PLACEHOLDER####',
              street: '####PLACEHOLDER####',
              country: 'Deutschland',
              houseNr: '0',
              zipCode: '12681',
              state: $t('DATA.STATES.BADEN_WUERTTEMBERG'),
            };

            // build an InstitutionInput
            let _institution: InstitutionInput = {
              bsnr: '0',
              name: (Stores.wizard.partner.institutionName !== null ? Stores.wizard.partner.institutionName : '####PLACEHOLDER####'),
              type: _institutionType,
              address: _address,
              billingAddress: _address,
            };

            const _partnerResult = await Partner.requestPartnerRole(_institution);

            if (_partnerResult) {

              // add tariff (legacy but needed for db consistency) and the bill payment method for the tariff
              await Partner.addBillPaymentMethod();

              // create EstablishmentInput
              let _establishment: EstablishmentInput = {
                institutionID: Stores.partnerData.institutions[0].id,
                name: (Stores.wizard.partner.institutionName !== null ? Stores.wizard.partner.institutionName : '####PLACEHOLDER####'),
                bsnr: '0',
                billingAddress: _address,
                serviceAreaID: (Stores.wizard.partner.isCustomServiceArea ? null : (Stores.wizard.partner.serviceAreaId === null ? null : Stores.wizard.partner.serviceAreaId)),
              };

              const _establishmentID = await Partner.addEstablishment(_establishment);

              if (_establishmentID !== '') {
                Stores.quickStart.partner.establishmentID = _establishmentID;

                // build doctor inputs
                let _title = (Stores.wizard.partner.doctorTitle !== null ? Stores.wizard.partner.doctorTitle : '');
                let _name = (Stores.wizard.partner.doctorName !== null ? Stores.wizard.partner.doctorName : '####PLACEHOLDER####');
                let _lanr = (Stores.wizard.partner.doctorLANR !== null ? Stores.wizard.partner.doctorLANR : '####PLACEHOLDER####');

                // add Establishment Doctor
                await Partner.addEstablishmentDoctor((_title + _name), _lanr, 1, _establishmentID);

                // add temporary service area
                if(Stores.wizard.partner.isCustomServiceArea && Stores.wizard.isQuickRegistration) {
                  let _service: ServiceInput = ServiceService.initServiceInput();
                  _service.establishmentID = _establishmentID;
                  await Partner.addServiceSubmission(_service, Stores.quickStart.partner.serviceType, Stores.wizard.partner.tempServiceData);
                }
              }

              if (Stores.wizard.isQuickRegistration) {
                this.$router.push({path: '/dashboard'}).catch(RouterSamePageErrorHandler);
                this.showSetup(false);
              }
              else {
                this.$router.push({name: 'SingleEntry'}).catch(RouterSamePageErrorHandler);
                this.showSetup(false);
              }
            }

          }
        }
        catch(e) {
          CaptureException.send(e);
          this.currentSetupStep = e as string;
        }

      }
    }
  });
</script>

<style lang="scss" scoped>
  @import '@/scss/configs/colors';
  @import '@/scss/configs/fonts';

  #modalSetup {
    padding: 40px;

    .v-card-title {
      padding: 0;
    }

    .loading {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: $padding-5 0;
    }


  }
</style>
