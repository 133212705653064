import { Emitter, EventHandlerMap, EventType, Handler, WildcardHandler } from 'mitt';

import mitt from 'mitt';
import events from '@/data/events';

class EventBus {
  /**
   * map of pre-defined event keys that can be used anywhere
   * use like EventBus.keys.REFRESH_USER
   * @var {object}
   */
  public keys = events;

  /**
   * event emitter instance
   * @var {Mitt}
   * @private
   */
  private emitter: Emitter<Record<EventType, unknown>> = mitt();

  /**
   * add new event-listener to a specific event key
   * @param {string} key
   * @param {function} handler
   * @return {function} - unset function for removing handler, can be used instead of 'off'
   */
  public on(key: string, handler: Handler): () => void {
    this.emitter.on(key, handler);

    return (): void => {
      this.emitter.off(key, handler);
    };
  }

  /**
   * add new event-listener to all events
   * @param {function} handler
   * @return {function} - unset function for removing handler, can be used instead of 'off'
   */
  public onAll(handler: WildcardHandler): () => void {
    this.emitter.on('*', handler);

    return (): void => {
      this.emitter.off('*', handler);
    };
  }

  /**
   * removes an event-listener from a specific key
   * can also use '*' to remove a wildcard handler
   * @param {string} key
   * @param {function} handler
   * @return {void}
   */
  public off(key: '*'|string, handler?: Handler): void {
    this.emitter.off(key, handler);
  }

  /**
   * add new event-listener to a specific event key that will only be handled once
   * can also use '*' as wildcard for all events
   * @param {string} key
   * @param {function} handler
   * @return {function} - unset function for removing handler, can be used instead of 'off'
   */
  public once(key: '*'|string, handler: Handler | WildcardHandler): () => void {
    const listener = (type: any, data?: any): void => {
      this.off(key, listener);

      if (key === '*') {
        handler(type, data);
      }
      else {
        // @ts-ignore - typescript doesn't realise that the handler depends on the key
        handler(type);
      }
    };

    this.on(key, listener);

    return (): void => {
      this.emitter.off(key, listener);
    };
  }

  /**
   * a map of event names to registered handler functions
   * @return {Map}
   */
  public all(): EventHandlerMap<Record<EventType, unknown>> {
    return this.emitter.all;
  }

  /**
   * emit event to all registered listeners on that key
   * @param {string} key
   * @param {*} data
   * @return {void}
   */
  public emit(key: string, data?: any): void {
    this.emitter.emit(key, data);
  }

  /**
   * remove ALL event listeners
   * @return {void}
   */
  public clear(): void {
    this.all().clear();
  }
}

export default new EventBus();
