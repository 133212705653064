export default {
  CLOSE: 'Schließen',
  CONFIRMATION: 'Sie haben diesem Vertrag am {0} zugestimmt.',
  DISCLAIMER:
    'Arztpool stellt seinen Kunden und Vertretern als unverbindliche Serviceleistung einen Muster-Dienstleistungsvertrag zur freien Verwendung zur Verfügung. Kunde und Vertreter sind nicht verpflichtet, diesen Muster-Vertrag zu nutzen. Arztpool haftet weder für den Inhalt dieses Muster-Dienstleistungsvertrages noch für etwaige Ansprüchen des Kunden und des Vertreters aus diesem Vertrag, insbesondere nicht für etwaige sozialrechtliche und steuerrechtliche Ansprüche. Der Muster-Dienstleistungsvertrag erhebt keinen Anspruch auf Vollständigkeit und Richtigkeit. Vertreter und Kunde erklären, den Muster-Dienstleistungsvertrag erst nach individueller Prüfung und ggf Änderung auf eigene Verantwortung zu verwenden.',
  NO_CONFIRMATION_PARTNER:
    'Bitte bestätigen Sie, dass Sie den genannten Dienst zu den Bedinungen des gezeigten Vertrags von ihrem Vertragspartner vertreten lassen wollen. ',
  NO_CONFIRMATION_SUBSTITUTE:
    'Bitte bestätigen Sie, dass Sie den genannten Dienst zu den Bedinungen des gezeigten Vertrags für ihren Vertragspartner vertreten wollen. ',
  BTN: {
    DOWNLOAD: 'Vertrag runterladen',
    CONFIRM: 'Vertrag bestätigen',
  },
};
