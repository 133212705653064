<template>
  <div class="nav-dropdown">
    <v-list density="compact" :nav="true" :model="group">
      <v-list-item v-if="signedIn && !isOperator" :class="userType">
        <v-list-item-title class="menu-nav-item">
          <router-link to="/dashboard">{{ $t('NAVIGATION.MY_DOCTOR_POOL.LINKS.DASHBOARD') }}</router-link>
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="signedIn && !isOperator" :class="userType">
        <v-list-item-title class="menu-nav-item">
          <i class="fas fa-stethoscope"/>
          <router-link v-if="userType === 'partner'" to="/services/overview">{{ $t('NAVIGATION.MY_DOCTOR_POOL.LINKS.SERVICES') }}</router-link>
          <router-link v-else-if="userType === 'substitute'" to="/services/marketplace">{{ $t('NAVIGATION.MY_DOCTOR_POOL.LINKS.SERVICES') }}</router-link>
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="signedIn && !isOperator" :class="userType">
        <v-list-item-title class="menu-nav-item">
          <v-icon icon="far fa-file-alt" />
          <router-link to="/documents">{{ $t('NAVIGATION.MY_DOCTOR_POOL.LINKS.DOCUMENTS') }}</router-link>
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="signedIn && !isOperator" :class="userType">
        <v-list-item-title class="menu-nav-item">
            <i class="far fa-envelope"/>
            <router-link to="/messages">
              {{ $t('NAVIGATION.MY_DOCTOR_POOL.LINKS.MESSAGES') }}
              <v-chip color="success">{{ unconfirmedMessages.length }}</v-chip>
            </router-link>
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="signedIn && isOperator" :class="userType">
        <v-list-item-title class="menu-nav-item">
          <i class="fas fa-cogs"/>
          <router-link v-if="isOperator" to="/operators">{{ $t('NAVIGATION.MY_DOCTOR_POOL.LINKS.OPERATORS') }}</router-link>
        </v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-title class="menu-nav-item">
          <v-icon class="pa-1 mr-1 mt-n2 color-marine" icon="fas fa-clinic-medical"></v-icon>
          <router-link to="/kv-dienst-vertreten-lassen">{{ $t('NAVIGATION.MY_DOCTOR_POOL.LINKS.PROVIDE_SERVICE') }}</router-link>
        </v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-title class="menu-nav-item">
          <v-icon class="pa-1 mr-1 mt-n2 color-marine" icon="fas fa-clinic-medical"></v-icon>
          <router-link to="/kv-dienst-uebernehmen">{{ $t('NAVIGATION.MY_DOCTOR_POOL.LINKS.REPRESENT_SERVICE') }}</router-link>
        </v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-title class="menu-nav-item">
          <v-icon class="pa-1 mr-1 mt-n2 color-marine" icon="fas fa-clinic-medical"></v-icon>
          <router-link to="/mvz">{{ $t('NAVIGATION.MY_DOCTOR_POOL.LINKS.MVZ') }}</router-link>
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="false">
        <v-list-item-title class="menu-nav-item">
          <v-icon class="pa-1 mr-1 mt-n2 color-marine" icon="fas fa-medkit"></v-icon>
          <router-link to="/help">{{ $t('NAVIGATION.MY_DOCTOR_POOL.LINKS.HELP') }}</router-link>
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-if="smAndDown">
        <v-list-item-title class="menu-nav-item">
          <v-btn :block="true" class="btn-cta" @click.stop="handleInteraction">
            <span v-if="!signedIn">{{ $t('NAVIGATION.MY_DOCTOR_POOL.LOGIN') }}</span>
            <span v-else>{{ $t('NAVIGATION.MY_DOCTOR_POOL.LOGOUT') }}</span>
          </v-btn>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { useDisplay } from 'vuetify';
  import { Hub } from 'aws-amplify';
  import { RouterSamePageErrorHandler } from '@/plugins/router';

  import EventBus from '@/services/EventBus';
  import Stores from '@/stores';

  export default defineComponent({
    name: 'PageNavigationPublicMenu',
    components: {},
    setup() {
      const { signedIn, userType } = Stores.userDataStoreToRefs();
      const { isOperator } = Stores.profileDataStoreToRefs();
      const { unconfirmedMessages } = Stores.messageStoreToRefs();
      const { smAndDown } = useDisplay();

      return {
        signedIn,
        userType,
        isOperator,
        unconfirmedMessages,
        smAndDown,
        userDataStore: Stores.userData,
        group: null,
        showOffCanvas: false,
      };
    },
    watch: {
      group() {
        this.showOffCanvas = false;
      }
    },
    methods: {
      /**
       * redirect to home page and open login form after logout
       * @return {void}
       */
      logoutRedirect(): void {
        this.$router.push({path: '/', query: {login: 'true'}}).catch(RouterSamePageErrorHandler);
      },

      /**
       * handler for login/logout action button
       * @return {void}
       */
      handleInteraction(): void {
        if (!this.signedIn) {
          EventBus.emit(EventBus.keys.LOGIN_OPEN, true);
        }
        else {
          this.userDataStore.logout().then(async () => {
            if (this.signedIn) {
              Hub.listen('auth', data => {
                const {payload} = data;

                if (payload.event === 'signOut') {
                  this.logoutRedirect();
                }
              });
            }
            else {
              this.logoutRedirect();
            }
          });
        }
      }
    },
  });
</script>

<style lang="scss" scoped>
  @import '@/scss/configs/colors';

  .nav-dropdown {
    width: 364px;
    padding: 20px;
    position: absolute;
    top: 76px;
    right: 0;
    font-size: 16px;
    font-weight: 500;
    color: $color-marine-dark;
    background-color: $color-white;
    border: 1px solid $color-blue-grey-30;

    @media (min-width: 960px) {
      top: 110px;
    }

    .v-list-item:hover {
      background-color: $color-blue-grey-10;
    }

    .v-list-item {
      .v-icon {
        font-size: 24px;
      }

      a {
        font-size: 20px;
        font-weight: bolder;
        color: $color-marine;
        text-decoration: none;
        margin-left: 4px;
        border-bottom: 2px solid transparent;

        &.router-link-active {
          color: $color-orange;
          width: fit-content;
        }
      }
    }
  }
</style>
