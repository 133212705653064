import { defineStore, PiniaPluginContext } from 'pinia';
import { restoreDateProperties } from '@/plugins/pinia';

import Debug from '@/utils/Debug';
import Profile from '@/definitions/interfaces/Profile.i';

export interface ProfileDataState {
  profile: Profile|null;
  roles: string[];
  loadingProfile: boolean;
}

const useProfileDataStore = defineStore('ProfileData', {
  state: (): ProfileDataState => ({
    profile: {
      id: '',
      email: null,
      title: null,
      firstName: null,
      lastName: null,
      birthDate: null,
      registrationDate: null,
      termsAcceptedOn: null,
      mobile1: null,
      mobile2: null,
      fax: null,
      phone: null,
      legacy: false,
    },
    roles: [],
    loadingProfile: false,
  }),
  actions: {
    removeProfile(): void {
      this.profile = {
        id: '',
        email: null,
        title: null,
        firstName: null,
        lastName: null,
        birthDate: null,
        registrationDate: null,
        termsAcceptedOn: null,
        mobile1: null,
        mobile2: null,
        fax: null,
        phone: null,
        legacy: false,
      };
      this.roles = [];
    },
    addRole(role: string): void {
      this.roles.push(role);
    },
  },
  getters: {
    isOperator: (state: ProfileDataState) => state.roles.length > 0 && state.roles.includes('operator'),
    isSubstitute: (state: ProfileDataState) => state.roles.length > 0 && state.roles.includes('substitute'),
    isPartner: (state: ProfileDataState) => state.roles.length > 0 && state.roles.includes('partner'),
     /**
     * get all messages from that are not confirmed
     * @return {array}
     */
     userName: (state: ProfileDataState) => {
      let name: string = '';
      if (state.profile !== null) {
        name = `${state.profile.title !== null ? state.profile.title : ''} ${state.profile.firstName !== null ? state.profile.firstName : ''} ${state.profile.lastName !== null ? state.profile.lastName : ''}`;
      }
      return name;
    },
  },
  persist: {
    key: 'arztpool24.de-profile',
    debug: Debug.isEnabled(),
    afterRestore: (ctx: PiniaPluginContext): void => {
      restoreDateProperties(ctx.store.$state, {
        profile: ['birthDate', 'registrationDate', 'termsAcceptedOn'],
      });
    },
  },
});

export default useProfileDataStore;
