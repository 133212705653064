export default {
  LABEL: [
    'Vermittlungsgebühr Standard-Auftrag ',
    'Vermittlungsgebühr Eil-Auftrag ',
    'Vermittlungsgebühr Express-Auftrag ',
    'Vermittlungsgebühr Rundum-Sorglos-Paket'
  ],
  ACTION: {
    DELETE: 'Löschen',
    EDIT: 'Bearbeiten'
  },
  NO_LOCATION: 'Ihre angelegte Dienststelle wird von unserem Kundendienst geprüft.'
};
