import { createGtm, VueGtmPlugin } from '@gtm-support/vue-gtm';

import router from '@/plugins/router';
import apiConfig from '@/configs/api';

const gtm: VueGtmPlugin = createGtm({
  id: apiConfig.googleTagManager.id, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects
  defer: true, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: true, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether display console logs debugs (optional)
  loadScript: false, // Whether to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  trackOnNextTick: false, // Whether call trackView in Vue.nextTick
});

export default gtm;
