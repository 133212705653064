export default {
  META: {
    TITLE: 'Allgemeine Geschäftsbedingungen',
  },
  HEADING: 'Allgemeine Geschäftsbedingungen der arztpool 365 GmbH',
  SCOPE: {
    HEADING: '§ 1 Definition und Geltungsbereich',
    TEXT: [
      'Geschäftsgegenstand der arztpool 365 GmbH (im Weiteren arztpool genannt) ist die Vermittlung und Vermittlungsoption von kassenärztlichen Bereitschaftsdiensten (im Weiteren KV-Dienste genannt) zwischen niedergelassenen Ärzten, medizinischen Versorgungszentren oder Berufsausübungsgemeinschaften (im Weiteren Kunden genannt), die diese abgeben wollen, und Ärzten (im Weiteren Vertreter genannt), die diese KV-Dienste übernehmen wollen.',
      'Die Vertreter sind freiberuflich tätig (es sei denn, es ist zwischen Kunde und Vertreter individuell anderes schriftlich vereinbart) und nicht weisungsgebunden, sowie frei in der Wahl der KV-Dienste. Die vertraglich geschuldete Vertretertätigkeit ist eine höchstpersönliche Pflicht des Vertreters und kann nicht an einen anderen Berufsträger übertragen werden. Die Vertreter rechnen gegenüber den Kunden selbständig ab. Die Behandlung von Privatpatienten und die Ausstellung von Totenscheinen im Rahmen der KV-Dienste-Vertretung gilt als eigenständige Leistung des Vertreters, welche er unmitelbar mit den Patienten/erstatenden Stellen auf eigene Verantwortung abrechnet. Eine Anstellung der Vertreter bei der arztpool erfolgt nicht. Die arztpool übernimmt die reine Vermittlungsoption zur Vertretung von KV- Diensten und die damit verbundenen Dienstleistungen, aber keine Arbeitnehmerüberlassung. Die Vertreter sind in keiner Weise in den Betriebsablauf der arztpool eingebunden.',
      'Die Allgemeinen Geschäftsbedingungen der arztpool (im Weiteren AGB genannt) gelten für die Kunden und Vertreter, sofern nicht schriftlich andere Vereinbarungen mit der arztpool getroffen wurden.',
      'Jedem Kunden und Vertreter werden die AGB vor Vertragsabschluss bekannt gegeben. Sie können auf der Homepage der arztpool zu jeder Zeit in der jeweils gültigen Fassung eingesehen werden.',
    ],
  },
  MEDIATION: {
    HEADING: '§ 2 Vermittlungsablauf',
    TEXT: [
      'Der Kunde beauftragt die arztpool mit der Vermittlungsoption von Vertretern für den KV-Dienst. Die vertraglichen Grundlagen und Dienstleistungen werden dem Kunden und dem Vertreter in diesen AGB detailliert aufgelistet.',
      'Der Kunde stellt nach Registrierung und Anmeldung auf dem Vermittlungsportal von arztpool seine zu vergebenden KV-Dienste ein.',
      'Der Vertreter kann nach Registrierung und Anmeldung auf dem Vermittlungsportal von arztpool die seitens der Kunden eingestellten KV-Dienste sehen und anbieten, diese(n) KV-Dienst(e) für den Kunden zu vertreten. Wahlweise kann sich der Vertreter für den jeweiligen KV-Dienst bewerben oder - soweit angeboten – die Sofort-Zusage Option wählen.',
      'Es besteht weder ein Anspruch des Kunden noch des Vertreters gegenüber der arztpool auf eine erfolgreiche Abgabe von KV-Diensten an Vertreter oder auf die erfolgreiche Zuweisung von KV- Diensten.',
      'Soweit aufgrund der Vermittlung der arztpool ein Vertreter für einen Kunden einen KV- Dienst übernimmt, wird ein separater Vertrag zwischen dem Kunden und dem Vertreter (Vertretungsvertrag/Dienstvertretungsvertrag) geschlossen. Die arztpool ist nicht Vertragspartei dieses Vertretungsvertrages. Jegliche Haftung diesbezüglich gegenüber der arztpool, insbesondere bezüglich der unverbindlichen Mustervorlage des Vertretungsvertrages auf der Homepage von arztpool, ist ausgeschlossen.',
    ],
  },
  LIABILITY: {
    HEADING: '§ 3 Haftpflichtversicherung, Approbation, Facharztqualifikation',
    TEXT: [
      'Für den Vertreter ist das Bestehen einer Berufshaftpflichtversicherung verpflichtend und im Rahmen des Registrierungsprozesses der arztpool schriftlich nachzuweisen. Die Bewerbung auf einen KV- Dienst ist erst nach positiver Prüfung der Berufshaftpflichtversicherung durch die arztpool möglich. Im Januar eines jeden Folgejahres ist die weitere Beständigkeit der Berufshaftpflichtversicherung durch ein Schreiben der Versicherung unaufgefordert nachzuweisen. Die vereinbarten Deckungssummen dürfen folgende Beträge nicht unterschreiten: 5 Millionen EUR pauschal für Personen- und Sachschäden und 500.000 EUR für Vermögensschäden.',
      'Der Vertreter hat bei Registrierung/Anmeldung bei arztpool seine Approbation schriftlich nachzuweisen. Im Falle des Entzugs oder des Ruhens der Approbation ist arztpool unverzüglich schriftlich zu informieren.',
      'Wenn für die Vertretung das Vorhandensein einer (spezifischen) Facharztqualifikation erforderlich ist, so ist diese der arztpool unaufgefordert vor Bewerbung oder Zuweisung eines KV-Dienstes schriftlich nachzuweisen. Sollte für die Vertretung eine (spezifische) Facharztqualifikation erforderlich sein, dürfen sich nur solche Vertreter auf die jeweiligen KV-Dienste bewerben bzw. die Sofort-Zusage Option nutzen, die die entsprechenden Voraussetzungen erfüllen.',
    ],
  },
  COMPENSATION: {
    HEADING: '§ 4 Vergütung',
    TEXT: [
      'Der Kunde zahlt an die arztpool bei erfolgreicher Vermittlung seines/r KV-Dienste(s), die der aktuellen Preisliste der arztpool zu entnehmende Vermittlungsgebühr. Der Anspruch auf die Vermittlungsgebühr ist mit Vermittlung des/der Dienste(s) entstanden und fällig, auch wenn der Kunde den/die KV-Dienst(e) wieder stornieren oder der/die KV-Dienst(e) aus anderen Gründen nicht oder nicht mit einem Vertreter über die arztpool durchgeführt werden sollte.',
      'Der Vertreter zahlt der arztpool bei erfolgreicher KV-Dienst-Vermittlung eine Vermittlungsgebühr entsprechend der aktuellen Preisliste an die arztpool.',
      'Die Vergütungsansprüche des Vertreters bestehen ausschließlich gegen den Kunden aufgrund des zwischen ihnen bestehenden Vertretungsvertrages. Der Vertreter hat keinen Anspruch auf Zahlung einer Vertretungsvergütung gegenüber der arztpool. Ihm stehen nur die Ansprüche aus dem Vertretungsvertrag gegenüber dem Kunden zu.',
    ],
  },
  COMPETITION: {
    HEADING: '§ 5 Konkurrenzschutzklausel',
    TEXT: [
      'Die Vermittlung eines Vertreters für den KV-Dienst erfolgt ausschließlich durch die arztpool. Der Kunde erklärt ausdrücklich, dass er keinem Dritten einen ähnlichen oder gleichlautenden Auftrag zur Vermittlung von KV-Diensten erteilt hat.',
      'Der Kunde darf den Vertreter nicht weitervermiteln und die Daten des Vertreters im KV- Dienst auch nicht Dritten oder mit ihm verbundenen Unternehmen zu Vermittlungszwecken zur Verfügung stellen. Der Vertreter darf kein Vermittlungsangebot des Kunden oder eines Dritten, der die Daten vom Kunden erlangt hat, annehmen.',
    ],
  },
  PENALTIES: {
    HEADING: '§ 6 Stornogebühren, Strafzahlungen',
    TEXT: [
      'Kunde',
      'Der Kunde hat die Möglichkeit, seinen im Vermittlungsportal eingestellten, noch nicht einem Vertreter zugewiesenen Dienst, kostenfrei zu stornieren. Wurde der KV-Dienst durch die Sofort-Zusage-Option oder durch die Bestätigung der Übernahme durch den Kunden schon zugewiesen, bleibt der Kunde zur Zahlung der entstandenen Vermittlungsgebühr nach § 4 Absatz 1 an die arztpool und der Entschädigungszahlung an den Vertreter verpflichtet.',
      'Sobald ein Vertreter die Sofort-Zusage-Option bestätigt hat, oder der Kunde einem bewerbenden Vertreter die Übernahme des KV-Dienstes bestätigt hat und der Kunde anschließend den KV-Dienst storniert oder aus anderen Gründen nicht anbieten kann, hat der Vertreter einen Anspruch auf Entschädigung gegenüber dem Kunden, um den Ausfall der Vertretungsvergütung des Vertretungsarztes zu kompensieren. Wird ein Dienst bis zu 14 Tagen vor dem Termin storniert, so bekommt der Vertreter 25% der vereinbarten Vergütung als Kompensation für den Ausfall der Vertretungsvergütung vom Kunden. Wird ein Dienst weniger als 2 Wochen aber mehr als 7 Tage vor dem Termin storniert, so bekommt der Vertreter 50% der vereinbarten Vergütung als Kompensation für den Ausfall der Vertretungsvergütung vom Kunden. Wird ein Dienst in den letzten 7 Tagen vor dem Termin storniert, so bekommt der Vertreter 75% der vereinbarten Vergütung als Kompensation für den Ausfall der Vertretungsvergütung vom Kunden.',
      'Der Anspruch der arztpool auf die Vergütung nach § 4 Absatz 1 bleibt von einer Nichtdurchführung des KV-Dienstes unberührt.',
      'Vertreter',
      'Sollte der Vertreter nicht in der Lage sein, den KV-Dienst wahrzunehmen (z.B. Krankheit) oder selbigen stornieren, so hat er die arztpool unverzüglich über diesen Umstand schriftlich zu unterrichten. Die arztpool wird sich um die Vermittlung eines neuen Vertreters bemühen. Sollte dies nicht gelingen, hat der Vertreter gegenüber dem Kunden zur Kompensation des Ausfalls Ersatz zu leisten: 250,00 € 0 bis 3 Tage vor dem KV-Dienst und 175,00 € 4 bis 10 Tage vor dem KV-Dienst zuzüglich etwaiger Strafzahlungen, die die KV dem Kunden wegen Nichtantrits des KV-Dienstes in Rechnung stellt. Der Vertreter ist nicht berechtigt, alternativ einseitig einen Untervertreter zu bestimmen oder zu schicken.',
      'Der Anspruch der arztpool auf die Vergütung nach § 4 Absatz 1 bleibt von einer Nichtdurchführung des KV-Dienstes unberührt.',
    ],
  },
  DISCLAIMER: {
    HEADING: '§ 7 Haftungsausschluss',
    TEXT: [
      'Die arztpool übernimmt alle notwendigen Maßnahmen zur Überprüfung des Vorliegens der Berufserlaubnis und des Versicherungsschutzes des Vertreters.',
      'Die arztpool haftet nur für die fehlerfreie Auswahl des jeweiligen Vertreters in Bezug auf die vereinbarte Tätigkeit und für die sie treffenden Leistungspflichten zur Organisation und Abwicklung des KV-Dienstes. Die Haftung beschränkt sich auf Schäden, die durch vorsätzliche oder grob fahrlässige Verletzung oder im Falle der Verletzung von Leben, Körper und Gesundheit aufgrund einer fahrlässigen Verletzung der Auswahlpflicht entstehen.',
      'Der Vertreter ist weder Erfüllungs- noch Verrichtungsgehilfe der arztpool. Die arztpool haftet nicht für Schadenersatzforderungen aus der Tätigkeit des Vertreters im KV-Dienst gegenüber dem Kunden oder dritten Personen, die durch die Tätigkeit des Vertreters einen Schaden erlitten haben.',
      'Die Vertragsleistung seitens der arztpool besteht ausdrücklich nicht in der Erbringung der durch den Vertreter auszuführenden Arbeitstätigkeit selbst. Die arztpool ist weder Erfüllungs- noch Verrichtungsgehilfe des Kunden und übernimmt keinerlei Haftung gegenüber dem Vertreter aus Schäden, die dieser durch seine Tätigkeit im KV-Dienst erleidet.',
      'arztpool stellt seinen Kunden und Vertretern als unverbindliche Serviceleistung einen Muster-Dienstleistungsvertrag zur freien Verwendung zur Verfügung. Kunde und Vertreter sind nicht verpflichtet, diesen Muster-Vertrag zu nutzen. arztpool haftet weder für den Inhalt dieses Muster-Dienstleistungsvertrages noch für etwaige Ansprüchen des Kunden und des Vertreters aus diesem Vertrag, insbesondere nicht für etwaige sozialrechtliche und steuerrechtliche Ansprüche. Der Muster-Dienstleistungsvertrag erhebt keinen Anspruch auf Vollständigkeit und Richtigkeit. Vertreter und Kunde erklären, den Muster-Dienstleistungsvertrag erst nach individueller Prüfung und ggf Änderung auf eigene Verantwortung zu verwenden.',
    ],
  },
  CLAIMS: {
    HEADING: '§ 8 Verwirkung von Ansprüchen',
    TEXT: [
      'Ansprüche aus diesem Vertrag müssen von beiden Vertragsparteien spätestens 3 Monate nach Beendigung des schriftlich gegenüber dem Vertragspartner geltend gemacht werden. Alle nicht in dieser Form erhobenen Ansprüche gelten nach dem Ablauf dieser Frist als verwirkt.',
    ],
  },
  PRIVACY_POLICY: {
    HEADING: '§ 9 Datenschutzbestimmungen',
    TEXT: [
      'Kunde und Vertreter erklären sich mit der elektronischen Speicherung ihrer Daten einverstanden. Sie willigen insbesondere in die Weitergabe ihrer Daten durch die arztpool an einen interessierten Vertreter oder Kunden ein.',
      'Eine Weitergabe der Daten an andere Personen oder Institutionen, die nicht am Vermittlungsprozess beteiligt sind, erfolgt grundsätzlich nicht, es sei denn, die arztpool ist gesetzlich oder behördlich zur Datenerhebung und Datenweitergabe verpflichtet. Alle Daten werden auf Verlangen/Widerspruch vollständig gelöscht, soweit dies gesetzlich zulässig ist.',
    ],
  },
  SEVERABILITY: {
    HEADING: '§ 10 Schlussbestimmungen, Salvatorische Klausel',
    TEXT: [
      'Gegen Forderungen seitens der arztpool kann nur mit unstrittigen oder rechtskräftig festgestellten Ansprüchen aufgerechnet werden. Ein Zurückbehaltungsrecht kann nur aufgrund unmitelbar aus dem jeweiligen Vermittlungsvertrag resultierenden Ansprüchen geltend gemacht werden.',
      'Die arztpool hat jederzeit das Recht, ohne Angabe von Gründen das Vertragsverhältnis mit dem Kunden oder dem Vertreter zu beenden und Kunden oder Vertreter von der Nutzung der Dienste der arztpool auszuschließen.',
      'Der Gerichtstand für etwaige Streitigkeiten zwischen arztpool und Kunde oder Vertreter ist der örtliche Sitz von arztpool, dies ist Berlin.',
      'Änderungen oder Ergänzungen dieser AGB gelten ab Bekanntgabe an den jeweiligen Vertragspartner. Die Änderungen gelten als genehmigt, wenn nicht innerhalb von 30 Tagen nach Zugang der Änderungsmiteilung der Änderung widersprochen wurde, soweit bei Miteilung der Änderung ausdrücklich auf diese Folge hingewiesen wurde.',
      'Sollten einzelne Bestimmungen der AGB unwirksam sein oder werden, soll die Wirksamkeit der übrigen Klauseln der AGB im Übrigen davon unberührt bleiben. An die Stelle einer unwirksamen Regelung oder der durch die Unwirksamkeit entstehenden Lücke treten die nächstzulässigen Bestimmungen, die die Vertragsparteien bei Kenntnis der Unwirksamkeit vereinbart hätten.',
      'Es gilt das Recht der Bundesrepublik Deutschland.',
      'Stand 06.10.2023',
    ],
  },
};
