<template>
  <footer v-show="!hideFooter" v-bind:class="signedIn ? 'signed-footer' : ''">
    <v-container class="py-0 flex-nowrap">
      <div v-if="!signedIn" :class="smAndDown ? 'sm' : ''" class="footer-links">
        <router-link class="mx-6" to="/kv-dienst-vertreten-lassen">
          {{ $t('FOOTER.LINKS.PROVIDE_SERVICE') }}
        </router-link>
        <router-link class="mx-6" to="/kv-dienst-uebernehmen">
          {{ $t('FOOTER.LINKS.REPRESENT_SERVICE') }}
        </router-link>
        <router-link class="mx-6" to="/mvz">
          {{ $t('FOOTER.LINKS.MVZ') }}
        </router-link>
        <router-link class="mx-6" to="/about-us">
          {{ $t('FOOTER.LINKS.ABOUT') }}
        </router-link>
      </div>
      <v-row v-if="!signedIn" class="pt-7 pb-6 social-media" v-bind:class="signedIn ? 'signed-footer-bottom' : 'footer-bottom'">
        <v-col cols="2" offset="5" class="text-center">
          <a href="https://www.linkedin.com/company/arztpool24/about/" target="_blank" class="mr-md-3 mr-0">
            <v-icon icon="fab fa-linkedin"></v-icon>
          </a>
          <a href="https://www.youtube.com/@arztpool24" target="_blank" class="mr-sm-3 mr-0">
            <v-icon icon="fab fa-youtube"></v-icon>
          </a>
          <a href="https://www.instagram.com/arztpool24/" target="_blank" class="mr-md-3 mr-0">
            <v-icon icon="fab fa-instagram"></v-icon>
          </a>
          <a href="https://www.facebook.com/people/Arztpool24/61551379002584/" target="_blank">
            <v-icon icon="fab fa-facebook"></v-icon>
          </a>
        </v-col>
      </v-row>
      <v-row class="pt-7" v-bind:class="signedIn ? 'signed-footer-bottom' : 'footer-bottom'">
        <v-col :class="smAndDown ? 'text-center' : 'text-left'" class="py-0 ps-0" cols="12" md="6">
          &copy; {{ year }} {{ $t('FOOTER.COPYRIGHT') }} (v{{ version }})
        </v-col>
        <v-col :class="smAndDown ? 'text-center mt-3 ml-n3' : 'text-right'" class="py-0 pe-0" cols="12" md="6">
          <div>
            <span>
              <router-link class="ms-5" to="/imprint">
                {{ $t('FOOTER.LEGAL.IMPRINT') }}
              </router-link>
            </span>
            <span>
              <router-link class="ms-5" to="/privacy">
                {{ $t('FOOTER.LEGAL.PRIVACY') }}
              </router-link>
            </span>
            <span>
              <router-link class="ms-5" to="/conditions">
                {{ $t('FOOTER.LEGAL.CONDITIONS') }}
              </router-link>
            </span>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </footer>
</template>

<script lang="ts">
  import { defineComponent, ref, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import { useDisplay } from 'vuetify';

  import Stores from '@/stores';

  export default defineComponent({
    name: 'PageFooter',
    setup() {
      const route = useRoute();
      const { smAndDown } = useDisplay();
      const { signedIn } = Stores.userDataStoreToRefs();

      // register this const as ref to make it changeable in watcher
      const hideFooter = ref(false);

      // watch for route changes to hide footer on specific pages
      watch(() => route.path, (value) => {
        hideFooter.value = value === '/wizard';
      }, {
        immediate: true,
      });

      return {
        signedIn,
        smAndDown,
        hideFooter,
        version: APP_VERSION,
      };
    },
    computed: {
      /**
       * get current year
       * @return {number}
       */
      year(): number {
        return new Date().getFullYear();
      },
    },
  });
</script>

<style lang="scss" scoped>
  @import '@/scss/configs/colors';
  @import '@/scss/configs/fonts';

  footer {
    padding: 104px 0 73px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    background-color: $color-marine;
    color: $color-white;

    a {
      color: $color-white;

      &:hover {
        color: $color-sky;
      }
    }

    .footer-links {
      padding-bottom: 100px;
      display: flex;
      justify-content: center;

      &.sm {
        display: grid;
        text-align: center;
      }

      a {
        font-size: $font-size-large;
        font-family: $font-semi-bold;
      }
    }

    .footer-bottom {
      border-top: 1px solid $color-white;
      font-size: 12px;
    }

    .signed-footer-bottom {
      font-size: 12px;
    }

    .social-media {
      a{
         .v-icon{
          color: $color-white;
          height: 30px;
          width: 30px;
        }
        &:hover .v-icon {
          color: $color-blue-light;
        }
      }
    }
  }

  .signed-footer {
    padding: 10px 0 30px;
  }
</style>
