<template>
  <div class="nav-dropdown my-doctor-pool">
    <div v-if="signedIn && !isOperator">
      <ul class="pl-3 list-style mb-2">
        <li>
          <v-icon class="pa-1 mr-1 mt-n2 color-marine" icon="fas fa-user"></v-icon>
          <router-link :to="{ name: 'Account' }"  class="my-nav">{{ $t('NAVIGATION.MY_DOCTOR_POOL.LINKS.SUBSTITUTES.YOUR_ACCOUNT') }}</router-link>
        </li>
        <li>
          <v-icon class="pa-1 mr-1 mt-n2 color-marine" icon="fas fa-tasks"></v-icon>
          <router-link :to="{ name: 'BaseData'}" class="my-nav">{{ $t('NAVIGATION.MY_DOCTOR_POOL.LINKS.SUBSTITUTES.BASIC_DATA') }}</router-link>
        </li>
        <li v-if="isSubstitute">
          <v-icon class="pa-1 mr-1 mt-n2 color-marine" icon="fas fa-book"></v-icon>
          <router-link :to="{ name: 'SubstituteBilling' }" class="my-nav">{{ $t('NAVIGATION.MY_DOCTOR_POOL.LINKS.SUBSTITUTES.BILLING_DATA') }}</router-link>
        </li>
        <li v-if="isSubstitute">
          <v-icon class="pa-1 mr-1 mt-n2 color-marine" icon="fas fa-clinic-medical"></v-icon>
          <router-link :to="{ name: 'SubstituteInsurances' }"  class="my-nav">{{ $t('NAVIGATION.MY_DOCTOR_POOL.LINKS.SUBSTITUTES.INSURANCE') }}</router-link>
        </li>
        <li v-if="isSubstitute">
          <v-icon class="pa-1 mr-1 mt-n2 color-marine" icon="fas fa-clinic-medical"></v-icon>
          <router-link :to="{ name : 'SubstituteSpecialization'}" class="my-nav">{{ $t('NAVIGATION.MY_DOCTOR_POOL.LINKS.SUBSTITUTES.HEALTH_INSURANCE') }}</router-link>
        </li>
        <li  v-if="isPartner">
          <v-icon class="pa-1 mr-1 mt-n2 color-marine" icon="fas fa-clinic-medical"></v-icon>
          <router-link :to="{ name : 'PartnerInstitutions'}" class="my-nav">{{ $t('NAVIGATION.MY_DOCTOR_POOL.LINKS.PARTNER.INSTITUTION') }}</router-link>
        </li>
        <li  v-if="isPartner">
          <v-icon class="pa-1 mr-1 mt-n2 color-marine" icon="fas fa-clinic-medical"></v-icon>
          <router-link :to="{ name : 'PartnerEstablishments'}" class="my-nav">{{ $t('NAVIGATION.MY_DOCTOR_POOL.LINKS.PARTNER.ESTABLISHMENT') }}</router-link>
        </li>
      </ul>
    </div>
   
    <v-btn class="btn-cta block" @click.stop="handleInteraction" :flat="true">
      <span v-if="!signedIn">{{ $t('NAVIGATION.MY_DOCTOR_POOL.LOGIN') }}</span>
      <span v-else>{{ $t('NAVIGATION.MY_DOCTOR_POOL.LOGOUT') }}</span>
    </v-btn>
    <ModalLogin />
    <ModalSetup />
    <ModalRegister />
    <ModalResetPassword />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { Hub } from 'aws-amplify';
  import { RouterSamePageErrorHandler } from '@/plugins/router';

  import Stores from '@/stores';
  import EventBus from '@/services/EventBus';
  import ModalLogin from '@/components/modals/Login.vue';
  import ModalSetup from '@/components/modals/Setup.vue';
  import ModalRegister from '@/components/modals/Register.vue';
  import ModalResetPassword from '@/components/modals/ResetPassword.vue';
  import Profile from '@/services/Profile';

  export default defineComponent({
    name: 'PageNavigationMyArztpool',
    components: {
      ModalLogin,
      ModalSetup,
      ModalRegister,
      ModalResetPassword,
    },
    setup() {
      const { signedIn, userType } = Stores.userDataStoreToRefs();
      const { isOperator, isSubstitute, isPartner } = Stores.profileDataStoreToRefs();

      return {
        signedIn,
        userType,
        isOperator,
        isSubstitute,
        isPartner,
        userDataStore: Stores.userData,
        profileDataStore: Stores.profileData,
        partnerDataStore: Stores.partnerData,
        substituteDataStore: Stores.substituteData,
        operatorDataStore: Stores.operatorData,
        quickStartStore: Stores.quickStart,
      };
    },
    methods: {
      /**
       * reset quickstart and profile draft store data
       * @return {void}
       */
      resetLocalStore(): void {
        this.quickStartStore.resetPartner();
        this.quickStartStore.resetSubstitute();
        this.partnerDataStore.resetPartnerStore();
        this.substituteDataStore.resetSubstituteStore();
        Stores.message.resetMessagesStore();
        Stores.operatorData.resetOperatorStore();
      },

      /**
       * handler for login/logout action button
       * @return {void}
       */
      handleInteraction() {
        if (!this.signedIn) {
          EventBus.emit(EventBus.keys.LOGIN_OPEN, true);
        } else {
          if (this.userType === 'operator' && this.operatorDataStore.isImpersonating) {
            // Start impersonation
            this.operatorDataStore.stopImpersonation();
            this.quickStartStore.resetPartner();
            this.quickStartStore.resetSubstitute();
            this.partnerDataStore.resetPartnerStore();
            this.substituteDataStore.resetSubstituteStore();
            Stores.message.resetMessagesStore();

            Profile.fetchProfile().then(async () => {
              this.$router.push({ name: 'Operators' }).catch(RouterSamePageErrorHandler);
            });

          }
          else {
            this.userDataStore.logout().then(async () => {
              this.resetLocalStore();

              if (this.userDataStore.signedIn) {
                Hub.listen('auth', data => {
                  const { payload } = data;

                  if (payload.event === 'signOut') {
                    this.logoutRedirect();
                  }
                });
              } else {
                this.logoutRedirect();
              }
            });
          }
        }
      },

      /**
       * redirect to home page and open login form after logout
       * @return {void}
       */
      logoutRedirect(): void {
        this.$router.push({path: '/'}).catch(RouterSamePageErrorHandler);
      }
    },
  });
</script>

<style lang="scss" scoped>
  @import '@/scss/configs/colors';
  .list-style{
    list-style: none;
  }
  .my-doctor-pool {
    z-index: 200;

    .ma-teaser {
      margin-bottom: 20px;
      text-align: center;
      font-weight: 400;
    }

    button {
      margin-bottom: 10px;
      width: 100%;
      font-size: 16px;
      font-weight: 500;
      color: $color-white;
    }
  }
  .my-nav{
    font-size: 20px;
    font-weight: bolder;
    color: #173968;
    text-decoration: none;
    margin-left: 4px;
    border-bottom: 2px solid transparent;
    z-index: 200;
  }
  .nav-dropdown {
    border: 1px solid $color-blue-grey-30;
    padding: 20px;
    width: 364px;
    position: absolute;
    top: 76px;
    right: 0;
    font-size: 16px;
    font-weight: 500;
    background-color: $color-white;
    color: $color-marine-dark;
    z-index: 200;

    @media (min-width: 960px) {
      top: 110px;
    }
  }
  .my-doctor-pool ul li {
    display: block;
    margin-bottom: 15px;
  }
  .my-doctor-pool ul li svg{
    color: black;
  }

</style>
