import { Auth } from 'aws-amplify';
import { ID_TOKEN } from '@/data/cognito';

import Stores from '@/stores';
import EventBus from './EventBus';
import Debug from '@/utils/Debug';
import CaptureException from '@/services/CaptureException';

class Login {
  /**
   * login user by email and password via amplify/cognito
   * @param {string} email
   * @param {string} password
   * @return {Promise}
   */
  public async login(email: string, password: string): Promise<void> {
    let loginResponse = null;

    try {
      loginResponse = await Auth.signIn(email.toLowerCase(), password);
    }
    catch (error: any){
      if (error){
        EventBus.emit(EventBus.keys.LOGIN_ERROR, error.message);
      }

      throw error;
    }

    // TODO: reset password is not implemented, yet
    const newPassword: string = '';
    if (typeof loginResponse.challengeName !== 'undefined') {
      if (loginResponse.challengeName === 'NEW_PASSWORD_REQUIRED') {
        if (newPassword !== '') {
          try {
            await Auth.completeNewPassword(
              loginResponse, // the Cognito User Object
              newPassword,   // the new password
              { email: email.toLowerCase() }
            );
          }
          catch(error: any) {
            Stores.userData.passwordChallenge = false;

            if (error) {
              Stores.userData.loginError = error.code || error;
            }

            throw error;
          }

          await this.fetchUser();
        }
        else {
          Stores.userData.passwordChallenge = true;
        }
      }
    }
    //
    else {
      await this.fetchUser();
    }
  }

  /**
   * get user attributes and information out of the current instance of amplify Auth
   *
   * @return {Promise}
   */
  public async fetchUser(): Promise<void> {

    try {
      const user = await Auth.currentAuthenticatedUser();
      const info = await Auth.currentUserInfo();

      Stores.userData.userType = info['attributes']['custom:userType'];

      const expires = user.getSignInUserSession().getIdToken().payload.exp - Math.floor(new Date().getTime() / 1000);
      const token = user.getSignInUserSession().getIdToken().jwtToken;

      localStorage.setItem(ID_TOKEN, token);
      Stores.userData.idToken = token;

      Debug.toConsole(`Token expires in ${expires} seconds`);

      setTimeout(async () => {
        Debug.toConsole('Renewing Token');
        await this.fetchUser();
      }, expires * 1000);

      Stores.userData.attributes = {
        type: user['attributes']['custom:userType'],
        email: user['attributes']['email'],
        email_verified: user['attributes']['email_verified'],
        sub: user['attributes']['sub'],
      };
      Stores.userData.signIn();
    }
    catch (err) {
      CaptureException.send(err);
    }

  }
}

export default new Login();
