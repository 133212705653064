export default {
  HEADING: 'Account Setup',
  DESCRIPTION: [
    'Vielen Dank für Ihre Registrierung.',
    'Wir richten jetzt Ihren Account ein. Dies kann bis zu einer Minute dauern. Bitte schließen Sie dieses Browser-Tab nicht. Wir werden Sie anschließend sofort auf Ihr Dashboard weiterleiten.',
    'Bitte einen Moment Geduld.',
  ],
  SETUP_STEPS: {
    SETUP_START: 'Starte Setup',
    SETUP_PROFILE: 'Einrichten des Profils.',
    SETUP_SUBSTITUTE: 'Anlegen der Vertreterrolle.',
    SETUP_SUBSTITUTE_TARIFF: 'Hinterlegen Ihrer Zahlungsweise.',
    SETUP_SUBSTITUTE_LICENSE: 'Hinterlegen Ihrer Approbation.',
    SETUP_SUBSTITUTE_SPECIALIZATION: 'Hinterlegen Ihres Fachgebiets.',
    SETUP_SUBSTITUTE_INSURANCE: 'Hinterlegen Ihrer Versicherung.',
    SETUP_PARTNER: 'Anlegen der Nutzerrolle.',
    SETUP_PARTNER_TARIFF: 'Hinterlegen Ihrer Zahlungsweise.',
    SETUP_PARTNER_DOCTOR: 'Anlegen des Arztes.',
    SETUP_PARTNER_ESTABLISHMENT: 'Anlegen der Betriebsstätte und Ihrer Einrichtung.',

  },
};
