import { defineStore } from 'pinia';
import Debug from '@/utils/Debug';

import Substitute from '@/definitions/interfaces/Substitute.i';
import Debtor from '@/definitions/interfaces/Debtor.i';
import LiabilityInsurance from '@/definitions/interfaces/LiabilityInsurance.i';
import UserService from '@/definitions/interfaces/UserService.i';
import Service from '@/definitions/interfaces/Service.i';
import Bookmark from '@/definitions/interfaces/Bookmark.i';
import Application from '@/definitions/interfaces/Application.i';

export interface SubstituteDataState {
  debtor: Debtor|null;
  substitute: Substitute|null;
  liabilityInsurances: LiabilityInsurance[];
  applications: Application[];
  assignments: Service[];
  bookmarks: Bookmark[];
  blackListedStates: string[];
  focusAssignments: Service[];
  marketplaceResultsCache: UserService[];
  marketplaceFiltersCache: {[p: string]: any}[];
  marketplaceTabIndexCache: number;
  loadingApplications: boolean;
  loadingAssignments: boolean;
  loadingBookmarks: boolean;
  loadingSubstitute: boolean;
  loadingInsurances: boolean;
  sortingSubstituteServices: boolean;
  generatingSubstituteFocusServices: boolean;
}

const useSubstituteDataStore = defineStore('SubstituteData', {
  /**
    Define defaults

    loadingServicesSubstitute is set to true as a convenience as after a login this is the first thing that happens
    anyway - if you would like to set it too false to have a well-defined state after initializing, this needs to
    reflected when loading the services from the views and be set to true then
  */
  state: (): SubstituteDataState => ({
    assignments: [],
    applications: [],
    bookmarks: [],
    blackListedStates: [],
    focusAssignments: [],
    liabilityInsurances: [],
    debtor: null,
    substitute: null,
    marketplaceResultsCache: [],
    marketplaceFiltersCache: [],
    marketplaceTabIndexCache: 0,
    loadingApplications: false,
    loadingAssignments: false,
    loadingSubstitute: false,
    loadingBookmarks: false,
    loadingInsurances: false,
    sortingSubstituteServices: false,
    generatingSubstituteFocusServices: false,
  }),
  actions: {
    /**
     * reset substitute store
     * @return {void}
     */
    resetSubstituteStore(): void {
      this.assignments = [];
      this.applications = [];
      this.bookmarks = [];
      this.blackListedStates = [];
      this.focusAssignments = [];
      this.liabilityInsurances = [];
      this.debtor = null;
      this.substitute = null;
      this.marketplaceResultsCache = [];
      this.marketplaceFiltersCache = [];
      this.marketplaceTabIndexCache = 0;
      this.loadingApplications = false;
      this.loadingAssignments = false;
      this.loadingSubstitute = false;
      this.loadingBookmarks = false;
      this.loadingInsurances = false;
      this.sortingSubstituteServices = false;
      this.generatingSubstituteFocusServices = false;
    }
  },
  persist: {
    key: 'arztpool24.de-substitute',
    debug: Debug.isEnabled(),
  },
});

export default useSubstituteDataStore;
