export default {
  FORMAT_OBSERVERS: '{count} Beobachter',
  FORMAT_APPLICATIONS: '{count} Bewerber',
  SERVICE_OFFICE: 'Sitzdienst',
  SERVICE_MOBILE: 'Fahrdienst',
  PRIORITY: 'Priority',
  INSTANT_APPLY: 'Sofort-Zusage',
  SUBMIT_APPLY: 'Bewerbung übermittelt',
  HOURLY_HONORAR: 'Honorar pro Std.',
  SHOW_DETAILS: 'Details ansehen',
  RIBBONS: {
    ASSIGNED: 'Zugewiesener Dienst',
    CONCLUDED: 'Beendeter Dienst',
    PUBLISHED: 'Veröffentlichter Dienst',
    DEFAULT: 'Dienst',
    PRIORITY: 'Priority',
    INSTANT_ASSIGN: 'Sofort-Zusage möglich',
    ALL_INCLUSIVE: 'Rundum-Sorglos'
  }
};
