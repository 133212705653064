export default {
  META: {
    TITLE: 'Nachrichten',
  },
  HEADING: 'Nachrichten',
  OPEN_SERVICE: 'Zum Dienst',
  DETAILS_SHOW: 'Details anzeigen',
  DETAILS_HIDE: 'Details verbergen',
  NEW: {
    HEADING: 'Ungelesene Nachrichten ({count})',
    NOTIFICATION: 'Sie haben aktuell keine neuen Nachrichten in Ihrem Posteingang.',
  },
  ARCHIVE: {
    HEADING: 'Gelesene Nachrichten  ({count})',
    NOTIFICATION: 'Sie haben aktuell keine Nachrichten in Ihrem Archiv.',
  },
  FILTER: {
    ALL: 'Alle',
    TODAY: 'Heute',
    WEEK: 'Diese Woche',
    MONTH: 'Diesen Monat',
    YEAR: 'Dieses Jahr',
  },
  SORTING: {
    ASC: 'Datum aufsteigend',
    DESC: 'Datum absteigend',
  },
};
