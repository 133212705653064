import gql from 'graphql-tag';

export const QUERY_SERVICES_LIST = gql `query getServicesList(
                                            $where: serviceWhereInput,
                                            $orderby: [serviceOrderByInput!],
                                            $first: Int,
                                            $last: Int,
                                            $before: serviceWhereUniqueInput,
                                            $after: serviceWhereUniqueInput
                                          ){
                                            services(
                                              where: $where,
                                              orderBy: $orderby,
                                              first: $first,
                                              last: $last,
                                              before: $before,
                                              after: $after)
                                             {
                                              id
                                              createdOn
                                              start
                                              end
                                              published
                                              concluded
                                              serviceArea {
                                                state
                                              }
                                              establishment {
                                                name
                                              }
                                              establishmentDoctor {
                                                name
                                              }
                                              substitute {
                                                details {
                                                  user {
                                                    title
                                                    firstName
                                                    lastName
                                                  }
                                                }
                                              }
                                            }
                                          }`;

export const QUERY_SERVICES_FULL = gql ` query getServicesFull(
                                            $where: serviceWhereInput,
                                            $orderby: [serviceOrderByInput!],
                                            $first: Int,
                                            $last: Int,
                                            $before: serviceWhereUniqueInput,
                                            $after: serviceWhereUniqueInput
                                          ){
                                            services(
                                              where: $where,
                                              orderBy: $orderby,
                                              first: $first,
                                              last: $last,
                                              before: $before,
                                              after: $after)
                                             {
                                              id
                                              start
                                              createdBy
                                              createdOn
                                              end
                                              kind
                                              orderNumber
                                              billNumber
                                              stateInsuredPatientCount
                                              privatePatientCount
                                              necropsyCount
                                              consultationViaTelephoneCount
                                              published
                                              concluded
                                              partnerConfirmedContractOn
                                              substituteConfirmedContractOn
                                              countsConfirmedOn
                                              comment
                                              establishmentDoctor {
                                                id
                                                name
                                                kvSeats
                                                lanr
                                              }
                                              regularFeeRange {
                                                min
                                                max
                                                current
                                              }
                                              holidayFeeRange {
                                                min
                                                max
                                                current
                                              }
                                              serviceArea {
                                                name
                                                state
                                              }
                                              establishment {
                                                name
                                                billingAddress {
                                                  city
                                                  street
                                                  country
                                                  houseNr
                                                  zipCode
                                                  state
                                                }
                                              }
                                              substitute {
                                                details {
                                                  user {
                                                    email
                                                    title
                                                    firstName
                                                    lastName
                                                    birthDate
                                                    mobile1
                                                    mobile2
                                                    fax
                                                    phone
                                                  }
                                                  address {
                                                    city
                                                    street
                                                    country
                                                    houseNr
                                                    zipCode
                                                    state
                                                  }
                                                }
                                                liabilityInsurance {
                                                  url
                                                }
                                                specializations {
                                                  specialization
                                                  certificate {
                                                    url
                                                  }
                                                }
                                              }
                                            }
                                          }`;

export const MOBILE_SERVICES_QUERY = gql `query getMobileServices(
                                            $where: mobile_serviceWhereInput,
                                            $orderby: [mobile_serviceOrderByInput!],
                                            $first: Int,
                                            $last: Int,
                                            $before: mobile_serviceWhereUniqueInput,
                                            $after: mobile_serviceWhereUniqueInput
                                          ){
                                            mobileServices(
                                              where: $where,
                                              orderBy: $orderby,
                                              first: $first,
                                              last: $last,
                                              before: $before,
                                              after: $after)
                                            {
                                              __typename
                                              serviceArea {
                                                name
                                                coordinates
                                                comment
                                                address {
                                                  city
                                                  street
                                                  country
                                                  houseNr
                                                  zipCode
                                                  state
                                                }
                                              }
                                              details {
                                                __typename
                                                id
                                                start
                                                end
                                                kind
                                                createdOn
                                                createdBy
                                                stateInsuredPatientCount
                                                privatePatientCount
                                                necropsyCount
                                                consultationViaTelephoneCount
                                                published
                                                concluded
                                                partnerConfirmedContractOn
                                                substituteConfirmedContractOn
                                                countsConfirmedOn
                                                comment
                                                kvShareRateMobile
                                                establishmentDoctor {
                                                  name
                                                  lanr
                                                }
                                                establishment {
                                                  name
                                                  billingAddress {
                                                    city
                                                    street
                                                    country
                                                    houseNr
                                                    zipCode
                                                    state
                                                  }
                                                }
                                                serviceArea {
                                                  state
                                                  name
                                                }
                                                regularFeeRange {
                                                  min
                                                  max
                                                  current
                                                }
                                                holidayFeeRange {
                                                  min
                                                  max
                                                  current
                                                }
                                                applicants {
                                                  details {
                                                    user {
                                                      firstName
                                                      lastName
                                                    }
                                                  }
                                                }
                                                substitute {
                                                  details {
                                                    user {
                                                      email
                                                      title
                                                      firstName
                                                      lastName
                                                      birthDate
                                                      mobile1
                                                      mobile2
                                                      fax
                                                      phone
                                                    }
                                                    address {
                                                      city
                                                      street
                                                      country
                                                      houseNr
                                                      zipCode
                                                      state
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }`;

export const OFFICE_SERVICES_QUERY = gql `query getOfficeServices(
                                            $where: office_serviceWhereInput,
                                            $orderby: [office_serviceOrderByInput!],
                                            $first: Int,
                                            $last: Int,
                                            $before: office_serviceWhereUniqueInput,
                                            $after: office_serviceWhereUniqueInput
                                          ){
                                            officeServices(
                                              where: $where,
                                              orderBy: $orderby,
                                              first: $first,
                                              last: $last,
                                              before: $before,
                                              after: $after)
                                            {
                                              __typename
                                              serviceArea {
                                                name
                                                comment
                                                address {
                                                  id
                                                  city
                                                  street
                                                  country
                                                  houseNr
                                                  zipCode
                                                  state
                                                }
                                              }
                                              details {
                                                __typename
                                                id
                                                start
                                                end
                                                kind
                                                createdOn
                                                stateInsuredPatientCount
                                                privatePatientCount
                                                necropsyCount
                                                consultationViaTelephoneCount
                                                published
                                                concluded
                                                partnerConfirmedContractOn
                                                substituteConfirmedContractOn
                                                countsConfirmedOn
                                                comment
                                                kvShareRateOffice
                                                establishmentDoctor {
                                                  name
                                                  lanr
                                                }
                                                establishment {
                                                  name
                                                  billingAddress {
                                                    city
                                                    street
                                                    country
                                                    houseNr
                                                    zipCode
                                                    state
                                                  }
                                                }
                                                serviceArea {
                                                  state
                                                  name
                                                }
                                                regularFeeRange {
                                                  min
                                                  max
                                                  current
                                                }
                                                holidayFeeRange {
                                                  min
                                                  max
                                                  current
                                                }
                                                applicants {
                                                  details {
                                                    user {
                                                      firstName
                                                      lastName
                                                    }
                                                  }
                                                }
                                                substitute {
                                                  details {
                                                    user {
                                                      email
                                                      title
                                                      firstName
                                                      lastName
                                                      birthDate
                                                      mobile1
                                                      mobile2
                                                      fax
                                                      phone
                                                    }
                                                    address {
                                                      city
                                                      street
                                                      country
                                                      houseNr
                                                      zipCode
                                                      state
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }`;


export const MOBILE_APPLICATIONS_QUERY = gql `query getMobileServices(
                                            $where: mobile_serviceWhereInput,
                                            $orderby: [mobile_serviceOrderByInput!],
                                            $first: Int,
                                            $last: Int,
                                            $before: mobile_serviceWhereUniqueInput,
                                            $after: mobile_serviceWhereUniqueInput
                                          ){
                                            mobileServices(
                                              where: $where,
                                              orderBy: $orderby,
                                              first: $first,
                                              last: $last,
                                              before: $before,
                                              after: $after)
                                            {
                                              __typename
                                              serviceArea {
                                                name
                                                coordinates
                                                comment
                                                address {
                                                  city
                                                  street
                                                  country
                                                  houseNr
                                                  zipCode
                                                  state
                                                }
                                              }
                                              details {
                                                __typename
                                                id
                                                start
                                                createdOn
                                                end
                                                kind
                                                published
                                                concluded
                                                serviceArea {
                                                  state
                                                  name
                                                }
                                              }
                                            }
                                          }`;

export const OFFICE_APPLICATIONS_QUERY = gql `query getOfficeServices(
                                            $where: office_serviceWhereInput,
                                            $orderby: [office_serviceOrderByInput!],
                                            $first: Int,
                                            $last: Int,
                                            $before: office_serviceWhereUniqueInput,
                                            $after: office_serviceWhereUniqueInput
                                          ){
                                            officeServices(
                                              where: $where,
                                              orderBy: $orderby,
                                              first: $first,
                                              last: $last,
                                              before: $before,
                                              after: $after)
                                            {
                                              __typename
                                              serviceArea {
                                                name
                                                comment
                                                address {
                                                  city
                                                  street
                                                  country
                                                  houseNr
                                                  zipCode
                                                  state
                                                }
                                              }
                                              details {
                                                __typename
                                                id
                                                createdOn
                                                start
                                                end
                                                kind
                                                published
                                                concluded
                                                serviceArea {
                                                  state
                                                  name
                                                }
                                              }
                                            }
                                          }`;
