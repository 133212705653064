<template>
  <v-dialog v-model="showModalLogin" width="560" :persistent="true">
    <v-card id="modalLogin">
      <v-card-title class="mb-5">
        <a class="modal-close" @click="showLogin(false)">
          <span>{{ $t('LOGIN_MODAL.CLOSE') }}</span>
          <v-icon icon="fal fa-times" />
        </a>
        <h1 class="mb-0">{{ $t('LOGIN_MODAL.HEADING') }}</h1>
      </v-card-title>
      <v-form @submit.prevent="loginUser" ref="loginForm">
        <p id="loginError" v-if="error">{{ $t(`LOGIN_MODAL.ERROR.${error}`) }}</p>
        <v-row no-gutters>
          <v-col class="pa-0" cols="12">
            <v-text-field v-model="username" :label="$t('LOGIN_MODAL.FORM.MAIL')" type="email" :rules="emailRules" :disabled="loading" />
          </v-col>
          <v-col class="mb-2 pa-0 password" cols="12">
            <v-text-field
              v-model="password"
              :label="$t('LOGIN_MODAL.FORM.PASSWORD')"
              :type="showPassword ? 'text' : 'password'"
              :disabled="loading"
              :rules="passwordRules"
              :auto-complete="false"
            >
              <template v-slot:append-inner>
                <img
                  v-if="showPassword"
                  class="passwordToggle"
                  src="/images/icons/eye.png"
                  :alt="$t('LOGIN_MODAL.FORM.PASSWORD_SHOW')"
                  v-on:click="toggleShowPassword"
                />
                <img
                  v-else
                  class="passwordToggle"
                  src="/images/icons/eye-slash.png"
                  :alt="$t('LOGIN_MODAL.FORM.PASSWORD_HIDE')"
                  v-on:click="toggleShowPassword"
                />
              </template>
            </v-text-field>
          </v-col>
          <v-col class="pa-0 d-flex justify-start align-center" cols="12" md="6">
            <v-checkbox v-model="rememberLogin" :label="$t('LOGIN_MODAL.FORM.REMAIN_SIGNUP')" :disabled="loading" :hide-details="true" type="checkbox" />
          </v-col>
          <v-col class="pa-0 d-flex justify-end align-center" cols="12" md="6">
            <v-btn :class="{ 'mt-8': smAndDown }" :loading="loading" :ripple="false" class="btn-cta text-uppercase" elevation="0" type="submit">
              {{ $t('LOGIN_MODAL.FORM.SUBMIT') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-row>
        <v-col class="pa-0 d-flex justify-end align-center pr-2 mt-14 mb-1" cols="12">
          <a href="#" @click="showResetPassword">{{ $t('LOGIN_MODAL.RESET_PASSWORD') }}</a>
        </v-col>
      </v-row>
      <v-divider class="mb-10" />
      <v-card-title class="mb-5">
        <h1 class="mb-0">{{ $t('LOGIN_MODAL.FOOTER.HEADING') }}</h1>
      </v-card-title>
      <v-btn class="btn-primary mb-13" @click="showModalRegister" :flat="true">
        {{ $t('LOGIN_MODAL.FOOTER.REGISTERBUTTON') }}
      </v-btn>
      <p class="mb-0 agreement text-left">
        {{ $t('LOGIN_MODAL.FOOTER.AGREEMENT.0') }}
        <router-link to="/conditions" target="_blank">{{ $t('LOGIN_MODAL.FOOTER.AGREEMENT.1') }}</router-link>
        {{ $t('LOGIN_MODAL.FOOTER.AGREEMENT.2') }}
        <router-link to="/privacy" target="_blank">{{ $t('LOGIN_MODAL.FOOTER.AGREEMENT.3') }}</router-link>
        {{ $t('LOGIN_MODAL.FOOTER.AGREEMENT.4') }}
      </p>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue';
  import { useDisplay } from 'vuetify';
  import { RouterSamePageErrorHandler } from '@/plugins/router';

  import Stores from '@/stores';
  import Login from '@/services/Login';
  import Profile from '@/services/Profile';
  import Partner from '@/services/Partner';
  import Substitute from '@/services/Substitute';
  import Messages from '@/services/Messages';
  import Validation from '@/services/Validation';
  import EventBus from '@/services/EventBus';
  import CaptureException from '@/services/CaptureException';

  export default defineComponent({
    name: 'ModalLogin',
    components: {},
    setup() {
      const { smAndDown } = useDisplay();

      return {
        smAndDown,

        showModalLogin: ref(false),
        loading: ref(false),
        error: ref(''),
        showPassword: ref(false),
        rememberLogin: ref(false),
        username: ref(''),
        password: ref(''),

        loginForm: ref(null as unknown as HTMLFormElement),
        emailRules: Validation.getEmailValidator(),
        passwordRules: Validation.getRequiredValidator(false),
      };
    },
    async beforeMount(): Promise<void> {
      // register event to open login modal
      EventBus.on(EventBus.keys.LOGIN_OPEN, (): void => {
        this.showLogin(true);
      });

      // register event to receive login error messages
      EventBus.on(EventBus.keys.LOGIN_ERROR, (loginError): void => {
        this.error = (loginError as string).toUpperCase().split(' ').join('_').slice(0, -1);
        this.loading = false;
      });
    },
    methods: {
      /**
       * toggle visibility of password input value
       * @return {void}
       */
      toggleShowPassword(): void {
        this.showPassword = !this.showPassword;
      },

      /**
       * toggle login modal state (open/close)
       * @return {void}
       */
      showLogin(state: boolean): void {
        this.showModalLogin = state;
      },

      /**
       * login user
       * @return {void}
       */
      async loginUser(): Promise<void> {
        if (this.loginForm.isValid) {
          this.error = '';
          this.loading = true;

          try {
            await Login.login(this.username, this.password);
          } catch (e) {
            CaptureException.send(e);
          }

          if (this.error === '') {
            Profile.fetchProfile().then(async () => {
              if (Stores.profileData.profile.id === null || Stores.profileData.profile.id === '') {
                EventBus.emit(EventBus.keys.SETUP_OPEN, true);
                this.loading = false;
                this.showLogin(false);
                this.loginForm.reset();
                EventBus.emit(EventBus.keys.TRIGGER_SETUP, true);
              } else {
                let _startRoute = 'Home';
                if (Stores.profileData.isPartner) {
                  Partner.fetchPartner();
                  Messages.fetchMessages();
                  _startRoute = 'Dashboard';
                } else if (Stores.profileData.isSubstitute) {
                  Substitute.fetchSubstitute(true);
                  Messages.fetchMessages();
                  _startRoute = 'Dashboard';
                } else if (Stores.profileData.isOperator) {
                  _startRoute = 'Operators';
                }

                if (Stores.profileData.isSubstitute && Stores.quickStart.substitute.triggerAction.action !== '') {
                  this.$router
                    .push({
                      path: '/service',
                      query: {
                        id: Stores.substituteData.triggerAction.serviceId,
                      },
                    })
                    .catch(RouterSamePageErrorHandler);
                  this.loginForm.reset();
                } else {
                  this.$router.push({ name: _startRoute }).catch(RouterSamePageErrorHandler);
                  this.loginForm.reset();
                }

                this.loading = false;
                this.showLogin(false);
              }
            });
          }
        }
      },

      /**
       * open reset password modal
       * @return {void}
       */
      showResetPassword(): void {
        EventBus.emit(EventBus.keys.PASSWORD_RESET_OPEN, true);
        this.showLogin(false);
      },

      /**
       * open registration modal
       * @return {void}
       */
      showModalRegister(): void {
        // if the quickstart store has a triggerAction set, we need to show the onboarding wizard here, otherwise continue with registration
        if (Stores.quickStart.substitute.triggerAction.action !== null && Stores.quickStart.substitute.triggerAction.action !== '') {
          this.$router.push({ name: 'Wizard', query: { role: 'substitute' } });
        } else {
          EventBus.emit(EventBus.keys.PREREGISTER_OPEN, true);
          this.showLogin(false);
        }
      },
    },
  });
</script>

<style lang="scss" scoped>
  @import '@/scss/configs/colors';

  #modalLogin {
    .v-card-title {
      padding: 0;
    }

    img.passwordToggle:hover {
      cursor: pointer;
    }
  }
</style>
