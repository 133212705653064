import { createPinia, Pinia } from 'pinia';
import persistedState from 'pinia-plugin-persistedstate';

const pinia: Pinia = createPinia();
pinia.use(persistedState);

/**
 * restores single a date property in a state object
 * when the properties value is not a string nothing will happen
 * @param {object} obj
 * @param {string} property
 * @return {void}
 */
export function restoreDateProperty(obj: {[p: string]: any}, property: string): void {
  if (obj && obj[property] && typeof obj[property] === 'string') {
    obj[property] = new Date(obj[property]);
  }
}

/**
 * restores date properties in any object
 * can be used with cts.store.$state of pinia
 *
 * Usage example:
 * restoreDateProperties(ctx, {
 *   rootProperty: true,
 *   nested: {
 *     propOne: true,
 *     sub: {
 *       propTwo: true,
 *     },
 *   },
 *   list: ['propThree', 'propFour']
 * });
 *
 * Will convert the following state:
 *
 * {
 *   rootProperty: 'date-string',
 *   nested: {
 *     propOne: 'date-string',
 *     sub: {
 *       propTwo: 'date-string',
 *     },
 *   },
 *   list: {
 *     propThree: 'date-string',
 *     propFour: 'date-string',
 *   },
 * }
 *
 * @param {object} obj
 * @param {object} dates
 * @return {void}
 */
export function restoreDateProperties(obj: {[p: string]: any}, dates: {[p: string]: any}): void {
  for (const entry in dates) {
    // is boolean and true - restore state
    if (typeof dates[entry] === 'boolean' && dates[entry] === true) {
      restoreDateProperty(obj, entry);
    }

    // is array - loop and restore state
    else if(typeof dates[entry] === 'object' && Array.isArray(dates[entry]) && dates[entry] !== null) {
      dates[entry].forEach((property: string): void => {
        restoreDateProperty(obj[entry], property);
      });
    }

    // is object - recursive call
    else if (typeof dates[entry] === 'object' && !Array.isArray(dates[entry]) && dates[entry] !== null) {
      restoreDateProperties(obj[entry], dates[entry]);
    }
  }
}

export default pinia;
