import { $t } from '@/plugins/i18n';
import ServiceSubstituteValidationRule from '@/definitions/interfaces/ServiceSubstituteValidationRule.i';

export const STATE_BREMEN: string = $t('DATA.STATES.BREMEN');
export const STATE_HAMBURG: string = $t('DATA.STATES.HAMBURG');
export const STATE_BERLIN: string = $t('DATA.STATES.BERLIN');
export const STATE_SAARLAND: string = $t('DATA.STATES.SAARLAND');
export const STATE_SCHLESWIG_HOLSTEIN: string = $t('DATA.STATES.SCHLESWIG_HOLSTEIN');
export const STATE_THURINGIA: string = $t('DATA.STATES.THURINGIA');
export const STATE_SAXONY: string = $t('DATA.STATES.SAXONY');
export const STATE_RHINELAND_PALATINATE: string = $t('DATA.STATES.RHINELAND_PALATINATE');
export const STATE_SAXONY_ANHALT: string = $t('DATA.STATES.SAXONY_ANHALT');
export const STATE_HESSE: string = $t('DATA.STATES.HESSE');
export const STATE_MECKLENBURG_POMERANIA: string = $t('DATA.STATES.MECKLENBURG_POMERANIA');
export const STATE_BRANDENBURG: string = $t('DATA.STATES.BRANDENBURG');
export const STATE_NORTHRHINE_WESTPHALIA: string = $t('DATA.STATES.NORTHRHINE_WESTPHALIA');
export const STATE_BADEN_WUERTTEMBERG: string = $t('DATA.STATES.BADEN_WUERTTEMBERG');
export const STATE_LOWER_SAXONY: string = $t('DATA.STATES.LOWER_SAXONY');
export const STATE_BAVARIA: string = $t('DATA.STATES.BAVARIA');

export interface State {
  name: string;
  adjacent: string[];
}

export const States: State[] = [
  {
    name: STATE_BADEN_WUERTTEMBERG,
    adjacent: [
      STATE_BAVARIA,
      STATE_RHINELAND_PALATINATE,
      STATE_HESSE,
    ],
  },
  {
    name: STATE_BAVARIA,
    adjacent: [
      STATE_BADEN_WUERTTEMBERG,
      STATE_HESSE,
      STATE_THURINGIA,
      STATE_SAXONY,
    ],
  },
  {
    name: STATE_BERLIN,
    adjacent: [
      STATE_BRANDENBURG,
    ],
  },
  {
    name: STATE_BRANDENBURG,
    adjacent: [
      STATE_BERLIN,
      STATE_SAXONY,
      STATE_SAXONY_ANHALT,
      STATE_LOWER_SAXONY,
      STATE_MECKLENBURG_POMERANIA,
    ],
  },
  {
    name: STATE_BREMEN,
    adjacent: [
      STATE_LOWER_SAXONY,
    ],
  },
  {
    name: STATE_HAMBURG,
    adjacent: [
      STATE_LOWER_SAXONY,
      STATE_SCHLESWIG_HOLSTEIN,
    ],
  },
  {
    name: STATE_HESSE,
    adjacent: [
      STATE_RHINELAND_PALATINATE,
      STATE_NORTHRHINE_WESTPHALIA,
      STATE_LOWER_SAXONY,
      STATE_THURINGIA,
      STATE_BAVARIA,
    ],
  },
  {
    name: STATE_MECKLENBURG_POMERANIA,
    adjacent: [
      STATE_BRANDENBURG,
      STATE_LOWER_SAXONY,
      STATE_SCHLESWIG_HOLSTEIN,
    ],
  },
  {
    name: STATE_LOWER_SAXONY,
    adjacent: [
      STATE_BREMEN,
      STATE_HAMBURG,
      STATE_SCHLESWIG_HOLSTEIN,
      STATE_MECKLENBURG_POMERANIA,
      STATE_SAXONY_ANHALT,
      STATE_THURINGIA,
      STATE_NORTHRHINE_WESTPHALIA,
      STATE_HESSE,
    ],
  },
  {
    name: STATE_NORTHRHINE_WESTPHALIA,
    adjacent: [
      STATE_LOWER_SAXONY,
      STATE_HESSE,
      STATE_RHINELAND_PALATINATE,
    ],
  },
  {
    name: STATE_RHINELAND_PALATINATE,
    adjacent: [
      STATE_NORTHRHINE_WESTPHALIA,
      STATE_HESSE,
      STATE_BADEN_WUERTTEMBERG,
    ],
  },
  {
    name: STATE_SAARLAND,
    adjacent: [
      STATE_RHINELAND_PALATINATE,
    ],
  },
  {
    name: STATE_SAXONY,
    adjacent: [
      STATE_BRANDENBURG,
      STATE_SAXONY_ANHALT,
      STATE_THURINGIA,
      STATE_BAVARIA,
    ],
  },
  {
    name: STATE_SAXONY_ANHALT,
    adjacent: [
      STATE_THURINGIA,
      STATE_LOWER_SAXONY,
      STATE_BRANDENBURG,
      STATE_SAXONY,
    ],
  },
  {
    name: STATE_SCHLESWIG_HOLSTEIN,
    adjacent: [
      STATE_MECKLENBURG_POMERANIA,
      STATE_LOWER_SAXONY,
      STATE_HAMBURG,
    ],
  },
  {
    name: STATE_THURINGIA,
    adjacent: [
      STATE_BAVARIA,
      STATE_HESSE,
      STATE_LOWER_SAXONY,
      STATE_SAXONY_ANHALT,
      STATE_SAXONY,
    ],
  },
];

export const StatesSelectList: {title: string, value: string}[] = [
  {
    title: STATE_BADEN_WUERTTEMBERG,
    value: STATE_BADEN_WUERTTEMBERG,
  },
  {
    title: STATE_BAVARIA,
    value: STATE_BAVARIA,
  },
  {
    title: STATE_BERLIN,
    value: STATE_BERLIN,
  },
  {
    title: STATE_BRANDENBURG,
    value: STATE_BRANDENBURG,
  },
  {
    title: STATE_BREMEN,
    value: STATE_BREMEN,
  },
  {
    title: STATE_HAMBURG,
    value: STATE_HAMBURG,
  },
  {
    title: STATE_HESSE,
    value: STATE_HESSE,
  },
  {
    title: STATE_MECKLENBURG_POMERANIA,
    value: STATE_MECKLENBURG_POMERANIA,
  },
  {
    title: STATE_LOWER_SAXONY,
    value: STATE_LOWER_SAXONY,
  },
  {
    title: STATE_NORTHRHINE_WESTPHALIA,
    value: STATE_NORTHRHINE_WESTPHALIA,
  },
  {
    title: STATE_RHINELAND_PALATINATE,
    value: STATE_RHINELAND_PALATINATE,
  },
  {
    title: STATE_SAARLAND,
    value: STATE_SAARLAND,
  },
  {
    title: STATE_SAXONY,
    value: STATE_SAXONY,
  },
  {
    title: STATE_SAXONY_ANHALT,
    value: STATE_SAXONY_ANHALT,
  },
  {
    title: STATE_SCHLESWIG_HOLSTEIN,
    value: STATE_SCHLESWIG_HOLSTEIN,
  },
  {
    title: STATE_THURINGIA,
    value: STATE_THURINGIA,
  },
];

const requireVerifiedSpecialization = (value: any): boolean => {
  for (const spec of value.substitute.specializations) {
    // substitute specializations must be verified
    if (spec.verified) {
      return true;
    }
  }
  return false;
};

export const ServiceSubstituteValidationRules: {[p: string]: ServiceSubstituteValidationRule[]} = {
  [STATE_SAXONY]: [requireVerifiedSpecialization],
  [STATE_NORTHRHINE_WESTPHALIA]: [requireVerifiedSpecialization],
};

export default States;
