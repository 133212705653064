<template>
  <div class="header">
    <nav id="main-navigation">
      <v-container class="py-0 flex-nowrap navbar">
        <v-row class="navigation-row">
          <v-col class="py-0 text-left logo" cols="4">
            <a href="/">
              <img alt="arztpool.de" height="110" src="/images/arztpool.svg" />
            </a>
          </v-col>
          <v-col class="py-0" cols="8">
            <div v-if="!hideNavigation" :class="smAndDown ? 'sm' : ''" class="navigation" @mouseleave="hideAllMenus">
              <div v-if="signedIn && !isOperator && !smAndDown">
                <v-list>
                  <v-list-item>
                    <router-link to="/dashboard">
                      {{ $t('NAVIGATION.MENU.LINKS.DASHBOARD') }}
                    </router-link>
                  </v-list-item>
                  <v-list-item>
                    <router-link to="/services" :class="{ 'router-link-active': $route.path === '/service' }">
                      {{$t('NAVIGATION.MENU.LINKS.SERVICES') }}
                    </router-link>
                  </v-list-item>
                  <v-list-item>
                    <router-link to="/documents">
                      {{ $t('NAVIGATION.MENU.LINKS.DOCUMENTS') }}
                    </router-link>
                  </v-list-item>
                  <v-list-item class="item-icon">
                    <router-link to="/messages" class="text-none" stacked>
                      <v-badge v-if="unconfirmedMessages.length > 0 && !messagesLoading" :content="unconfirmedMessages.length">
                        <v-icon icon="far fa-bell" size="small" />
                      </v-badge>
                      <span v-else-if="unconfirmedMessages.length === 0 || messagesLoading">
                        <v-icon icon="far fa-bell" size="small"/>
                      </span>
                     </router-link>
                  </v-list-item>
                </v-list>
              </div>
              <div v-else>
                <div class="menu-general" @mouseover="showPublicMenu" @click.stop="togglePublicMenu">
                  {{ $t('NAVIGATION.MENU.TITLE') }}
                  <v-icon icon="fal fa-bars" />
                </div>
              </div>
              <div :class="{'signed-in': signedIn, active: myDoctorPoolActive}" class="btn-my-doctor-pool d-none d-md-block" @click="dashboardRedirect" @mouseover="showMyDoctorPoolMenu">
                {{ $t('NAVIGATION.MY_DOCTOR_POOL.TITLE') }}
                <v-icon icon="fal fa-user-md" />
              </div>
              <MyDoctorPool v-show="myDoctorPoolActive" @showLogin="showLogin" />
              <PublicMenu v-show="publicMenuActive" @showLogin="showLogin" />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </nav>
    <div class="nav-shadow" />
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, watch } from 'vue';
  import { useDisplay } from 'vuetify';
  import { useRoute } from 'vue-router';
  import { RouterSamePageErrorHandler } from '@/plugins/router';

  import Stores from '@/stores';
  import EventBus from '@/services/EventBus';

  import PublicMenu from '@/components/page/navigation/PublicMenu.vue';
  import MyDoctorPool from '@/components/page/navigation/MyDoctorPool.vue';

  export default defineComponent({
    name: 'PageNavigation',
    components: {
      PublicMenu,
      MyDoctorPool,
    },
    setup() {
      const { signedIn, userType } = Stores.userDataStoreToRefs();
      const { isOperator } = Stores.profileDataStoreToRefs();
      const { unconfirmedMessages } = Stores.messageStoreToRefs();

      const { smAndDown } = useDisplay();
      const route = useRoute();

      // register this const as ref to make it changeable in watcher
      const hideNavigation = ref(false);

      // watch for route changes to hide navigation on specific pages
      watch(() => route.path, (route) => {
        hideNavigation.value = route === '/wizard';
      }, {
        immediate: true,
      });

      return {
        signedIn,
        userType,
        isOperator,
        smAndDown,
        hideNavigation,
        unconfirmedMessages,
        publicMenuActive: ref(false),
        myDoctorPoolActive: ref(false),
      };
    },
    mounted() {
      if(this.$route.query.login === 'true') {
        this.showLogin();
      }
    },
    computed: {
      messagesLoading(): boolean {
        return Stores.message.messagesLoading;
      }
    },
    methods: {
      /**
       * toggle display of public menu
       * @return {void}
       */
      togglePublicMenu(): void {
        this.publicMenuActive = !this.publicMenuActive;
      },

      /**
       * display public menu
       * @return {void}
       */
      showPublicMenu(): void {
        this.publicMenuActive = true;
        this.myDoctorPoolActive = false;
      },

      /**
       * hide display of public menu
       * @return {void}
       */
      hidePublicMenu(): void {
        this.publicMenuActive = false;
      },

      /**
       * display myDoctorPool menu
       * @return {void}
       */
      showMyDoctorPoolMenu(): void {
        this.publicMenuActive = false;
        this.myDoctorPoolActive = true;
      },

      /**
       * hide public and myDoctorPool menus
       * @return {void}
       */
      hideAllMenus(): void {
        this.publicMenuActive = false;
        this.myDoctorPoolActive = false;
      },

      /**
       * open login in modal
       * @return {void}
       */
      showLogin(): void {
        EventBus.emit(EventBus.keys.LOGIN_OPEN, true);
      },

      /**
       * redirect user to profile based on its user info
       * @return {void}
       */
      async dashboardRedirect(): Promise<void> {
        if (this.signedIn) {
          if (this.isOperator) {
            this.$router.push({ name: 'Operators' }).catch(RouterSamePageErrorHandler);
          }
          else {
            this.$router.push({ name: 'Dashboard' }).catch(RouterSamePageErrorHandler);
          }
        }
        else {
          return;
        }

      },
    },
  });
</script>

<style lang="scss" scoped>
  @import '@/scss/configs/colors';
  #main-navigation:deep(.v-list-item__content) {
    overflow: visible;
  }
  #main-navigation:deep(.v-badge__badge) {
    border-color: $color-orange !important;
    background-color: $color-orange !important;
  }

  #main-navigation {
    min-width: 410px;
    flex: 0 0 auto;
    width: 100%;
    position: relative;
    background-color: $color-white;
    z-index: 94;
    height: 76px;

    @media (min-width: 960px) {
      height: 110px !important;
    }

    .logo {
      height: 76px;
      display: flex;
      align-items: center;

      a {
        height: 100%;
      }

      img {
        height: 100%;
        width: 130px;
      }

      @media (min-width: 960px) {
        height: 110px;

        img {
          width: 240px;
        }
      }
    }

    .navigation-row {
      position: relative;
    }

    .navigation {
      height: 76px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 16px;
      color: $color-marine;

      @media (min-width: 960px) {
        height: 110px;
      }

      &.sm {
        .svg-inline--fa {
          font-size: 20px;
          margin-right: 10px;
          color: $color-marine;
        }

        a.router-link-active {
          border: none;
        }
      }

      .v-list {
        display: inline-flex;

        .item-icon {
          padding: 0 0 0 12px;
          width: 65px;


          a {
            border-radius: 20px;
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            .v-icon {
              color: $color-marine;
            }

            &.router-link-active {
              border-bottom-color: transparent;
              width: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: rgba($color-orange, 0.15) !important;
            }
          }
        }

        a {
          font-size: 20px;
          font-weight: bolder;
          color: $color-marine !important;
          text-decoration: none;
          border-bottom: 2px solid transparent;

          &.router-link-active {
            border-bottom-color: $color-orange;
            width: fit-content;
            display: inline-block;
          }
        }
      }

      .menu-general, .btn-my-doctor-pool {
        display: flex;
        align-items: center;
        user-select: none;

        svg {
          margin-left: 16px;
          font-size: 24px;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .menu-general {
        font-weight: 600;
      }

      .btn-my-doctor-pool {
        border: 1px solid $color-marine;
        border-radius: 4px;
        padding: 12px 32px;
        background-color: $color-white;
        color: $color-marine;
        margin-left: 60px;

        &.signed-in {
          border-color: transparent;
          background-color: $color-marine;
          color: $color-white;
        }

        &:hover, &.active {
          border-color: transparent;
          background-color: $color-blue-grey-30;
          color: $color-marine;
        }
      }
    }
  }

  .nav-shadow {
    width: 100%;
    height: 76px;
    position: absolute;
    top: 0;
    box-shadow: 0 10px 6px 0 rgba(0, 0, 0, 0.16);
    z-index: 90;

    @media (min-width: 960px) {
      height: 110px;
    }
  }
</style>
