import { createVuetify } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import { en, de } from 'vuetify/locale';
import * as components from 'vuetify/components'; //option
import * as directives from 'vuetify/directives'; //option

import 'vuetify/styles';

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  locale: {
    locale: 'de',
    fallback: 'en',
    messages: {
      en,
      de,
    },
  },
});

export default vuetify;
