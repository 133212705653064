import Testimonial from '@/definitions/interfaces/locales/Testimonial.i';

export default {
  HEADING: 'Das sagen unsere Kunden',
  PARTNER: [
    {
      TEXT: 'Ich möchte mich hiermit für die jahrelange gute und kompetente Zusammenarbeit bedanken.',
      NAME: 'Dr. Beate Ranacher',
      POSITION: 'Fachärztin für Allgemeinmedizin',
    },
    {
      TEXT: 'arztpool findet immer eine Vertretung für uns, wir wurden noch nie hängengelassen.',
      NAME: 'Dr. med. Maxi A.',
      POSITION: 'Radiologin, Würzburg',
    },
    {
      TEXT: 'Ich bedanke mich außerordentlich für die Zusammenarbeit über die vielen Jahre und die immer gewährte Dienstvertretung.',
      NAME: 'Dr. med. Katrin Ladig',
      POSITION: 'Fachärztin für Frauenheilkunde und Geburtshilfe',
    },
    {
      TEXT: 'Dank der innovativen Plattform kann ich jederzeit meine Dienste eintragen und jederzeit nachsehen, wie es um meinen Dienst bestellt ist. Sobald ein Vertreter gefunden wurde, bekomme ich alle notwendigen Infos und kann sie an die KV melden.',
      NAME: 'Dr. med. Andrea K.-H.',
      POSITION: 'Allgemeinmediziner, Bergisch-Gladbach',
    },
    {
      TEXT: 'Vielen Dank für die gute Zusammenarbeit.',
      NAME: 'Dr. Helmut Hübner',
      POSITION: 'Facharzt für Orthopädie',
    },
    {
      TEXT: 'Mein erster Dienst war super kurzfristig, nur 5 Tage nach meiner Registrierung. arztpool hat innerhalb von wenigen Stunden schon einen Vertreter für mich gefunden. Eine beachtliche Leistung.',
      NAME: 'Walter K.',
      POSITION: 'Wührselen',
    },
    {
      TEXT: 'Ich danke Ihnen für die gute Zusammenarbeit.',
      NAME: 'Dr. Carl Albrecht Guhlmann',
      POSITION: 'Facharzt für Nuklearmedizin',
    },
    {
      TEXT: 'Am Anfang war ich schon skeptisch, wie soll eine Firma aus Berlin meine Bereitschaftsdienste in Nordrhein-Westfalen organisieren können. Aber arztpool hat mich schnell davon überzeugt, dass es kein Problem ist. Ich werde arztpool daher auch gerne weiterempfehlen.',
      NAME: 'Dr. med. Sabine W.',
      POSITION: 'Gynäkologin, Düsseldorf',
    },
    {
      TEXT: 'Vielen Dank für die gute Zusammenarbeit.',
      NAME: 'Dipl.-Med. Ada-Kathrin Adler',
      POSITION: 'Fachärztin für Dermatologie',
    },
  ] as Testimonial[],
  SUBSTITUTES: [
    {
      TEXT: 'arztpool ist eine tolle Möglichkeit für mich als Arzt nebenberuflich noch Geld zu verdienen. Das Angebot an Diensten ist enorm, nicht nur in meiner direkten Umgebung. Durch die Plattform und den Marktplatz ist die Suche und Bewerbung auf einen Dienst kinderleicht, mit nur wenigen Klicks ist es erledigt. Den Vertretungsvertrag schließe ich digital, sehr modern und zeitgemäß.',
      NAME: 'Anne E.',
      POSITION: 'Assitenzärztin, München',
    },
    {
      TEXT: 'Die Registrierung bei arztpool hat nur ein paar Minuten gedauert. Unterlagen hochgeladen und meine Angaben ergänzt. Direkt am Folgetag wurde mein Profil freigeschaltet und ich konnte sofort loslegen.',
      NAME: 'Dr. Hans-Rüdiger M.',
      POSITION: 'Allgemeinmediziner (i.R.), Braunschweig',
    },
    {
      TEXT: 'Super Service bei arztpool, man muss sich fast um nichts kümmern. Sobald ich einen Dienst zugewiesen bekommen habe, werde ich per Mail informiert und kurz darauf meldet sich der Arzt, den ich vertrete bei mir und wir klären die Übergabe der Unterlagen. Daher kann ich arztpool nur empfehlen.',
      NAME: 'Dr. Joachim M.-A.',
      POSITION: 'Anästhesist, Münster',
    },
  ] as Testimonial[],
};
