export default {
  STATES: {
    BREMEN: 'Bremen',
    HAMBURG: 'Hamburg',
    BERLIN: 'Berlin',
    SAARLAND: 'Saarland',
    SCHLESWIG_HOLSTEIN: 'Schleswig-Holstein',
    THURINGIA: 'Thüringen',
    SAXONY: 'Sachsen',
    RHINELAND_PALATINATE: 'Rheinland-Pfalz',
    SAXONY_ANHALT: 'Sachsen-Anhalt',
    HESSE: 'Hessen',
    MECKLENBURG_POMERANIA: 'Mecklenburg-Vorpommern',
    BRANDENBURG: 'Brandenburg',
    NORTHRHINE_WESTPHALIA: 'Nordrhein-Westfalen',
    BADEN_WUERTTEMBERG: 'Baden-Württemberg',
    LOWER_SAXONY: 'Niedersachsen',
    BAVARIA: 'Bayern',
  },

  WEEKDAYS: [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
    'Sonntag',
  ],

  INSTITUTIONS: [
    'Einzelpraxis',
    'Berufsausübungsgemeinschaft',
    'Praxisgemeinschaft',
    'Klinik',
    'MVZ',
  ],

  SERVICES: [
    'Allgemeinärztlicher Bereitschaftsdienst',
    'Augenärztlicher Bereitschaftsdienst',
    'Kinderärztlicher Bereitschaftsdienst',
    'HNO-ärztlicher Bereitschaftsdienst',
    'Chirurgischer Bereitschaftsdienst',
    'Zahnärztlicher Bereitschaftsdienst',
  ],

  COUNTING: [
    'ersten',
    'zweiten',
    'dritten',
    'vierten',
    'fünften',
    'sechsten',
    'siebten',
    'achten',
    'neunten',
    'zehnten',
  ],
};
